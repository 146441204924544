import React, { useState, useEffect, useRef }  from 'react';

import axios from 'axios';
import dayjs from 'dayjs';
import { Row } from 'react-bootstrap';
import webvtt from "node-webvtt";
import { useSelector } from 'react-redux';
import {  ChasingDots } from 'better-react-spinkit';

import '../../resources/assets/styles/sass/AIQuiz.scss';
import Player from '../components/aiQuiz/Player';
import SubList from '../components/aiQuiz/SubList';
import VisTimeline from '../components/aiQuiz/VisTimeline';

function AIVodQuiz(props) {

    const scrollRef = useRef();

    const userToken = useSelector(state => state.user.userToken);
    const organizationData = useSelector(state => state.organization.organizationData);
    const durationTime = useSelector(state => state.aiQuiz.durationTime);

    const [parsedSub, setParsedSub] = useState([]);
    const [playTime, setPlayTime] = useState('');
    const [timelineTime, setTimelineTime] = useState('');
    const [scrollHeight, setScrollHeight] = useState(0);
    const [playState, setPlayState] = useState(false);

    const [isMoving, setIsMoving] = useState(false);

    const handleClick = () => {
        setIsMoving(true);
        setTimeout(() => {
          setIsMoving(false);
        }, 1000); // 1초 후에 움직임을 멈춤
      };

    useEffect(() => {

        var data = JSON.stringify({
            "tk" : userToken,
            "uuid" : 'aaa0e838-74b6-4fb4-b558-5dd99f9d8337',
            "ogc" : 'csms39_mdl_dev',
            "lang" : "ko"
        });
  
        var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/subtitle/getSubtitle',
            headers: { 
                'Content-Type': 'application/json'
                },
            data : data            
        };
            
        axios(config)
        .then( (response) => {
            if (response.data.data) {

                const webvttString = response.data.data;
                const parsed = webvtt.parse(webvttString);
                
                if (parsed.cues.length > 0 ){
                    var tmpParsed = parsed.cues.map(cue => Object.assign({
                        id: Number(cue.identifier),
                        start: '1970-01-01 ' + dayjs('1970-01-01 00:00:00.000').add(cue.start,'seconds').format('HH:mm:ss.SSS'),
                        end: '1970-01-01 ' + dayjs('1970-01-01 00:00:00.000').add(cue.end,'seconds').format('HH:mm:ss.SSS'),
                        group: 1,
                        content: cue.text
                    })
                    )

                    setParsedSub(tmpParsed);
                }
            }
        })
    },[])

    useEffect(() => {
        if (parsedSub.length > 0) {
                scrollRef.current.scrollTop = scrollHeight;
        }
    },[parsedSub, scrollHeight])

    return (
        <>
            {
                parsedSub.length > 0 ?
                    <>
                        <Row>
                            <div className='col-6' style={{ padding: 0 }}>
                                <Player setPlayTime={setPlayTime} setPlayState={setPlayState} timelineTime={timelineTime} />
                            </div>
                            <div className='col-6 subLists' ref={scrollRef} style={{ padding: 0 }}>

                                { parsedSub.map((item, idx) => (
                                    <SubList key={item.id} idx={idx} item={item} playTime={playTime} setScrollHeight={setScrollHeight} />
                                ))}
                            </div>
                        </Row>

                        <Row>
                            <div className='col-12' style={{ padding: 0 }}>
                                    <VisTimeline playState={playState} playTime={playTime} parsedSub={parsedSub} setTimelineTime={setTimelineTime} />
                            </div>
                        </Row>

                        <Row>
                            <div className={`box ${isMoving ? 'moving' : ''}`}></div>
                            <button onClick={handleClick}>Move Right</button>
                        </Row>
                    </>
                    :
                    <div className= "loadingIcon">
                        <ChasingDots size={35} color='#23adad'/>
                    </div>
            }
        </>
    );
}

export default AIVodQuiz;
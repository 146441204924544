import React from 'react';

import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

function UsedDevice(props) {

    const seriesDonut =  [44, 55, 41, 17, 15] ;

    const optionsDonut: ApexOptions = {
        chart: {
            type: 'donut',
        },
        responsive: [{
            breakpoint: 480,
            options: {
            chart: {
                width: 200
            },
            legend: {
                position: 'bottom'
            }
            }
        }]
    }

    return (
        <div className="col-4" style={{ marginBottom: 30 }}>
            <div className="dashboardBox">
                <div className="dashboardBoxTitle">
                    사용자 장비
                </div>
                <ReactApexChart options={optionsDonut} series={seriesDonut} width="90%" height={200}  type="donut"/>
            </div>
        </div>
    );
}

export default UsedDevice;
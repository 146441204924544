import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import ReactApexChart from "react-apexcharts";

function TotalVideo( {options1} ) {

    const organizationData = useSelector(state => state.organization.organizationData);
    const userToken = useSelector(state => state.user.userToken);

    const [totalVideo, setTotalVideo] = useState(0);

    useEffect(() => {
        var data = JSON.stringify({ 
            "userToken" : userToken,
            "page": 1,
            "organizationCode" : organizationData.value
        });
    
        var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/vod/getVodList',
            headers: { 
            'Content-Type': 'application/json'
            },
            data : data
        };
        
        axios(config)
        .then( (response) => {
            if(response.data.statusCode==100){
              setTotalVideo(response.data.vodList.count)
            }
        })
        .catch((error) => {
            console.log(error);
        });

    },[])


    const series1 = [
        {
          name: "My Tasks",
          data: [11, 12, 8, 22, 14, 32, 41]
        }
      ];

    return (
        <div className="col-4" style={{ marginBottom: 30 }}>
            <div className="dashboardBox" style={{ height: 300 }}>
                <div className="dashboardBoxTitle">
                    총 영상 수
                </div>
                <div className="dashboardBoxContent">
                    <div style={{ fontSize: 50, fontStyle: 'italic', textAlign: 'center' }}> 
                        { totalVideo }
                    </div>
                </div>
                <ReactApexChart options={options1} series={series1} width="100%" height={150} />
            </div>
        </div>
    );
}

export default TotalVideo;
const SET_CURRENT_SUB = "aiQuiz/SET_CURRENT_SUB";
const SET_VIDEO_DURATION_TIME = "aiQuiz/SET_VIDEO_DURATION_TIME";

// 액션 생성 함수
export const setCurrentSub = currentSub => ({ type: SET_CURRENT_SUB, currentSub });
export const setVideoDurationTime = durationTime => ({ type: SET_VIDEO_DURATION_TIME, durationTime })
// 초깃값 (상태가 객체가 아니라 그냥 숫자여도 상관 없습니다.)
const initialState = {
    currentSub: [],
    durationTime: ''
}

export default function aiQuiz(state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_SUB:
            return {
                ...state,
                currentSub: action.currentSub
            }
        case SET_VIDEO_DURATION_TIME:
            return {
                ...state,
                durationTime: action.durationTime
            }
        default:
            return state;
    }
} 
import React, { useState, useEffect, useRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Row, Button, Modal} from 'react-bootstrap';
import { IoTrashSharp } from '@react-icons/all-files/io5/IoTrashSharp';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import { MdInfoOutline } from '@react-icons/all-files/md/MdInfoOutline';

import axios from 'axios';
import moment from 'moment';
import videojs from "video.js";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import hlsQualitySelector from "videojs-hls-quality-selector";
import "video.js/dist/video-js.css";

import "../../../../resources/assets/styles/sass/VideoDetail.scss";
import ThumbnailModal from './ThumbnailModal';
import Player from '../../editor/Player';
import Loading from '../../_commons/Loading';

import { setCurrentMediaInfo } from '../../../modules/media';

function VideoDetailMain({ match }) {

    const dispatch = useDispatch();  
    const location = useLocation();

    const videoRef = useRef(null); 
    const playerRef = useRef(null);

    const [videoInfo, setVideoInfo] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [player, setPlayer] = useState(null);
    const [selectedThumbURL, setSelectedThumbURL] = useState('');
    const [idx, setIdx] = useState(0);

    const userToken = useSelector(state => state.user.userToken);
    const organizationData = useSelector(state => state.organization.organizationData);
    const userThumbnailCDN = useSelector(state => state.user.userThumbnailCDN);
    const userVSCdn = useSelector(state => state.user.userVSCdn);

    const uuid = match.params.uuid;

    useEffect(() => {
        // 동영상 정보 가져오기
        var data = JSON.stringify({
            "userToken" : userToken,
            "uuid" : uuid,
            "organizationCode" : organizationData.value
        });

        var config = {
        method: 'post',
        url: API_URL + '/' + API_VERSION + '/vod/getVodInfo',
        headers: { 
            'Content-Type': 'application/json'
        },
        data : data
        };
        
        axios(config)
        .then( (response) => {
            console.log(response)
            if(response.status==200){
                dispatch(setCurrentMediaInfo(response.data));
                setVideoInfo(response.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }, [])

    const videoJsOptions = {
        autoplay: false,
        playbackRates: [0.5, 1, 1.25, 1.5, 2],
        width: 720,
        height: 300,
        controls: true,
        sources: [
          {
            src: userVSCdn + '/hls/' + uuid + '/mp4/' + uuid + '.mp4/index.m3u8',
            type: 'application/x-mpegURL',
          },
        ],
        plugins: { 
            hlsQualitySelector : {
                displayCurrentQuality: true
            }                    
        }     
    };

    useEffect(() => {

        // make sure Video.js player is only initialized once
        if (!playerRef.current) {
            
            const videoElement = videoRef.current;
            if (!videoElement) return;
            console.log(JSON.stringify(videoJsOptions))
            setPlayer( 
                playerRef.current = videojs(videoElement, videoJsOptions, () => { 
                    console.log("player is ready");
                })
            )
            
        } else {
            // you can update player here [update player through props]
            // const player = playerRef.current;
            // player.autoplay(options.autoplay);
            // player.src(options.sources);
        }

    }, []);

    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {
        return () => {
            if (playerRef.current) {
    
            playerRef.current.dispose();
            playerRef.current = null;
            }
        };
    }, []);

    const thumbnailRendering = () => {
        const result = [];
        for (let i = 0; i < 5; i++) {
            const URL = `${userThumbnailCDN}/${uuid}/thumbnail/tn_${i+1}.png`;
            result.push(
                    <div key={i} className="detail_videoThumbnail" onClick={ () => handleModal(URL,i) }>
                        <img src={URL}></img>
                    </div>
            );
        }
        return result;
    };

    function handleModal(url,idx) {
        if(!modalOpen) {
            setModalOpen(true)
            setSelectedThumbURL(url);
            setIdx(idx);
        }
    }

    return (

        <>
            {
                Object.keys(videoInfo).length > 0 ?
                        <div>
                            <div className='VideoDetail'>
                                <div className="wrapper">
                                    <Row className="contentHeader">
                                        <div className='videoDetailTitle'>
                                            <div style={{ fontSize: 25 }}> 동영상 세부정보 </div>
                                        </div>
                                    </Row>
                                    <Tabs
                                        defaultActiveKey="videoInfo"
                                        className="mb-3"
                                    >
                                        <Tab eventKey="videoInfo" title="영상정보">
                                            <Row className="contentBody">
                                                <div className="col-7" style={{ padding: 20, boxSizing: "border-box" }}>
                                                    <Player type="studio" uuid={location.pathname.replace("/video/detail/",'')} VSCdn={userVSCdn}/>
                                                </div>

                                                <div className="col-5" style={{ padding: "10px 20px 10px 10px", marginBottom: 30 , boxSizing: "border-box" }}>
                                                    
                                                    <div style={{ width: '100%', height: 50, padding: 10, fontWeight: 800 }}>
                                                        { videoInfo.realFileName }
                                                    </div>
                                                    <Row style={{ width: '100%', height: 195, padding: 10, fontSize: 13, border: '1px lightgray solid', backgroundColor: '#ddd' }}>
                                                        <div className="col-6">
                                                            파일이름 : 
                                                            <br/>{ videoInfo.realFileName }
                                                            <br/><br/>
                                                            화질 : { videoInfo.videoSize }
                                                            <br></br>
                                                            시간: {  (moment.utc(videoInfo.playTime * 1000)).format("HH:mm:ss") }
                                                        </div>
                                                        <div className="col-6"></div>
                                                    </Row>
                                                </div>

                                                <Row style={{ width: '100%' }}>
                                                    <div className='col-7'>
                                                        <div className="detail_thumbnail">
                                                            <div className="detail_thumbnailTable">
                                                                    {thumbnailRendering()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-5'>
                                                        
                                                    </div>

                                                </Row>
                                            </Row>
                                        </Tab>
                                        <Tab eventKey="infoCard" title="정보카드">
                                            <Row className="contentBody">
                                                <Row key='infoCard' style={{ width: '100%', fontSize: 14 }}>
                                                    <div className="col-6" style={{ width: '100%', padding: 0 }}>
                                                        <Row  style={{ width: '100%', height: 80, alignItems: 'center', borderBottom: '1px solid #ddd', textAlign: 'center', cursor: 'pointer' }}>
                                                            <div className="col-1 iconCol">
                                                                <div><MdInfoOutline size="25" /></div>
                                                            </div>
                                                            <div className="col-2" style={{ fontSize: 18 }}>
                                                                <div>카드</div>
                                                            </div>
                                                            <div  className="col-7"></div>
                                                            <div className="col-2"><FaPlus size="15" color="gray" /></div>
                                                        </Row>
                                                        <Row key='infoCard1' className='menuBoxRow'>
                                                            <div className='col-2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: 0 }}>링크 카드</div>
                                                            <div className='col-3' style={{ paddingRight: 0 }}>네이버로 이동</div>
                                                            <div className='col-2'>_blank</div>
                                                            <div className='col-4 cropOptions'>
                                                                https://www.naver.com
                                                            </div>
                                                            <div className='col-1'>
                                                                <IoTrashSharp />
                                                            </div>
                                                        </Row>
                                                        <Row key='infoCard2' className='menuBoxRow'>
                                                            <div className='col-2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: 0 }}>동영상 위치</div>
                                                            <div className='col-3' style={{ paddingRight: 0 }}>효소의 효과로 이동</div>
                                                            <div className='col-2'>00:01:22</div>
                                                            <div className='col-4'></div>
                                                            <div className='col-1'>
                                                                <IoTrashSharp />
                                                            </div>
                                                        </Row>
                                                    </div>
                                                    <div className='col-6' style={{ padding: 0 }}>
                                                        <Player type="studio" uuid={location.pathname.replace("/video/detail/",'')} VSCdn={userVSCdn}/>
                                                    </div>
                                                </Row>
                                            </Row>


                                        </Tab>
                                                        
                                    </Tabs>

                                </div>
                            </div>
                        </div>
                    :
                        <Loading />
            }




            <ThumbnailModal modalOpen={modalOpen} setModalOpen={setModalOpen} url={selectedThumbURL} idx={idx}/>
        </>
    );
}

export default VideoDetailMain;
import React, { useEffect, useState } from 'react';
import SideBar from '../_commons/SideBar';

import { Table } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import { ChasingDots } from 'better-react-spinkit';
import { FaThList } from '@react-icons/all-files/fa/FaThList';
import { FaClosedCaptioning } from '@react-icons/all-files/fa/FaClosedCaptioning';
import { FaPencilAlt } from '@react-icons/all-files/fa/FaPencilAlt';
import { FaCut } from '@react-icons/all-files/fa/FaCut';

import { setVideoList } from '../../modules/media'

import VideoListPagination from './VideoListPagination';

function VideoList(props) {

    const [videoListCount, setVideoListCount] = useState(0);
    const [currentVodList,setCurrentVodList] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [URLReady, setURLReady] = useState(false);
    const videoListPerPage = 30;
    const dispatch = useDispatch();

    const organizationData = useSelector(state => state.organization.organizationData);
    const userToken = useSelector(state => state.user.userToken);
    const userThumbnailCDN = useSelector(state => state.user.userThumbnailCDN);


    useEffect(() => {

        var toastId = '';

        const indexOfLast = currentPage * videoListPerPage;
        const indexOfFirst = indexOfLast - videoListPerPage;
        var data = JSON.stringify({ 
            "userToken" : userToken,
            "page": currentPage,
            "organizationCode" : organizationData.value
        });
    
        var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/vod/getVodList',
            headers: { 
            'Content-Type': 'application/json'
            },
            data : data
        };
        
        axios(config)
        .then( (response) => {
            if(response.data.statusCode==100){
                setVideoListCount(response.data.vodList.count)
                setCurrentVodList(response.data.vodList.rows)
                dispatch(setVideoList(response.data.vodList.rows));
                document.querySelector('.dashboardArea').scrollTo(0, 0);
            }
        })
        .catch((error) => {
            console.log(error);
        });

    },[currentPage])
    
    return (
        <>
            <div className='VideoList'>
                <div className="wrapper">
                <div>
                    <div className="contentHeader">
                        <div className='containerTitle'>
                            <div> 동영상 </div>
                        </div>
                    </div>
                </div>
                { 
                    currentVodList.length > 0 ?
                        <>
                            <Table className="videoListTable">
                                <thead>
                                <tr>
                                    <th scope="col">동영상</th>
                                    <th scope="col">파일명</th>
                                    <th scope="col">생성날짜</th>
                                    <th scope="col">시간</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {
                                        currentVodList.map((ele) => { 
                                            return (
                                                <tr key={ele.id} className="videoListRow">
                                                    <td className='videoThumbnailColumn' style={{ paddingLeft: 30, width: '30%' }}>
                                                        <div className="videoThumbnail">
                                                            <img src={ userThumbnailCDN ? `${userThumbnailCDN}/${ele.uuid}/thumbnail/tn_1.png` : '' }></img>
                                                        </div>
                                                    </td>
                                                    <td className='contentColumn'>
                                                        <div className='videoTitle'>{ele.realFileName}</div>
                                                        {/* <div className='videoDescription'>크집사 굿즈 똥고양이 해적단 가입!</div> */}
                                                        <div className='videoMenuBtns'>
                                                            <Link to={`video/detail/${ele.uuid}`}>
                                                                <div>
                                                                    <FaPencilAlt size="23" color="gray" />
                                                                </div>
                                                            </Link>
                                                            <Link to={`video/subtitle/${ele.uuid}`}>
                                                                <div>
                                                                    <FaClosedCaptioning size="23" color="gray" />
                                                                </div>
                                                            </Link>
                                                            <Link to={`video/chapter/${ele.uuid}`}>
                                                                <div>
                                                                    <FaThList size="22" color="gray" />
                                                                </div>
                                                            </Link>
                                                            <Link to={`video/editor/${ele.uuid}`}>
                                                                <div>
                                                                    <FaCut size="20" color="gray" />
                                                                </div>
                                                            </Link>
                                                            {/* <Link to={"video"}>
                                                                <div>
                                                                    <i className="fas fa-video"></i>
                                                                </div>
                                                            </Link>
                                                            <Link to={`video/analytics/${ele.uuid}`}>
                                                                <div>
                                                                    <i className="fas fa-chart-bar"></i>
                                                                </div>
                                                            </Link> */}
                                                        </div>
                                                    </td>
                                                    <td className='videoCreateAtColumn'>{moment(ele.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                    <td className='videoPlayTimeColumn'>{moment(moment.utc(ele.playTime * 1000)).format("HH:mm:ss")}</td>
                                                </tr>
                                            )
                                        })

                                    }
                                </tbody>
                            </Table>
                            <VideoListPagination 
                                    videoListPerPage={videoListPerPage}
                                    totalVodList={videoListCount}
                                    paginate={setCurrentPage}
                                    currentPage={currentPage}
                            />
                        </>
                    :
                    <div>
                        <div className="LoadingSpinner">
                            <ChasingDots size={35} color='#23adad' />
                        </div> 
                    </div>
                }
                </div>
            </div>
        </>
    );
}

export default VideoList; 
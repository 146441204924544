import React, {useState, useEffect } from 'react';

import './resources/lang/i18n';
import axios from 'axios';

import { HashRouter, Switch, Route, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { useHistory } from "react-router-dom";

import { setUserToken } from './app/modules/user'
import { setOrganizationList } from './app/modules/organization';

import { Main, Login, Video, Subtitle, Chapter, Editor, Analytics, Settings, PopupManager, MasterPopup, ForRedirect, Trimmer, AIVodQuiz} from './app/containers';

import ScrollTop from './app/components/_commons/ScrollTop';
import { persistStore } from 'redux-persist';

function App() {
  //const dotenv = require('dotenv').config({path: __dirname + '/.env' });
 // console.log(JSON.stringify(process.env.API_URL));
  const userToken = useSelector(state => state.user.userToken);
  const organizationList = useSelector(state => state.user.organizationList);
  const dispatch = useDispatch();  

  useEffect(() => {
      
    if ( typeof organizationList == "undefined" ) {
      var data = JSON.stringify({"listToken":"En7lfpF74U8QA39uo8F0PkzeLbTtti"});
      var config = {
        method: 'post',
        url: API_URL + '/' + API_VERSION + '/organization/getOrganization',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      

      axios(config)
      .then( (response) => {
          if(response.data.code==100){
            
            var tmpSelectOrganizationList = [];

            response.data.organizationList.map((val,key) =>{

                tmpSelectOrganizationList[key] = {
                    value : val.organization_code,
                    label : val.organization_name,
                    open_token : val.open_key,
                  }
              });

              dispatch(setOrganizationList([...tmpSelectOrganizationList]));
          }
            
        })
        .catch((error) => {
            console.log(error);
        });
    }
  }, []);

  

  useEffect(() =>{
    console.log('organizationList',organizationList)
  },[organizationList])
  return (

    <HashRouter>
      <ToastContainer/>
              <Switch>
                <Route path="/PopupManager/master/:masterPopupType/:key" component={MasterPopup} />
                <Route path="/PopupManager/:popupType/:key" component={PopupManager} />

                {
                  userToken ? 
                    <>
                      <Route exact path="/" component={Video} />  
                      <Route path="/main" component={Main} />
                      <Route path="/video" component={Video} />
                      <Route path="/subtitle" component={Subtitle} />
                      <Route path="/chapter" component={Chapter} />
                      <Route path="/editor" component={Editor} />
                      <Route path="/AIVodQuiz" component={AIVodQuiz} />
                      <Route path="/ffmpegTest" component={Trimmer} />
                      <Route path="*" component={ForRedirect} />

                    </>
                    :
                    <>
                      <Route path="/" component={Login} />  

                    </>
                }
              </Switch>
    </HashRouter>
  )

}

export default App; 
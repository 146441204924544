import React, { useState, useEffect, useMemo }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSubtitleList, setSelectedSubtitle } from '../../../modules/subtitle';

import moment from 'moment';
import axios from 'axios';

import { 
    setCardText
  } from '../../../modules/status';
  
function TextInput({ uuid, text, lang, idx, el, setParsedSub, tk, ogc } ) {

    const timelineText = useSelector(state => state.status.timelineText);
    const subtitleList = useSelector(state => state.subtitle.subtitleList);

    const dispatch = useDispatch();

    const [changedObj, setchangedObj] = useState({});
    const [content, setContent] = useState('');

    useEffect(() => {
        if (timelineText && el.id == timelineText.id) {   
            setContent(timelineText.content);
       }
    },[timelineText])
    
    useEffect(() => {
        if (subtitleList.length > 0) {
            setContent(subtitleList[idx].content);
        }
    },[subtitleList])

    function handleChange(content) {
        setchangedObj({
            ...subtitleList[idx],
            content: content
        });
        dispatch(setCardText({
            ...subtitleList[idx],
            content: content
        }));
    }

    function fetchText(e) {
        e.preventDefault();

        if (Object.keys(changedObj).length > 0) {
            var tmpSubList = [...subtitleList];
            tmpSubList[idx] = changedObj;
            dispatch(setSubtitleList(tmpSubList))
            setParsedSub(tmpSubList);
            
            var tmpParsed = tmpSubList.map(list => Object.assign({
                    identifier: String(list.id),
                    start: moment.duration(moment(list.start).diff(moment('1970-01-01 00:00:00.000'))).asSeconds(),
                    end: moment.duration(moment(list.end).diff(moment('1970-01-01 00:00:00.000'))).asSeconds(),
                    text: list.content,
                    styles: ""
                })
            )
            
            var data = JSON.stringify({
                "tk" : tk,
                "uuid" : uuid,
                "ogc" : ogc ,
                "lang" : lang,
                "subtitle" : tmpParsed
            });
    
            var config = {
                method: 'post',
                url: API_URL + '/' + API_VERSION + '/subtitle/aUpdate',
                headers: { 
                    'Content-Type': 'application/json'
                    },
                data : data            
            };
                
            axios(config)
            .then( (response) => {
                var data = response.data.data;
                console.log('edit data',data)
                //var langData = data.subtitle;
            })
            .catch((error) => {
                console.log(error);
            });
        }

    }

    return (
        <div key={idx} suppressContentEditableWarning={true} style={{ width: '100%' }} onBlur={e => fetchText(e)} >
            <div  
                className='editable-text'
                contentEditable={true}
                suppressContentEditableWarning={true}
                placeholder = "내용을 입력해주세요"
                onInput={(e) => handleChange(e.currentTarget.textContent)}
            >
                {content}
                {/* { 
                    timelineTextIdx == idx ? timelineText.content : text 
                } */}
            </div>
        </div>
    );
}
 
export default TextInput;
import React, { useState, useEffect, useRef, useCallback }  from 'react';

import axios from 'axios';
import moment from 'moment';
import { BiImageAdd } from '@react-icons/all-files/bi/BiImageAdd';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import { FaMinus } from '@react-icons/all-files/fa/FaMinus';
import { Modal, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { toast, Flip } from 'react-toastify';
import { ChasingDots } from 'better-react-spinkit';
import { VscError } from '@react-icons/all-files/vsc/VscError';

import Loading from '../../../_commons/Loading';

import { 
    setChapterList
} from '../../../../modules/chapter';

import { 
    setStatusVideoPlayState
} from '../../../../modules/status';

import {
    setPlayingTimeMediaPlayerToTimeline
} from '../../../../modules/media';

import MediaPlayer from '../../_commons/MediaPlayer';
import '../../../../../resources/assets/styles/sass/ManagerChapter.scss';

function EditChapModal( { uuid, type, editModalOpen, setEditModalOpen, editLang, editKoLabel}) {

    const dispatch = useDispatch();

    const PopupUserToken = useSelector(state => state.user.PopupUserToken);
    const popupOrganizationData = useSelector(state => state.organization.popupOrganizationData);
    const userToken = useSelector(state => state.user.userToken);
    const videoDurationTime = useSelector(state => state.media.videoDurationTime);
    const organizationData = useSelector(state => state.organization.organizationData);
    const PopupUserThumbnailCDN = useSelector(state => state.user.PopupUserThumbnailCDN);
    const PopupUserVSCdn = useSelector(state => state.user.PopupUserVSCdn);
    const userThumbnailCDN = useSelector(state => state.user.userThumbnailCDN);
    const userVSCdn = useSelector(state => state.user.userVSCdn);
    const vodInfo = useSelector(state => state.media.vodInfo);

    var newElement = [
        {
            "index" : 0,
            "type": "seeker",
            "start": "00:00:00.000", 
            "label": "",
            "thumbnail" : "",
            "desc" : ""
        }           
    ]
    
    const [parsedChap, setParsedChap] = useState(newElement);
    const [isFetched, setIsFetched] = useState(false);
    
    useEffect(() => {
        if (!editModalOpen) {
            dispatch(setStatusVideoPlayState(false));
            dispatch(setPlayingTimeMediaPlayerToTimeline(0));
            dispatch(setChapterList([]));
        }
        else { //모달 열린 다음에 데이터 패치
 
            var data = JSON.stringify({
                "tk" : type == 'pop' ? PopupUserToken : userToken,
                "uuid" : uuid,
                "ogc" : type == 'pop' ? popupOrganizationData.organization_code : organizationData.value,
                "lang" : editLang
            });
    
            var config = {
                method: 'post',
                url: API_URL + '/' + API_VERSION + '/chapter/getChapter',
                headers: { 
                    'Content-Type': 'application/json'
                    },
                data : data  
            }

            axios(config)
            .then( (response) => {
                if (response.data.data.length > 0) {

                    const jsonString = response.data.data.replace("/","");
                    
                    const parsed = JSON.parse(jsonString);
            
                    if (parsed.length > 0 ){
                        var tmpParsed = parsed.map(el => Object.assign({
                            ...el,
                            start : moment('1970-01-01 00:00:00.000').add(Number(el.start),'seconds').format('HH:mm:ss.SSS')
                            })
                        )

                        setParsedChap(tmpParsed);
                        dispatch(setChapterList(tmpParsed));
                    }
                    else {
                        setParsedChap(newElement);
                        dispatch(setChapterList(newElement));
                    }
                }
                else {
                    setParsedChap(newElement);
                    dispatch(setChapterList(newElement));
                }
                setIsFetched(true);
            })
            .catch((error) => {
                console.log(error);
            });
        }
    },[editModalOpen])

    /* 시간 바꾸기 */
    var changedTime;
    function changeTime(e, idx){

        var tmpParsedChap = parsedChap;

        var time = e.currentTarget.textContent;
            //변경하려는 시간이 왼쪽에 있는 기존 아이템과 겹칠 때
        if (parsedChap[idx-1] != undefined && moment('1970-01-01 ' + time).isSame(moment('1970-01-01 ' + parsedChap[idx-1].start)) ) {
            tmpParsedChap[idx].start = moment('1970-01-01 ' + parsedChap[idx-1].start).add(20,'milliseconds').format('HH:mm:ss.SSS');
        } else {
            tmpParsedChap[idx].start = moment('1970-01-01 '+ time).format('HH:mm:ss.SSS');
        }

        changedTime = tmpParsedChap.sort((a, b) => moment(a.start).diff(moment(b.start)));        
    }

    function fetchTime() {
        console.log(changedTime)
        setParsedChap([...changedTime]);
        fetchChap();
    }

    function TitleInput( { idx, text, type } ){
        return(
            <div suppressContentEditableWarning={true} style={{ width: '100%' }} onBlur={e => fetchChap(e, idx)} >
                <div  
                    className='editable-text'
                    contentEditable={true}
                    suppressContentEditableWarning={true}
                    placeholder = "라벨 입력"
                    onInput={e => changeText(e, idx, type) }
                >
                    { text }
                </div>
            </div>
        )
    }


    function TextInput( { idx, text, type } ){
        return(
            <div suppressContentEditableWarning={true} style={{ width: '100%' }} onBlur={e => fetchChap(e, idx)} >
                <div  
                    className='editable-text'
                    contentEditable={true}
                    suppressContentEditableWarning={true}
                    placeholder = "내용을 입력해주세요"
                    onInput={e => changeText(e, idx, type) }
                >
                    { text }
                </div>
            </div>
        )
    }

    function changeText(e, idx, type){   
        var tmpParsedChap = parsedChap;
        e.preventDefault();
        if (type == "desc") {
            tmpParsedChap[idx].desc = e.currentTarget.textContent;
        }
        else if (type == "label") {
            tmpParsedChap[idx].label = e.currentTarget.textContent;
        }
        setParsedChap(tmpParsedChap);
    }

    function fetchChap() {

        var data = JSON.stringify({
            "tk" : type == 'pop' ? PopupUserToken : userToken,
            "uuid" : uuid,
            "ogc" : type == 'pop' ? popupOrganizationData.organization_code : organizationData.value,
            "lang" : editLang,
            "chapter" : timeFormating(parsedChap)
        });

        var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/chapter/aUpdate',
            headers: { 
                'Content-Type': 'application/json'
                },
            data : data            
        };
            
        axios(config)
        .then( (response) => {
            if (response.data.code == 100) {
                console.log('success')
            }
            else {
                toast.error((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div>챕터 생성에 오류가 생겼습니다! </div></div>),{
                    type: toast.TYPE.ERROR,
                    position: "top-center",
                    autoClose: 1000,
                    className: 'rotateY animated',
                    transition: Flip
                });
            }
        })
        .catch((error) => {
            toast.error((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 챕터 생성에 오류가 생겼습니다! </div></div>),{
                type: toast.TYPE.ERROR,
                position: "top-center",
                autoClose: 1000,
                className: 'rotateY animated',
                transition: Flip
            });
        });
    }

    function timeFormating(parsedChap) {

        var tmpChap = [];

        parsedChap.map((el,idx) => {
            var tmpObj = {
                ...el,
                start : "0"+String((new Date('1970-01-01 ' + el.start).getTime() - new Date('1970-01-01 00:00:00.000').getTime()) / 1000)
            }
            tmpChap.push(tmpObj);
        })

        return tmpChap;
    }

    function delChapterBlock(idx){

        console.log("del idx : ", idx)

        var tmpChapList = parsedChap;
        tmpChapList.splice(idx, 1);
        setParsedChap(tmpChapList => [...tmpChapList]);
        dispatch(setChapterList([...tmpChapList]));  
        
        fetchChap();
    }

    function addChapterBlock(idx){

        var time = moment('1970-01-01 ' + parsedChap[idx].start).format('HH:mm:ss.SSS');
        var endTime = moment('1970-01-01 00:00:00.000').add(videoDurationTime,'seconds').format('HH:mm:ss.SSS');
        var tmpParsedChap = parsedChap;

        var arr = [];
        parsedChap.map((el) => {
            arr.push(el.index)
        })
        var maxIdentifier = Math.max(...arr);

        var newElement;

        if (idx == parsedChap.length - 1) { // 맨 끝에 추가
            if ( moment("1970-01-01 " + time).isAfter(moment("1970-01-01 " + endTime))) {
                newElement = { 
                    'index' : maxIdentifier+1,
                    'type' : "seeker",
                    'label' : "",
                    'start' : moment("1970-01-01 " + endTime).subtract(1,'seconds').format("HH:mm:ss.SSS"),
                    'thumbnail' : "",
                    'desc' : ""
                }
            }
            else {
                console.log('b',moment("1970-01-01 " + time).add(1,'seconds').format("HH:mm:ss.SSS"))
                newElement = { 
                    'index' : maxIdentifier+1,
                    'type' : "seeker",
                    'label' : "",
                    'start' : moment("1970-01-01 " + time).add(1,'seconds').format("HH:mm:ss.SSS"),
                    'thumbnail' : "",
                    'desc' : ""
                }
            }
        }
        else {
            var timeDiff = moment('1970-01-01 ' + parsedChap[idx+1].start).valueOf() - moment('1970-01-01 ' + parsedChap[idx].start).valueOf();
            
            newElement = { 
                'index' : maxIdentifier+1,
                'type' : "seeker",
                'label' : "",
                'start' : moment('1970-01-01 ' + time).add((timeDiff / 2), 'milliseconds').format('HH:mm:ss.SSS'),
                'thumbnail' : "",
                'desc' : ""
            }
        }
        
        tmpParsedChap.splice(idx+1, 0, newElement);
        console.log(newElement)

        var sortedArray = tmpParsedChap.sort((a, b) => moment(a.start).diff(moment(b.start)));
        setParsedChap([...sortedArray]);
        dispatch(setChapterList([...sortedArray]));  

        fetchChap();
    }

    function getUploadUrl(e) {

        var idx = e.target.id.replace('chapterFile_','');
        var tmpParsedChap = parsedChap;

        tmpParsedChap[idx].thumbnail = URL.createObjectURL(e.target.files[0]);
        setParsedChap(tmpParsedChap => [...tmpParsedChap]);
        dispatch(setChapterList([...tmpParsedChap]));  

        var data = JSON.stringify({
            "tk" : type == 'pop' ? PopupUserToken : userToken,
            "uuid" : uuid,
            "ogc" : type == 'pop' ? popupOrganizationData.organization_code : organizationData.value,
            "index": idx + 1,
            "lang" : editLang
        });
    
        var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/chapter/getThumbUploadUrl', 
            headers: { 
            'Content-Type': 'application/json'
            },
            data : data
        };
        axios(config)
        .then( (response) => {
            if (response.data.code == 100) {
                uploadFile(response.data.upload_url, e.target.files[0], idx);
            }
            console.log('response',response)
        })
        .catch((error) => {
            console.log(error);
        });
    }
  
  function uploadFile(uploadUrl, file, idx) {

    var config = {
        method: 'put',
        url: uploadUrl, 
        headers: { 
        'Content-Type': 'image/png'
        },
        data : file
    }

    console.log(config)
    axios(config)
      .then(function (response) {
        console.log(response);
      })
      .then(function () {
        var data = JSON.stringify({
            "tk" : type == 'pop' ? PopupUserToken : userToken,
            "uuid" : uuid,
            "ogc" : type == 'pop' ? popupOrganizationData.organization_code : organizationData.value,
            "idx": idx + 1
        });
        console.log(data)
        var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/common/setThumb', 
            headers: { 
            'Content-Type': 'application/json'
            },
            data : data
        };
        axios(config)
        .then( (response) => {
            if (response.data.code == 100) {
            }
           console.log('setThumb response',response)
        })
        .catch((error) => {
            console.log(error);
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  
    return (
        <Modal className="editModal" show={editModalOpen} onHide={() => setEditModalOpen(false) }>
            <Modal.Header closeButton>
                <Modal.Title>챕터 편집</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{ 'marginBottom' : 30, display: 'flex', justifyContent: 'space-between', padding: '0 10px' }}>
                    <div>
                    { vodInfo.realFileName } / { editKoLabel } 
                    </div>
                </div>
                {
                isFetched && parsedChap.length > 0 ?
                    <Row className="popupModalContent">
                        <div className="col-6" >
                            {
                                parsedChap.map((el,idx) => (
                                    <Row key={idx} className="popupModalSubList" >
                                        <div className="col-1" style={{ 'lineHeight': '80px', 'fontWeight': 'bold' }}>{idx >= 10 ? idx : '0'+idx }</div>
                                        <div className="col-2" style={{ 'display': 'flex', 'alignItems': 'center' }}>
                                            <div className='block-editable-time' >
                                                <div>{ el.type }</div>
                                                <div className='editable-time' contentEditable="true"
                                                    onInput={e => changeTime(e, idx) }
                                                    onBlur={fetchTime}
                                                    suppressContentEditableWarning={true}
                                                >
                                                    { el.start }
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-2 btns uploadBtnWrapper'>
                                            {
                                                el.thumbnail ? 
                                                    <img className="chapLogoImg" src={el.thumbnail} />
                                                    :
                                                    <label key={`fileLabel_${idx}`} htmlFor={`chapterFile_${idx}`} style={{ position: 'absolute', top: 'calc(50% - 22.5px)', left: 'calc(50% - 15px)' }}>
                                                        <div className="btn" style={{ color: 'lightgray' }}>
                                                           <BiImageAdd size="37" color="gray" />
                                                        </div>
                                                    </label>
                                            }
                                            
                                            <input key={`fileInput_${idx}`} id={`chapterFile_${idx}`} type="file" style={{ display: "none" }} accept="image/png" onChange={(e) => getUploadUrl(e)} />
                                        </div>
                                        <div className="col-2" style={{ padding: '10px 20px', display: 'flex', alignItems: 'center' }}>
                                            <TitleInput idx={idx} text={ el.label } type="label" ></TitleInput>
                                        </div>
                                        <div className='col-4' style={{ padding: '10px 20px', display: 'flex', alignItems: 'center' }}>
                                            <TextInput idx={idx} text={ el.desc } type="desc" ></TextInput>
                                        </div>
                                        <div className='col-1 btns' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                            { idx != 0 ?
                                                <div className='btn-del' style={{ marginBottom: 10 }} onClick={()=>delChapterBlock(idx)}>
                                                    <FaMinus size="17" color="gray" />
                                                </div>                                
                                                : null
                                            }
                                            <div className='btn-add' onClick={()=>addChapterBlock(idx)}>
                                                <FaPlus size="17" color="gray" />
                                            </div>
                                        </div>
                                    </Row>
                                ))
                            }

                            <div className='modalAddListBtn' onClick={()=>addChapterBlock(parsedChap.length-1)} style={{ border: 0}}><FaPlus size="22" color="gray" /></div>
                        </div>  
                        <div className="col-6">
                            <Row>
                                <div style={{ width: '100%', border: '1px solid #ddd', borderRadius: 10 }}>
                                    {
                                        type=="pop" ?
                                            <MediaPlayer uuid={uuid} lang={editLang} koLabel={editKoLabel} VSCdn={PopupUserVSCdn} cdn={PopupUserThumbnailCDN}></MediaPlayer>
                                            :
                                            <MediaPlayer uuid={uuid} lang={editLang} koLabel={editKoLabel} VSCdn={userVSCdn} cdn={userThumbnailCDN}></MediaPlayer>
                                    }
                                </div>
                            </Row>
                        </div> 
                    </Row>
                :
                    <Loading />
                }
            </Modal.Body>
        </Modal>
    );
}

export default EditChapModal;
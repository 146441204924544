import React, { useState, useEffect, useRef } from "react";

import Timeline from "react-vis-timeline";
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { 
  setPlayingTimeTimelineToMediaPlayer,
  setPlayingTimeMediaPlayerToTimeline
} from '../../../modules/media';


import '../../../../resources/assets/styles/sass/Timeline.scss';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

const now = moment('1970-01-01 00:00:00.000');

function VisTimeline( {modalPlayState} ) {
  const timelineRef = useRef(null);
  const dispatch = useDispatch();

  const videoDurationTime = useSelector(state => state.media.videoDurationTime);
  const videoCurrentTime = useSelector(state => state.status.videoCurrentTime);

  const [ zoomValue, setZoomValue ] = useState(4); 

  // 타임라인 드래그해서 시간 이동시키면 비디오 컴포넌트의 시간도 변경 (타임라인 -> 비디오)
  useEffect(() => {

    var start = now.clone().subtract(1000*5,'milliseconds');
    var end = now.clone().add(1000*5,'milliseconds');
    timelineRef.current.timeline.setWindow(start, end, { animation: false });

    dispatch(setPlayingTimeTimelineToMediaPlayer(0));

    timelineRef.current.timeline
      .on("rangechange", function (properties) {
        var ct = new Date((new Date(properties['start']).getTime() + new Date(properties['end']).getTime()) / 2);
        if (moment(ct).isBefore(moment('1970-01-01 00:00:00.000'))) {
          timelineRef.current.timeline.moveTo('1970-01-01 00:00:00', { animation: false })
          dispatch(setPlayingTimeMediaPlayerToTimeline(0));
        }

        if (moment(ct).isAfter(moment('1970-01-01 00:00:00.000').add(videoDurationTime, 'seconds'))) {
          timelineRef.current.timeline.moveTo(moment('1970-01-01 00:00:00.000').add(videoDurationTime, 'seconds'), { animation: false })
          //dispatch(setPlayingTimeMediaPlayerToTimeline(videoDurationTime));
        }
      })
      .on("rangechanged", function (properties) {

        var ct = new Date((new Date(properties['start']).getTime() + new Date(properties['end']).getTime()) / 2);
          if (properties['byUser']) {
            dispatch(setPlayingTimeTimelineToMediaPlayer((moment.duration(moment(ct).diff(moment('1970-01-01 00:00:00.000'))).asSeconds())));
          }
      })

  }, []);

  useEffect(() => {
    //비디오 시간에 맞춰 타임라인 이동 (비디오 -> 타임라인)
    console.log('MediaPlayerPlayingTime',videoCurrentTime)
    if (modalPlayState) {
        var time = moment('1970-01-01 00:00:00.000').add(videoCurrentTime,'seconds').format('HH:mm:ss.SSS');
        timelineRef.current.timeline.moveTo(moment('1970-01-01 '+ time));
    }
  }, [modalPlayState, videoCurrentTime]);
  

    const ZoomPlus = () => {
      setZoomValue((prevValue) => prevValue - 1);
      timelineRef.current.timeline.zoomOut(1.0)
    };

    const ZoomMinus = () => {
      setZoomValue((prevValue) => prevValue + 1);
      timelineRef.current.timeline.zoom(1.0)
    };

  const options = {
    height: '150px',
    showMajorLabels: false,
    orientation: "top",
    // zoomMin: 100,
    // zoomMax: 1000 * 100,
    horizontalScroll: true, 
    verticalScroll: false,
    zoomKey: 'ctrlKey',
    max: moment('1970-01-01 00:00:00.000').add(videoDurationTime + 5, 'seconds'),
    zoomable: false,
    stack: false,
    type: "range",
    showCurrentTime: true,
    editable: {
      add: true,         // add new items by double tapping
      updateTime: true,  // drag items horizontally
      updateGroup: false, // drag items from one group to another
      remove: false,       // delete an item by tapping the delete button top right
    },
    margin: {
      axis: 50,
      item: {
        vertical: 15,
        horizontal: 0,
      }
    },
    timeAxis: {
      scale: 'second',
      step: 1
    },
    format: {
      minorLabels: function (date, scale, step) {

        if (moment(date).isBefore(now)) {
          return "";
        }
        else if (moment(date).isAfter(now.clone().add(videoDurationTime, 'seconds'))) {
          return "";
        }
        else {
          if (scale == "millisecond" && step == 200)
            return moment(date).format('mm:ss.SS');
          else if (scale == "millisecond" && step == 100)
            return moment(date).format('ss.SS');
          else if (scale == "millisecond" && step == 50)
            return moment(date).format('ss.SSS');
          else if (scale == "millisecond" && step == 10)
            return moment(date).format('ss.SSS');
          else if (scale == "millisecond" && step == 5)
            return moment(date).format('ss.SSS');
          else if (scale == 'second')
            return moment(date).format('HH:mm:ss');
        }
      }
    },
  };

  return (
        <div className="Editor TimelineBox" id="box">

            <div className="borderBox" id="box">
            <div className="track"></div>
            <div className="arrow"></div>
            </div>

            <Timeline options={options} ref={timelineRef} />

        </div>
  );
}




export default VisTimeline;


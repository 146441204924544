import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';
import moment from 'moment';
import { Row, Table } from 'react-bootstrap';
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { toast, Slide, Flip } from 'react-toastify';
import { VscCheck } from '@react-icons/all-files/vsc/VscCheck';
import { VscError } from '@react-icons/all-files/vsc/VscError';
import { IoIosArrowForward } from '@react-icons/all-files/io/IoIosArrowForward';
import { ChasingDots } from 'better-react-spinkit';

import VideoListPagination from '../videoEdit/VideoListPagination';
import Loading from '../_commons/Loading';

function EditVideoList({ setUuid }) {

    const userToken = useSelector(state => state.user.userToken);
    const organizationData = useSelector(state => state.organization.organizationData);
    const userThumbnailCDN = useSelector(state => state.user.userThumbnailCDN);
    const [videoListCount, setVideoListCount] = useState(0);
    const videoListPerPage = 30;

    const [currentVodList,setCurrentVodList] = useState([])
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {

        const indexOfLast = currentPage * videoListPerPage;
        const indexOfFirst = indexOfLast - videoListPerPage;
        var data = JSON.stringify({ 
            "userToken" : userToken,
            "page": currentPage,
            "organizationCode" : organizationData.value
        });
    
        var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/vod/getVodList',
            headers: { 
            'Content-Type': 'application/json'
            },
            data : data
        };
        
        axios(config)
        .then( (response) => {
            if(response.data.statusCode==100){
                setVideoListCount(response.data.vodList.count)
                setCurrentVodList(response.data.vodList.rows)
            }
        })
        .catch((error) => {
            console.log(error);
        });

    },[currentPage])

    function handleEditor(el) {
        setUuid(el.uuid);
    }

    return (
        <div style={{ position: 'relative' }}>
            { 
                currentVodList.length > 0 ?
                    <>
                        <Table className="videoListTable concat">
                            <thead>
                                <tr>
                                    <th scope="col">동영상</th>
                                    <th scope="col">파일명</th>
                                    <th scope="col">생성날짜</th>
                                    <th scope="col">시간</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    currentVodList.map((el,idx) => { 
                                        return (
                                            <tr key={el.id} className="videoListRow" onClick={(e) => handleEditor(el)}>
                                                <td className='videoThumbnailColumn' style={{ paddingLeft: 30, width: '30%' }}>
                                                    <div className="videoThumbnail">
                                                        <img src={ userThumbnailCDN ? `${userThumbnailCDN}/${el.uuid}/thumbnail/tn_1.png` : '' }></img>
                                                    </div>
                                                </td>
                                                <td className='contentColumn'>
                                                    <div className='videoTitle'>{el.realFileName}</div>
                                                </td>
                                                <td className='videoCreateAtColumn'>{moment(el.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                <td className='videoPlayTimeColumn'>{moment(moment.utc(el.playTime * 1000)).format("HH:mm:ss")}</td>
                                                <td style={{ width: 100 }}>
                                                    <div style={{ marginLeft: 35 }}><IoIosArrowForward /></div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                        <VideoListPagination 
                                videoListPerPage={videoListPerPage}
                                totalVodList={videoListCount}
                                paginate={setCurrentPage}
                                currentPage={currentPage}
                        />
                    </>
                    : 
                    <Loading style={{ top: '30vh' }} />
            }
        </div>
    );
}

export default EditVideoList;
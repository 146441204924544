import React, { useEffect, useState } from 'react';
import { Row, Table } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

import axios from 'axios';

import Header from '../components/_commons/Header';
import SideBar from '../components/_commons/SideBar';

import '../../resources/assets/styles/sass/Subtitle.scss';

import VideoListPagination from '../components/subtitle/VideoListPagination';
import SubList from '../components/subtitle/SubList';
import Loading from '../components/_commons/Loading';

import { setVideoList } from '../modules/media';

function Subtitle(props) {

    const [loadingCount, setLoadingCount] = useState([0]);
    const [videoListCount, setVideoListCount] = useState(0);
    const [currentVodList,setCurrentVodList] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true); 
    const [chapListLoadingStatus, setChapListLoadingStatus] = useState([]);

    const videoListPerPage = 30;

    const initArr = new Array(videoListPerPage);
    initArr.fill(false);

    const userToken = useSelector(state => state.user.userToken);
    const organizationData = useSelector(state => state.organization.organizationData);
    const dispatch = useDispatch();

    const history = useHistory();

    useEffect(() => {

        const indexOfLast = currentPage * videoListPerPage;
        const indexOfFirst = indexOfLast - videoListPerPage;
        var data = JSON.stringify({ 
            "userToken" : userToken,
            "page": currentPage,
            "organizationCode" : organizationData.value
        });
    
        var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/vod/getVodList',
            headers: { 
            'Content-Type': 'application/json'
            },
            data : data
        };
        
        axios(config)
        .then( (response) => {
            if(response.data.statusCode==100){
                setVideoListCount(response.data.vodList.count);
                setCurrentVodList(response.data.vodList.rows);
                dispatch(setVideoList(response.data.vodList.rows));
                var arr = new Array(response.data.vodList.rows.length).fill(0);
                setLoadingCount(arr);
                document.querySelector('.dashboardArea').scrollTo(0, 0);
            }
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            checkLoadingStatus(); 
        });
    },[currentPage])

    useEffect(() => {
        let sum = 0;

        for (let i = 0; i < loadingCount.length; i++) {
            sum += loadingCount[i];
        }
        console.log(sum);
    },[loadingCount])

    useEffect(() => {
        var path = location.hash.replace("#/subtitle",'');

        if (path != '') {
            history.push('/subtitle');
        }
    },[location.hash.replace("#/subtitle",'')])

    const checkLoadingStatus = () => {
        const allLoaded = chapListLoadingStatus.every(status => status === true);
        if (allLoaded) {
            setIsLoading(false);
        }
    };

    return (
      <>
        <Header></Header>
        <Row style={{ width: '100%', margin: 0  }}>
            <SideBar/>
            <div className='dashboardArea' style={{ width: '100%' }} >
                <div className="wrapper">
                    <div>
                        <div className="contentHeader">
                            <div className='containerTitle'>
                                <div> 자막 </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isLoading ? 
                        <Loading /> 
                    :   
                    currentVodList.length > 0  ?
                            <>
                                <Table className="subtitleListTable">
                                    <thead>
                                        <tr>
                                            <th scope="col">동영상</th>
                                            <th scope="col"></th>
                                            <th scope="col">언어</th>
                                            <th scope="col">수정일</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            currentVodList.map((ele, index) => { 
                                                return (
                                                    <SubList key={index} index={index} videoInfo={ele} loadingCount= {loadingCount} setLoadingCount={setLoadingCount} updateLoadingStatus={status => {
                                                        const updatedStatus = [...chapListLoadingStatus];
                                                        updatedStatus[index] = status;
                                                        setChapListLoadingStatus(updatedStatus);
                                                    }} />
                                                )
                                            })
                                        }
                                    </tbody>          
                                </Table>
                                <VideoListPagination 
                                            videoListPerPage={videoListPerPage}
                                            totalVodList={videoListCount}
                                            paginate={setCurrentPage}
                                            currentPage={currentPage}
                                    />
                            </>
                        :
                            <Loading />
                }
            </div>
        </Row>
      </>        
    );
}

export default Subtitle;
import { combineReducers } from 'redux';
import media from './media';
import user from './user';
import organization from './organization';
import status from './status';
import subtitle from './subtitle';
import chapter from './chapter';
import editor from './editor';
import aiQuiz from './aiQuiz';

const rootReducer = combineReducers({ organization, user, media, status, subtitle, chapter, editor, aiQuiz });

export default rootReducer;

import React,{ useEffect, useState } from 'react';

import axios from 'axios';
import { Row } from 'react-bootstrap';
import { IoTrashSharp } from '@react-icons/all-files/io5/IoTrashSharp';
import { AiOutlinePlus } from '@react-icons/all-files/ai/AiOutlinePlus';
import { useDispatch, useSelector } from 'react-redux';

import { setProjectData } from '../../../modules/editor';

function InfoCardMenu({ uuid, tk, ogc }) {

    const projectData = useSelector(state => state.editor.projectData);
    const projectInfo = useSelector(state => state.editor.projectInfo);
    const editProjIdx = useSelector(state => state.editor.editProjIdx);

    const [infoCardList, setInfoCardList] = useState([]);
    const [changedObj, setchangedObj] = useState({});

    const dispatch = useDispatch();  

    useEffect(() => {
        if (projectData[0].length > 0) {
            projectData[0].map((el, idx) => {
                if (el.action == "infoCard") {
                    setInfoCardList(el.data);
                }
            })
        }
    },[projectData])
    
    function handleChange(idx, type, text) {

        var tmpArr = infoCardList[idx];

        if (type =='from') {
            setchangedObj({
                ...tmpArr,
                'from' : text
            });
        }
        else if (type =='to') {
            setchangedObj({
                ...tmpArr,
                'to' : text
            })
        }
    }

    function addCard() {

        var tmpList = infoCardList;
        tmpList.push(
            {
                'title': '',
                'from': '00:00:000',
                'to' : '00:00:000'
            }
        );
        setInfoCardList([...tmpList])
    }

    function fetchText(e, idx) {
        e.preventDefault();

        var tmpCardList = [...infoCardList];
        tmpCardList[idx] = changedObj;
        setInfoCardList(tmpCardList);
        
        var tmpProjectData = projectData;
        if (projectData.length > 0) {
            projectData.map((el, idx) => {
                if (el.action == "infoCard") {
                    tmpProjectData.splice(idx,1);
                }
            })
        }
        tmpProjectData.push({
            "action" : "infoCard",
            "data" : tmpCardList
        });
        
        dispatch(setProjectData(tmpProjectData));

        var data = JSON.stringify({
            "tk" : tk,
            "uuid" : uuid,
            "ogc" : ogc,
            "project_id": editProjIdx,
            "projectInfo" : projectInfo,
            "projectData" : tmpProjectData
        });

        var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/editor/update',
            headers: { 
                'Content-Type': 'application/json'
                },
            data : data            
        };
            
        axios(config)
        .then( (response) => {
            console.log(response.data.data);
        })
        .catch((error) => {
            console.log(error);
        });
    }
    return (
        <div>
            {
                infoCardList.map((el,idx) => {
                    return (
                        <Row key={`crop_${idx}`} className='menuBoxRow' onBlur={e => fetchText(e, idx)} >
                            <div className='col-2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 20 }}>동영상</div>
                            <div className='col-2'>새로운 창</div>
                            <div className='col-3'>{el.title}</div>
                            <div className='col-4 cropOptions'>
                                <Row style={{ display: 'flex' }} >
                                    <div style={{ marginRight: 10 }} >
                                        <div  
                                            className='editor editable-text'
                                            contentEditable={true}
                                            suppressContentEditableWarning={true}
                                            onInput={(e) => handleChange(idx, 'from', e.currentTarget.textContent)}
                                        >
                                            {el.from}
                                        </div>
                                    </div>
                                    <div style={{ marginRight: 10 }} > - </div>
                                    <div>
                                        <div  
                                            className='editor editable-text'
                                            contentEditable={true}
                                            suppressContentEditableWarning={true}
                                            onInput={(e) => handleChange(idx, 'to', e.currentTarget.textContent)}
                                            style={{ width: '100%' }}
                                        >
                                            {el.to}
                                        </div>
                                    </div>
                                </Row>
                            </div>
                            <div className='col-1'>
                                <IoTrashSharp />
                            </div>
                        </Row>
                    )
                })
            }   
            <div className="plusBtn" onClick={() => addCard()}><div className='plusBtnIcon'><AiOutlinePlus /></div></div>
        </div>
    );
}

export default InfoCardMenu;
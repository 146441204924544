import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';

import { useSelector, useDispatch } from 'react-redux';
import { Table, Button } from 'react-bootstrap';
import { ChasingDots, Wave } from 'better-react-spinkit';
import { toast, Slide, Flip } from 'react-toastify';
import { VscCheck } from '@react-icons/all-files/vsc/VscCheck';
import { VscError } from '@react-icons/all-files/vsc/VscError';
import { CgSpinner } from '@react-icons/all-files/cg/CgSpinner';
import Switch from "react-switch";

import {
    setPopupChapState
} from '../../modules/chapter';

import ToggleSwitch from "../../components/videoEdit/_commons/ToggleSwitch";
import EditColumn from '../videoEdit/chapter/EditColumn';
import DeleteColumn from '../videoEdit/chapter/DeleteColumn';

function ChapterPopup({uuid}) {

    const dispatch = useDispatch();

    const [isUsed, setIsUsed] = useState(false);
    const [isFetched, setIsFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const PopupUserToken = useSelector(state => state.user.PopupUserToken);
    const popupOrganizationData = useSelector(state => state.organization.popupOrganizationData);
    const popupvodInfo = useSelector(state => state.media.popupvodInfo);
    const popupChapState = useSelector(state => state.chapter.popupChapState);

    const koLabelArr = [
        { "lang" : "ko", "koLabel" : "한글" },
        { "lang" : "en", "koLabel" : "영어" },
        { "lang" : "ja", "koLabel" : "일본어" },
        { "lang" : "zh-CN", "koLabel" : "중국어 - 간체" },
        { "lang" : "zh-TW", "koLabel" : "중국어 - 번체" },  
        { "lang" : "vi", "koLabel" : "베트남어" },
        { "lang" : "id", "koLabel" : "인도네시아어" },
        { "lang" : "th", "koLabel" : "태국어" },
        { "lang" : "de", "koLabel" : "독일어" },
        { "lang" : "ru", "koLabel" : "러시아어" },
        { "lang" : "es", "koLabel" : "스페인어" },
        { "lang" : "it", "koLabel" : "이탈리아어" },
        { "lang" : "fr", "koLabel" : "프랑스어" },
    ]

    const toastId = useRef();

    useEffect(() => { 
        //redux 초기화
        return () => {
            dispatch(setPopupChapState([]));
        };
    },[])
    
    useEffect(() => {
       
        if (PopupUserToken && popupOrganizationData) {
            
            var data = JSON.stringify({
                "tk" : PopupUserToken,
                "uuid" : uuid,
                "ogc" : popupOrganizationData.organization_code,
                "type" : "ALL"
            });

            var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/chapter/getState',
            headers: { 
                'Content-Type': 'application/json'
            },
            data : data
            };
    
            axios(config)
            .then( (response) => {
                if(response.data.code==100){
                    var tmpChapState = new Array(13).fill(0);
                    if (response.data.data.chapter.length > 0) {
                        response.data.data.chapter.map((el, index) => {
                            /*
                                언어 순서 조정 및 한국어 라벨 저장하기
                            */
                        var lang = el.lang;

                        //하이픈 비교 오류 방지
                        if (el.lang && el.lang.includes('-')) {
                                lang = el.lang.replace('-','')
                            }
                        
                            switch(lang) 
                            {
                                case "ko":
                                    tmpChapState[0] = el;
                                break;
                                case "en":
                                    tmpChapState[1] = el;
                                break;                        
                                case "ja":
                                    tmpChapState[2] = el;
                                break;
                                case "zhCN":
                                    tmpChapState[3] = el;
                                break;    
                                case "zhTW":
                                    tmpChapState[4] = el;
                                break;  
                                case "vi":
                                    tmpChapState[5] = el;
                                break;      
                                case "id":
                                    tmpChapState[6] = el;
                                break;    
                                case "th":
                                    tmpChapState[7] = el;
                                break;     
                                case "de":
                                    tmpChapState[8] = el;
                                break;    
                                case "ru":
                                    tmpChapState[9] = el;
                                break;   
                                case "es":
                                    tmpChapState[10] = el;
                                break;    
                                case "it":
                                    tmpChapState[11] = el;
                                break;   
                                case "fr":
                                    tmpChapState[12] = el;
                                break;      
                            }
                            
                        })
                    }

                    dispatch(setPopupChapState([...tmpChapState]));
                    setIsFetched(true);
                }
                
            })
            .catch((error) => {
                console.log(error);
            });
        }

    }, [PopupUserToken, popupOrganizationData]);



    useEffect(() => {
        if (popupvodInfo) {
            if (popupvodInfo.support_chapter == 1) {
                setIsUsed(true);
            }
            else {
                setIsUsed(false);
            }

        }
    },[popupvodInfo])

    function handleChange() {
        
        toast.update(toastId.current ,{
            render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><CgSpinner size="22" color="#fff" className="loaderIcon" /></div><div>챕터 사용 여부를 변환 중 입니다.</div></div>,
            type: toast.TYPE.INFO,
            autoClose: 1000,
            position: "top-center",
            className: 'rotateY animated',
            transition: Flip
        });

        setIsUsed(!isUsed);

        var data = JSON.stringify({
            "userToken" : PopupUserToken,
            "uuid" : uuid,
            "organizationCode" : popupOrganizationData.organization_code,
            "state" : isUsed ? 0 : 1
        });

        var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/vod/setChapterState',
            headers: { 
                'Content-Type': 'application/json'
            },
            data : data
        };
        
        axios(config)
        .then( (response) => {
            toast.dismiss(toastId.current);

            console.log(response)
            if(response.data.code == 100){
                setIsUsed(response.data.currentState);
            }
        })
        .catch((error) => {
            console.log(error);
            toast.error((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 챕터 생성에 오류가 생겼습니다! </div></div>),{
                type: toast.TYPE.ERROR,
                autoClose: 1000,
                position: "top-center",
                className: 'rotateY animated',
                transition: Flip
            });
        });
    }

    return (
        <>
            <div>
                <div>
                    <div className="contentHeader" style={{ padding: 20 }}>
                        <div className='containerSubtitleTitle'>
                            <div>
                                <div> 동영상 챕터 </div>

                            </div>
                        </div>
                    </div>
                </div>   

                <div style={{ width: '90%', margin: '0 auto 30px', fontSize: 13, display: 'flex', justifyContent: 'space-between' }}>
                    <div className='titleDesc'>
                        <div>· 이 영상에서 챕터를 사용하시려면 토글 버튼을 on으로, 사용을 중단하실 때는 off로 바꾸어주십시오.</div>
                        <div>· 자동 번역은 한글을 기준으로 하는 번역만 지원합니다.</div>
                    </div>
                    <Switch uncheckedIcon={
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", 
                                            fontSize: 11, color: "#fff", paddingRight: 2 }} >
                                    off
                                </div>
                            }
                            checkedIcon={
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", 
                                            fontSize: 11, color: "#fff", paddingRight: 2 }} >
                                    on
                                </div>
                              }
                            onColor='#11a7ea'     
                            onChange={() => handleChange() }
                            checked={ isUsed ? true : false } />
                </div>             
            </div>

            <Table className="subtitleListTable">
                <thead>
                    <tr>
                        <th scope="col">언어</th>
                        <th scope="col">사용</th>
                        <th scope="col">편집 / 생성</th>
                        {/* <th scope="col">자동 번역</th> */}
                        <th scope="col">삭제</th>
                    </tr>
                </thead>
                    <tbody>      
                        { 
                            isFetched && !isLoading?
                                popupChapState && popupChapState.length > 0 ?
                                    koLabelArr.map((el, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{el.koLabel}</td>
                                                <td> 
                                                    <ToggleSwitch type='pop' loc='chapter' uuid={uuid} lang={el.lang} koLabel={el.koLabel} index={index}/>
                                                </td>
                                                <td className='editModifyBtns'> 
                                                    <EditColumn type='pop' setIsLoading={setIsLoading} index={index} uuid={uuid} el={el} />
                                                </td>
                                                {/* <td> 
                                                    
                                                    {   
                                                        el.lang != 'ko' ?
                                                            autoGenChapState[index]  == 0?
                                                                <Button  className="autoTranslateBtn" variant="warning" style={{ width: 85}} onClick={() => autoGenChap(el.lang, index)}>
                                                                    자동 번역
                                                                </Button>
                                                                :  
                                                                autoGenChapState[index] == 1?
                                                                    <Button className="autoTranslateBtn"  variant="success" style={{ width: 85 }} >
                                                                        완료
                                                                    </Button>
                                                                    :
                                                                    autoGenChapState[index] == 2?
                                                                        <Button className="autoTranslateBtn"  variant="primary" style={{ width: 85 }} >
                                                                            <Wave style={{ display: 'flex', justifyContent: 'center' }} size={15} color='#fefeff' />
                                                                        </Button>
                                                                        :null
                                                        : null
                                                    }
                                                </td> */}
                                                <td className='editDeleteBtns'> 
                                                    {
                                                        popupChapState && popupChapState[index] != 0 ?
                                                            <DeleteColumn type="pop" index={index} uuid={uuid} el={el} />
                                                        :   null
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })  
                                    :
                                    null
                                :                  
                                <tr>
                                    <td className="LoadingSpinner popup" >
                                        <ChasingDots size={35} color='#23adad' />
                                    </td> 
                                </tr>
                        }
                    </tbody>        
            </Table>
        </>
    );
}

export default ChapterPopup;

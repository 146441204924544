
import React from 'react';

import ReactApexChart from "react-apexcharts";
import { ApexOptions} from "apexcharts";
import { Row } from 'react-bootstrap';

import TotalVideo from './TotalVideo';
import TotalStorage from './TotalStorage';
import WeeklyVideoUsage from './WeeklyVideoUsage';
import NumberUsers from './NumberUsers';
import UsagePerTime from './UsagePerTime';
import UsedService from './UsedService';
import UsedOS from './UsedOS';
import UsedDevice from './UsedDevice';
import LearningStatus from './LearningStatus';
import UsagePerCountry from './UsagePerCountry';
import '../../../resources/assets/styles/sass/Dashboard.scss';

function DashBoard(){

    const options1: ApexOptions = {
      chart: {
          id: 'sparkline1',
          group: 'sparklines',
          type: 'area',
          height: 100,
          sparkline: {
              enabled: true
          },
          },
          stroke: {
          curve: 'straight'
          },
          fill: {
          opacity: 0.5,
          },
          colors: ['#DCE6EC'],
      }


    return (
        <Row style={{ padding: 30, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>   

            <Row style={{ width: "100%" }}>
                <TotalVideo options1={options1} />
                <TotalStorage options1={options1} />
                <WeeklyVideoUsage options1={options1} />
            </Row>

            <Row style={{ width: "100%" }}>
                <NumberUsers />
                <UsagePerTime />
            </Row>

            <Row style={{ width: "100%" }}>
                <UsedService />
                <UsedOS />
                <UsedDevice />
            </Row>

            <Row style={{ width: "100%" }}>
                <LearningStatus />
                <UsagePerCountry />
            </Row>

        </Row>
    );
}

export default DashBoard; 
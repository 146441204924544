import React from 'react';

import ReactApexChart from "react-apexcharts";

function TotalStorage( {options1} ) {

    const series2 =  [
        {
          name: "My Tasks",
          data: [11, 12, 22, 14, 32, 8, 22, 14, 32, 41]
        }
    ];

    return (
        <div className="col-4" style={{ marginBottom: 30 }}>
            <div className="dashboardBox" style={{ height: 300 }}>
                <div className="dashboardBoxTitle">
                    총 스토리지 이용량
                </div>
                <div className="dashboardBoxContent">
                    <div style={{ fontSize: 50, fontStyle: 'italic', textAlign: 'center' }}> 
                        64G
                    </div>
                </div>
            <ReactApexChart options={options1} series={series2} width="100%" height={150} />
            </div>
        </div>
    );
}

export default TotalStorage;
import React, { useState, useEffect, useRef }  from 'react';

import videojs from "video.js";
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';

import { setVideoDurationTime } from '../../modules/aiQuiz';

function Player({setPlayTime, setPlayState, timelineTime}) {

    const videoRef = useRef(null); 
    const playerRef = useRef(null);
    
    const dispatch = useDispatch();
    
    const currentSub = useSelector(state => state.aiQuiz.currentSub);

    const [player, setPlayer] = useState(null);
    const [currentTime, setCurrentTime] = useState(0);
    const [durationTime, setDurationTime] = useState(0);
    const [thumbCanvas, setThumbCanvas] = useState([]);
    const [thumbnails, setThumbnails] = useState([]);

    var videoJsOptions = {
        autoplay: false,
        playbackRates: [0.5, 1, 1.25, 1.5, 2],
        controls: true,
        height: '100%',
        width: '100%',
        sources: [
          {
            src: 'https://jaolyskypdfu7395038.cdn.ntruss.com/hls/aaa0e838-74b6-4fb4-b558-5dd99f9d8337/mp4/aaa0e838-74b6-4fb4-b558-5dd99f9d8337.mp4/index.m3u8',
            type: 'application/x-mpegURL',
          },
        ],
        plugins: {
          hlsQualitySelector: {
            displayCurrentQuality: true
          }
        }
      }
  
      useEffect(() => {
        // make sure Video.js player is only initialized once
        if (!playerRef.current) {
          
            const videoElement = videoRef.current;
            if (!videoElement) return;

            setPlayer( 
                playerRef.current = videojs(videoElement, videoJsOptions, () => {
                    console.log("player is ready");
  
                })
            )
        }
        
      }, []);

      useEffect(() => {
   
        if(player != null){
          
            player.on("loadedmetadata", () => {
              setDurationTime(player.duration());
              dispatch(setVideoDurationTime(player.duration()))
            });
            player.on("timeupdate", () => {
              const newTime = dayjs('1970-01-01 00:00:00.000').add(player.currentTime() * 1000);
              setPlayTime(newTime.format('YYYY-MM-DD HH:mm:ss.SSS'));
            });
            player.on("play", () => {
              console.log("IS Playinggg");
              setPlayState(true);
            });
            player.on("pause", () => {
              console.log("IS STOPPED");
              setPlayState(false);
            });

        } 
      }, [player]); 

      useEffect(() => {
        if (Object.keys(currentSub).length > 0) {
          var millisecondsDiff = dayjs(currentSub.start).diff(dayjs('1970-01-01 00:00:00.000'), 'millisecond');
          playerRef.current.currentTime(millisecondsDiff/1000);
        }
      },[currentSub]);

      useEffect(() => {
        if (timelineTime) {
          playerRef.current.currentTime(timelineTime);
        }
      },[timelineTime])

    return (
        <div className="videoContatiner" >
            <div id="videoEl" data-vjs-player>
                <video ref={videoRef} id="videoEl" className="video-js vjs-big-play-centered" style={{ height: '100%' }} >
                </video>
            </div>
        </div>
    );
}

export default Player;
import React from 'react';

import Pagination from 'react-bootstrap/Pagination';

function VideoListPagination({ videoListPerPage, totalVodList, paginate, currentPage }) {
    
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalVodList / videoListPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
        <div className='pagenationContainer'>
            <Pagination className='customPagination'>
                <Pagination.First onClick={() => paginate(1)}  />
                <Pagination.Prev onClick={() => paginate(currentPage - 1)} />
                    {
                        pageNumbers.map((number, index) => {
                            return (
                                <Pagination.Item 
                                    className={currentPage == number ? 'page__numbers active': 'page__numbers'} 
                                    onClick={() => paginate(number)} 
                                    key={index}
                                    >{number}</Pagination.Item>
                            )
                        })
                    }
                <Pagination.Next onClick={() => paginate(currentPage + 1)} />
                <Pagination.Last onClick={() => paginate(pageNumbers[pageNumbers.length - 1])}/>
            </Pagination>
        </div>
    );
}

export default VideoListPagination; 
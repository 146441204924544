import React from 'react';
import { ChasingDots, Wave } from 'better-react-spinkit';

function Loading(props) {
    return (
        <div style={{ position: 'absolute', top: "40vh", left: "50%" }}>
             <ChasingDots size={35} color='#23adad' />
        </div>
    );
}

export default Loading;
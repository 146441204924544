import React, { useState, useRef, useEffect } from 'react';

import { Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { ChasingDots, Wave } from 'better-react-spinkit';
import { toast, Slide, Flip } from 'react-toastify';
import { VscError } from '@react-icons/all-files/vsc/VscError';
import { CgSpinner } from '@react-icons/all-files/cg/CgSpinner';

import {
    setSubState,
    setPopupSubState
} from '../../../modules/subtitle';

import EditSubModal from './editModal/EditSubModal';

import '../../../../resources/assets/styles/sass/VideoDetail.scss';

function EditColumn( { type, index, el, setIsLoading, isGenerating, isTranslating, setAutoGenSubState, setTranslateSubState, uuid } ) {

    const dispatch = useDispatch();

    const PopupUserToken = useSelector(state => state.user.PopupUserToken);
    const popupOrganizationData = useSelector(state => state.organization.popupOrganizationData);
    const popupSubState = useSelector(state => state.subtitle.popupSubState);

    const userToken = useSelector(state => state.user.userToken);
    const organizationData = useSelector(state => state.organization.organizationData);
    const subState = useSelector(state => state.subtitle.subState);

    const [editState, setEditState] = useState(0);
    const [editModalOpen, setEditModalOpen] = useState(false);

    const toastId = useRef();

    useEffect(() => {
        
        if (type == "pop") {
            if (popupSubState && popupSubState.length > 0) {
                if (popupSubState[index] == 0){
                    setEditState(0);
                }
                else {
                    if (popupSubState[index].state == 2) {
                        setEditState(2);
                    }
                    else {
                        setEditState(1);
                    }
                }
            }
        } else {
            if (subState && subState.length > 0) {
                if (subState[index] == 0){
                    setEditState(0);
                }
                else {
                    if (subState[index].state == 2) {
                        setEditState(2);
                    }
                    else {
                        setEditState(1);
                    }
                }
            }
        }
    },[subState, popupSubState])

    useEffect(() => {
        if (!editModalOpen) {
            setAutoGenSubState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
            setTranslateSubState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
        }
    },[editModalOpen])

    function handleEditModal() {

        if (type == "pop") {
            var tmpSubState = popupSubState;

            // 생성되어있는 자막이 없으면 자동 생성한 후 /subtitle/create API 응답 받으면 모달 열어줌
            if ( popupSubState && popupSubState[index] == 0 )  {
    
                setIsLoading(true);

                var data = JSON.stringify({
                    "tk" : PopupUserToken,
                    "uuid" : uuid,
                    "ogc" : popupOrganizationData.organization_code,
                    "lang" : el.lang
                });
            
                var config = {
                    method: 'post',
                    url: API_URL + '/' + API_VERSION + '/subtitle/create', 
                    headers: { 
                    'Content-Type': 'application/json'
                    },
                    data : data
                };
                
                axios(config)
                .then( (response) => {
                    setIsLoading(false);
                    
                    if (response.data.code == 100){
                        console.log('open')
                        toast.dismiss(toastId.current);
                        setEditModalOpen(true);

                        tmpSubState[index] = {
                            uuid: uuid,
                            lang: el.lang,
                            state: 0
                        };

                        dispatch(setPopupSubState([...tmpSubState]));
                        setEditState(1);
                        setIsLoading(false);
                        
                    }
                    else {
                        toast.update(toastId.current ,{
                            render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 자막 생성에 오류가 생겼습니다! </div></div>,
                            type: toast.TYPE.ERROR,
                            autoClose: 1000,
                            position: "top-center",
                            className: 'rotateY animated',
                            transition: Flip
                        });
                        setEditState(0);
                    }
                })
                .catch((error) => {
                    toast.update(toastId.current ,{
                        render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 자막 생성에 오류가 생겼습니다! </div></div>,
                        type: toast.TYPE.ERROR,
                        autoClose: 1000,
                        position: "top-center",
                        className: 'rotateY animated',
                        transition: Flip
                    });
                    setEditState(0);
                });
            }
            // 생성된 챕터가 있다면 그냥 모달 열어줌.
            else {
                setEditModalOpen(true)
            }

        }
        else {
            var tmpSubState = subState;

            // 생성되어있는 자막이 없으면 자동 생성한 후 /subtitle/create API 응답 받으면 모달 열어줌
            if (subState && subState[index] == 0 )  {
    
                setIsLoading(true);
    
                var data = JSON.stringify({
                    "tk" : userToken,
                    "uuid" : uuid,
                    "ogc" : organizationData.value,
                    "lang" : el.lang
                });
            
                var config = {
                    method: 'post',
                    url: API_URL + '/' + API_VERSION + '/subtitle/create', 
                    headers: { 
                    'Content-Type': 'application/json'
                    },
                    data : data
                };
                
                axios(config)
                .then( (response) => {
                    setIsLoading(false);
                    if (response.data.code == 100){
                        toast.dismiss(toastId.current);
                        setEditModalOpen(true);
                        tmpSubState[index] = {
                            uuid: uuid,
                            lang: el.lang,
                            state: 0
                        };
                        dispatch(setSubState([...tmpSubState]));
                        setIsLoading(true);
                        setEditState(1);
                    }
                    else {
                        toast.update(toastId.current ,{
                            render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 자막 생성에 오류가 생겼습니다! </div></div>,
                            type: toast.TYPE.ERROR,
                            autoClose: 1000,
                            position: "top-center",
                            className: 'rotateY animated',
                            transition: Flip
                        });
                        setEditState(0);
                    }
                })
                .catch((error) => {
                    toast.update(toastId.current ,{
                        render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 자막 생성에 오류가 생겼습니다! </div></div>,
                        type: toast.TYPE.ERROR,
                        autoClose: 1000,
                        position: "top-center",
                        className: 'rotateY animated',
                        transition: Flip
                    });
                    setEditState(0);
                });
            }
            // 생성된 챕터가 있다면 그냥 모달 열어줌.
            else {
                setEditModalOpen(true)
            }
        }
   
    }

    return (
        <>
            {
                editState == 2 ?
                    <Button className='subListEditBtn off' disabled={true} onClick={handleEditModal}>
                        생성 중
                    </Button>
                    :
                    editState == 1 ?
                        <>
                            {
                                isGenerating == 2 || isGenerating == 3 || isTranslating == 3 ?
                                    <Button className='subListEditBtn off' disabled={true} onClick={handleEditModal}>
                                        <Wave style={{ display: 'flex', justifyContent: 'center' }} size={15} color='#fefeff' />
                                    </Button>
                                :
                                    <Button className='subListEditBtn' disabled={false} onClick={handleEditModal}>
                                        편집
                                    </Button>
                            }
                        </>
                        :
                        <>
                            {
                                isGenerating == 2 || isGenerating == 3 || isTranslating == 3 ?
                                    <Button className='subListCreateBtn off' disabled={true} onClick={handleEditModal}>
                                        <Wave style={{ display: 'flex', justifyContent: 'center' }} size={15} color='#fefeff' />
                                    </Button>
                                    :
                                    <Button className='subListCreateBtn' disabled={false} onClick={handleEditModal}>
                                        생성
                                    </Button>
                            }
                        </>
            }

            <EditSubModal key="sub" uuid={uuid} type={type} editModalOpen={editModalOpen} setEditModalOpen={setEditModalOpen} editLang={el.lang} editKoLabel={el.koLabel} />
        </>
    );
}

export default EditColumn;
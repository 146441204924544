import React, { useEffect, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { FaAngleDown } from '@react-icons/all-files/fa/FaAngleDown';

import { setSubState } from '../../modules/subtitle';

import LangRow from './LangRow';

function SubList({ videoInfo, index, loadingCount, setLoadingCount, updateLoadingStatus }) {

    const dispatch = useDispatch();

    const [langRowOpen, setLangRowOpen] = useState(false);
    const [subInfo, setSubInfo] = useState([]);
    const count = loadingCount;
    
    const userToken = useSelector(state => state.user.userToken);
    const organizationData = useSelector(state => state.organization.organizationData);
    const userThumbnailCDN = useSelector(state => state.user.userThumbnailCDN);

    useEffect(() => {
        var data = JSON.stringify({
            "tk" : userToken,
            "uuid" : videoInfo.uuid,
            "ogc" : organizationData.value,
            "type" : "ALL"
        });

        var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/subtitle/getState',
            headers: { 
            'Content-Type': 'application/json'
            },
            data : data
        };
        
        axios(config)
        .then( (response) => {
            if(response.data.code==100){
                var tmpSubState = [];
                var filteredTmpSubState = [];
                
                if (response.data.data.subtitle.length > 0) {
                    response.data.data.subtitle.map((el, index) => {
                        /*
                            언어 순서 조정 및 한국어 라벨 저장하기
                            0 ~ 5번으로 언어 순서별로 저장 후 filter로 빈 배열 없애기
                        */
                       var lang = el.lang;
                       //하이픈 비교 오류 방지
                       if (el.lang.indexOf('-') > 0) {
                            lang = el.lang.replace('-','')
                       }
                       
                        switch(lang) 
                        {
                            case "ko":
                                tmpSubState[0] = el;
                                tmpSubState[0].koLabel = "한글";
                            break;
                            case "en":
                                tmpSubState[1] = el;
                                tmpSubState[1].koLabel = "영어";
                            break;                        
                            case "ja":
                                tmpSubState[2] = el;
                                tmpSubState[2].koLabel = "일본어";
                            break;
                            case "zhCN":
                                tmpSubState[3] = el;
                                tmpSubState[3].koLabel = "중국어 - 간체";
                            break;    
                            case "zhTW":
                                tmpSubState[4] = el;
                                tmpSubState[4].koLabel = "중국어 - 번체";
                            break;  
                            case "vi":
                                tmpSubState[5] = el;
                                tmpSubState[5].koLabel = "베트남어";
                            break;      
                            case "id":
                                tmpSubState[8] = el;
                                tmpSubState[8].koLabel = "인도네시아어";
                            break;    
                            case "th":
                                tmpSubState[9] = el;
                                tmpSubState[9].koLabel = "태국어";
                            break;     
                            case "de":
                                tmpSubState[10] = el;
                                tmpSubState[10].koLabel = "독일어";
                            break;    
                            case "ru":
                                tmpSubState[11] = el;
                                tmpSubState[11].koLabel = "러시아어";
                            break;   
                            case "es":
                                tmpSubState[12] = el;
                                tmpSubState[12].koLabel = "스페인어";
                            break;    
                            case "it":
                                tmpSubState[13] = el;
                                tmpSubState[13].koLabel = "이탈리아어";
                            break;   
                            case "fr":
                                tmpSubState[14] = el;
                                tmpSubState[14].koLabel = "프랑스어";
                            break;      
                        }

                        filteredTmpSubState = tmpSubState.filter(function (el) {
                            return el != null;
                        });
                        
                    })
                }
       
                setSubInfo(filteredTmpSubState);
                dispatch(setSubState(filteredTmpSubState));

                count[index] = 1;
                setLoadingCount(count);
            }
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            updateLoadingStatus(true); // 데이터 로딩 완료 상태 업데이트
        });
    },[videoInfo]) 

    function filterFunc (el)  {
        if (el.state != 2)  {
          return true;
        }
      }

    return (
        <>
            <tr>
                <td style={{ width: '30%', paddingLeft: 30 }}>
                    <div className="videoThumbnail">
                        <img src={ userThumbnailCDN ? `${userThumbnailCDN}/${videoInfo.uuid}/thumbnail/tn_1.png` : '' }></img>
                    </div>
                </td>
                <td  style={{ width: '30%', textAlign: 'left' }}>
                    <div>{ videoInfo.realFileName }</div>
                    {/* <div style={{ marginTop: 15 }}>크집사 굿즈 똥고양이 해적단 가입!</div> */}
                </td>
                <td>
                { 
                    subInfo.length > 0 ? 
                        <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }} onClick={()=> { langRowOpen ? setLangRowOpen(false) : setLangRowOpen(true) }} >                                          
                            <div className={ langRowOpen ? 'langRowIcon open' : 'langRowIcon' } >
                                <FaAngleDown size="23" color="gray" />
                            </div>
                            <div style={{ marginLeft: 5 }}>{subInfo.filter(filterFunc).length}</div> 
                        </div>
                        : null
                }
                </td>
                <td style={{ width: '30%' }}>
                    <div>{ (videoInfo.createdAt).replace('T',' ').slice(0,-5)}</div>
                </td>
            </tr>
            {
                langRowOpen ? 
                <LangRow subInfo={subInfo} index={index} videoInfo={videoInfo}/> : null
            }
        </>
    );
}

export default SubList;
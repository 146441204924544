import React, { useState, useRef, useEffect } from 'react';

import { Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { toast, Slide, Flip } from 'react-toastify';
import { VscError } from '@react-icons/all-files/vsc/VscError';
import { CgSpinner } from '@react-icons/all-files/cg/CgSpinner';

import {
    setChapState,
    setPopupChapState
} from '../../../modules/chapter';

import EditChapModal from './editModal/EditChapModal';

function EditColumn( { setIsLoading, type, index, el, uuid } ) {

    const dispatch = useDispatch();
    
    const PopupUserToken = useSelector(state => state.user.PopupUserToken);
    const popupOrganizationData = useSelector(state => state.organization.popupOrganizationData);
    const popupChapState = useSelector(state => state.chapter.popupChapState);

    const userToken = useSelector(state => state.user.userToken);
    const organizationData = useSelector(state => state.organization.organizationData);
    const chapState = useSelector(state => state.chapter.chapState);

    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editState, setEditState] = useState(false);


    const toastId = useRef();

    useEffect(() => {

        if (type == "pop") {

            if (popupChapState && popupChapState.length > 0) {

                if (popupChapState[index] == 0){
                    setEditState(false);
                }
                else {
                    setEditState(true);
                }
            }
        }
        else {
            
            if (chapState && chapState.length > 0) {

                if (chapState[index] == 0){
                    setEditState(false);
                }

                else {
                    setEditState(true);
                }
            }
        }
    },[popupChapState, chapState])

    
    function handleEditModal() {

        if (type=="pop") {
            var tmpChapState = popupChapState;

            // 생성되어있는 챕터가 없으면 자동 생성한 후 /chapter/create API 응답 받으면 모달 열어줌
            if (tmpChapState && tmpChapState[index] == 0 )  {

                setIsLoading(true);
                
                var data = JSON.stringify({
                    "tk" : PopupUserToken,
                    "uuid" : uuid,
                    "ogc" : popupOrganizationData.organization_code,
                    "lang" : el.lang
                });
            
                var config = {
                    method: 'post',
                    url: API_URL + '/' + API_VERSION + '/chapter/create', 
                    headers: { 
                        'Content-Type': 'application/json'
                    },
                    data : data
                };
                
                axios(config)
                .then( (response) => {
                    setIsLoading(false);
                    
                    if (response.data.code == 100){
                        toast.dismiss(toastId.current); 

                        tmpChapState[index] = {
                            uuid: uuid,
                            lang: el.lang,
                            state: 0
                        };

                        dispatch(setPopupChapState([...tmpChapState]));
    
                        setEditState(true);
                        setIsLoading(false);

                    }
                    else {
                        toast.update(toastId.current ,{
                            render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 자막 생성에 오류가 생겼습니다! </div></div>,
                            type: toast.TYPE.ERROR,
                            autoClose: 1000,
                            position: "top-center",
                            className: 'rotateY animated',
                            transition: Flip
                        });

                        setEditState(false);
                    }
                })
                .catch((error) => {
                    toast.error((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 챕터 생성에 오류가 생겼습니다! </div></div>),{
                        type: toast.TYPE.ERROR,
                        autoClose: 1000,
                        position: "top-center",
                        className: 'rotateY animated',
                        transition: Flip
                    });
                    setEditState(false);
                });
            }
            // 생성된 챕터가 있다면 그냥 모달 열어줌.
            else {
                setEditModalOpen(true)
            }

        }
        else {
            var tmpChapState = chapState;

            // 생성되어있는 챕터가 없으면 자동 생성한 후 /chapter/create API 응답 받으면 모달 열어줌
            if (chapState && chapState[index] == 0 )  {

                setIsLoading(true);

                toastId.current = toast.info((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><CgSpinner size="22" color="#fff" className="loaderIcon" /></div><div> 챕터 생성 후 편집창이 열립니다. </div></div>),{
                    type: toast.TYPE.INFO,
                    autoClose: 1000,
                    position: "top-center",
                    className: 'rotateY animated',
                    transition: Flip
                });

                
                var data = JSON.stringify({
                    "tk" : userToken,
                    "uuid" : uuid,
                    "ogc" : organizationData.value,
                    "lang" : el.lang
                });
            
                var config = {
                    method: 'post',
                    url: API_URL + '/' + API_VERSION + '/chapter/create', 
                    headers: { 
                    'Content-Type': 'application/json'
                    },
                    data : data
                };
                
                axios(config)
                .then( (response) => {
                    setIsLoading(false);

                    if (response.data.code == 100){
                        toast.dismiss(toastId.current); 
                        setEditModalOpen(true);

                        tmpChapState[index] = {
                            uuid: uuid,
                            lang: el.lang,
                            state: 0
                        };

                        dispatch(setChapState([...tmpChapState]));

                        setEditState(true);
                    }
                    else {
                        toast.update(toastId.current ,{
                            render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 자막 생성에 오류가 생겼습니다! </div></div>,
                            type: toast.TYPE.ERROR,
                            autoClose: 1000,
                            position: "top-center",
                            className: 'rotateY animated',
                            transition: Flip
                        });

                        setEditState(false);
                    }
                })
                .catch((error) => {
                    toast.error((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 챕터 생성에 오류가 생겼습니다! </div></div>),{
                        type: toast.TYPE.ERROR,
                        autoClose: 1000,
                        position: "top-center",
                        className: 'rotateY animated',
                        transition: Flip
                    });
                    setEditState(false);
                });
            }
            // 생성된 챕터가 있다면 그냥 모달 열어줌.
            else {
                setEditModalOpen(true)
            }

        }

    }


    return (
        <>
            {
                editState ?
                    <Button variant="primary" className='subListEditBtn off' style={{ width: 85, opacity: 1 }} onClick={handleEditModal}>
                            편집
                    </Button>
                :
                    <Button variant="primary" className='subListCreateBtn' style={{ width: 85, opacity: 0.7 }} onClick={handleEditModal}>
                            생성
                    </Button>
            }

            <EditChapModal uuid={uuid} type={type} editModalOpen={editModalOpen} setEditModalOpen={setEditModalOpen} editLang={el.lang} editKoLabel={el.koLabel} />
        </>
    );
}

export default EditColumn;
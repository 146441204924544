import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import Switch from "react-switch";

import {
    setSubState,
    setPopupSubState,
} from '../../../modules/subtitle';

import {
    setChapState,
    setPopupChapState,
} from '../../../modules/chapter';

const ToggleSwitch = ({ loc, type, uuid, lang, koLabel, index }) => {
  
    const dispatch = useDispatch();

    const [checked, setChecked] = useState(false);
    const [langState, setLangState] = useState(null);

    const [createSubModalOpen, setCreateSubModalOpen] = useState(false);
    const [createChapModalOpen, setCreateChapModalOpen] = useState(false);

    const PopupUserToken = useSelector(state => state.user.PopupUserToken);
    const popupOrganizationData = useSelector(state => state.organization.popupOrganizationData);
    const popupSubState = useSelector(state => state.subtitle.popupSubState);
    const popupChapState = useSelector(state => state.chapter.popupChapState);

    const userToken = useSelector(state => state.user.userToken);
    const organizationData = useSelector(state => state.organization.organizationData);
    const subState = useSelector(state => state.subtitle.subState);
    const chapState = useSelector(state => state.chapter.chapState);

    useEffect(() => {

        if (type=="pop") {
            if (loc == "subtitle") {
                popupSubState && setLangState(popupSubState[index]);
            }
            else {
                popupChapState && setLangState(popupChapState[index]);
            }
        }
        else {
            if (loc == "subtitle") {
                subState && setLangState(subState[index]);
            }
            else {
                chapState && setLangState(chapState[index]);
            }
        }

    },[popupSubState, popupChapState, subState, chapState])

    useEffect(() => {
        
        if (langState == 0) {
            setChecked(false);
        }
        else {
            langState && setChecked(langState.state == 0 || langState.state == 2 ? false : true);
        }

    },[langState]);

    const handleChange = nextChecked => {

        if (langState == 0) { //자막이 생성 된 게 있을 때는 사용 여부 상태 변경해줌
            console.log('lang',langState)
            handleCreateModal();
        }
        else {
            setChecked(!checked);
            changeState(nextChecked);
        }
    };

    function handleCreateModal() {
        if(loc== "subtitle" && !createSubModalOpen) {
            setCreateSubModalOpen(true)
        }
        if (loc== "chapter" && !createChapModalOpen)
            setCreateChapModalOpen(true)
    }

    function changeState (nextChecked) {

        if (type == "pop") {
            var data = JSON.stringify({
                'tk' : PopupUserToken,
                'uuid' :  uuid,
                'ogc' : popupOrganizationData.organization_code,
                'type' : lang,
                'state' : nextChecked ? 1 : 0
              });
      
              var config = {
                  method: 'post',
                  url: API_URL + '/' + API_VERSION + '/' + loc + '/setState',
                  headers: { 
                      'Content-Type': 'application/json'
                  },
                  data : data
              };
      
            axios(config)
            .then( (response) => {
                if(response.data.code==100){
                    console.log('success')
                    
                    if (loc == "subtitle") {
                        var tmpObj = {...popupSubState[index],
                            state: nextChecked ? 1 : 0
                        };

                        popupSubState[index] = tmpObj

                        dispatch(setPopupSubState([...popupSubState]));
                    }
                    else {
                        var tmpObj = {...popupChapState[index],
                            state: nextChecked ? 1 : 0
                        };

                        popupChapState[index] = tmpObj

                        dispatch(setPopupChapState([...popupChapState]));
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }
        else {
            var data = JSON.stringify({
                'tk' : userToken,
                'uuid' :  uuid,
                'ogc' : organizationData.value,
                'type' : lang,
                'state' : nextChecked ? 1 : 0
              });
      
              var config = {
                  method: 'post',
                  url: API_URL + '/' + API_VERSION + '/' + loc + '/setState',
                  headers: { 
                      'Content-Type': 'application/json'
                  },
                  data : data
              };
      
                axios(config)
                .then( (response) => {
                  if(response.data.code==100){
                    console.log('success')

                    if (loc == "subtitle") {
                        var tmpObj = {...subState[index],
                            state: nextChecked ? 1 : 0
                        };

                        subState[index] = tmpObj

                        dispatch(setSubState([...subState]));
                    }
                    else {
                        var tmpObj = {...chapState[index],
                            state: nextChecked ? 1 : 0
                        };

                        chapState[index] = tmpObj

                        dispatch(setChapState([...chapState]));
                    }
                  }
                })
                .catch((error) => {
                    console.log(error);
                });
        }


      };


    return (
      <>
            {
                langState == 0 ?
                    null
                    :
                    <Switch
                        onChange={handleChange}
                        checked={checked}
                        onColor="#57CF00"
                    />
            }

            <Modal className="createSubModal" show={createSubModalOpen} onHide={() => setCreateSubModalOpen(false)}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body style={{ textAlign: 'center' }}>
                    <div style={{ padding: '3rem' }}>
                        등록된 { koLabel } 자막이 없습니다.<br /><br />
                        <b>자막 파일 편집</b> 혹은 <b>자동 생성</b>을 통해 자막을 생성하여주세요. 
                    </div>
                    <div style={{ fontSize: 12, color: 'gray' }}> 현재 자동 생성은 <b>한글</b>, <b>영어</b>, <b>일본어</b> 만 가능합니다.</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setCreateSubModalOpen(false)}>
                        취소
                    </Button>
                    <Button variant="primary" style={{ padding: '0.375rem 0.5rem' }} onClick={() =>  {setCreateSubModalOpen(false) }}>
                        확인
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal className="createSubModal" show={createChapModalOpen} onHide={() => setCreateChapModalOpen(false)}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body style={{ textAlign: 'center' }}>
                    <div style={{ padding: '3rem' }}>
                        등록된 { koLabel } 챕터가 없습니다.<br /><br />
                        챕터를 등록하세요. 
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setCreateChapModalOpen(false)}>
                        취소
                    </Button>
                    <Button variant="primary" style={{ padding: '0.375rem 0.5rem' }} onClick={() =>  {setCreateChapModalOpen(false) }}>
                        확인
                    </Button>
                </Modal.Footer>
            </Modal>
      </>

    );
  };
  
  export default ToggleSwitch;
import React, { useState, useRef, useEffect } from 'react';

import { Button, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { toast, Slide, Flip } from 'react-toastify';
import { VscCheck } from '@react-icons/all-files/vsc/VscCheck';
import { VscError } from '@react-icons/all-files/vsc/VscError';
import { CgSpinner } from '@react-icons/all-files/cg/CgSpinner';

import {
    setSubState,
    setPopupSubState
} from '../../../modules/subtitle';


function DeleteColumn( { type, index, el, uuid } )  {

    const dispatch = useDispatch();

    const PopupUserToken = useSelector(state => state.user.PopupUserToken);
    const popupOrganizationData = useSelector(state => state.organization.popupOrganizationData);
    const popupSubState = useSelector(state => state.subtitle.popupSubState);

    const userToken = useSelector(state => state.user.userToken);
    const organizationData = useSelector(state => state.organization.organizationData);
    const subState = useSelector(state => state.subtitle.subState);

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [delState, setDelState] = useState(false);

    const toastId = useRef();

    useEffect(() => {
        
        if (type == "pop") {

            if (popupSubState && popupSubState.length > 0) {

                if (popupSubState[index] == 0){
                    setDelState(false);
                }

                else {
                    if (popupSubState[index].state == 2) {
                        setDelState(false);
                    }
                    else {
                        setDelState(true);
                    }
                }
            }
        }
        else {

            if (subState && subState.length > 0) {

                if (subState[index] == 0){
                    setDelState(false);
                }

                else {
                    if (subState[index].state == 2) {
                        setDelState(false);
                    }
                    else {
                        setDelState(true);
                    }
                }
            }
        }

    },[popupSubState, subState])

    function handleDeleteModal() {
        if(!deleteModalOpen) {
            setDeleteModalOpen(true)
        }
    }

    function deleteSub() {

        if (type == "pop") {
            var tmpSubState = popupSubState;

            toastId.current = toast.info((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><CgSpinner size="22" color="#fff" className="loaderIcon" /></div><div>자막 삭제 중입니다.</div></div>),{
                type: toast.TYPE.INFO,
                autoClose: 1000,
                position: "top-center",
                className: 'rotateY animated',
                transition: Flip
            });
    
            var data = JSON.stringify({
                "tk" : PopupUserToken,
                "uuid" : uuid,
                "ogc" : popupOrganizationData.organization_code,
                "lang" : el.lang
            });
        
            var config = {
                method: 'delete',
                url: API_URL + '/' + API_VERSION + '/subtitle/delete', 
                headers: { 
                'Content-Type': 'application/json'
                },
                data : data
            };
            
            axios(config)
            .then( (response) => {
                setDeleteModalOpen(false)
                if(response.data.code==100){
                    toast.update(toastId.current ,{
                        render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscCheck size="23" /> 삭제 되었습니다! </div></div>,
                        type: toast.TYPE.INFO,
                        autoClose: 1000,
                        position: "top-center",
                        className: 'rotateY animated',
                        transition: Flip
                    });
                    tmpSubState[index] = 0;
                    dispatch(setPopupSubState([...tmpSubState]));
                    setDelState(false);
                }
                else {
                    toast.update(toastId.current ,{
                        render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 자막 삭제에 오류가 생겼습니다! </div></div>,
                        type: toast.TYPE.ERROR,
                        autoClose: 1000,
                        position: "top-center",
                        className: 'rotateY animated',
                        transition: Flip
                    });
                }
            })
            .catch((error) => {
                setDeleteModalOpen(false)
                toast.error((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 삭제에 오류가 생겼습니다! </div></div>),{
                    type: toast.TYPE.ERROR,
                    autoClose: 1000,
                    position: "top-center",
                    className: 'rotateY animated',
                    transition: Flip
                });
            });
        }
        else {
            var tmpSubState = subState;

            toastId.current = toast.info((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><CgSpinner size="22" color="#fff" className="loaderIcon" /></div><div>자막 삭제 중입니다.</div></div>),{
                type: toast.TYPE.INFO,
                autoClose: 1000,
                position: "top-center",
                className: 'rotateY animated',
                transition: Flip
            });
    
            var data = JSON.stringify({
                "tk" : userToken,
                "uuid" : uuid,
                "ogc" : organizationData.value,
                "lang" : el.lang
            });
        
            var config = {
                method: 'delete',
                url: API_URL + '/' + API_VERSION + '/subtitle/delete', 
                headers: { 
                'Content-Type': 'application/json'
                },
                data : data
            };
            
            axios(config)
            .then( (response) => {
                setDeleteModalOpen(false)
                if(response.data.code==100){
                    toast.update(toastId.current ,{
                        render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscCheck size="23" /> 삭제 되었습니다! </div></div>,
                        type: toast.TYPE.INFO,
                        autoClose: 1000,
                        position: "top-center",
                        className: 'rotateY animated',
                        transition: Flip
                    });
                    tmpSubState[index] = 0;
                    dispatch(setSubState([...tmpSubState]));
                    setDelState(false);
                }
                else {
                    toast.update(toastId.current ,{
                        render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 자막 삭제에 오류가 생겼습니다! </div></div>,
                        type: toast.TYPE.ERROR,
                        autoClose: 1000,
                        position: "top-center",
                        className: 'rotateY animated',
                        transition: Flip
                    });
                }
            })
            .catch((error) => {
                setDeleteModalOpen(false)
                toast.error((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 삭제에 오류가 생겼습니다! </div></div>),{
                    type: toast.TYPE.ERROR,
                    autoClose: 1000,
                    position: "top-center",
                    className: 'rotateY animated',
                    transition: Flip
                });
            });
        }
    }

    return (
        <>
            {
                delState ?
                    <Button variant="danger" style={{ width: 65 }} onClick={() => handleDeleteModal()}>
                        삭제
                    </Button>
                :
                    null
            }


            <Modal className="deleteModal" show={deleteModalOpen} onHide={() => setDeleteModalOpen(false)}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body style={{ textAlign: 'center' }}>{ el.koLabel } 자막을 삭제하시겠습니까?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setDeleteModalOpen(false)}>
                        취소
                    </Button>
                    <Button variant="primary"  style={{ padding: '0.375rem 0.5rem', opacity: 0.7 }} onClick={deleteSub}>
                        삭제
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    );
}

export default DeleteColumn;
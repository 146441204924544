import React, { useEffect, useState } from 'react';

import SubToggleSwitch from './ChapToggleSwitch';
import EditColumn from '../videoEdit/chapter/EditColumn';
import { useSelector, useDispatch } from 'react-redux';

import { setChapState } from '../../modules/chapter';

import { setCurrentMediaUUID, setCurrentMediaInfo } from '../../modules/media';

function LangRow({ chapInfo, videoInfo }) {
    const dispatch = useDispatch();  
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        dispatch(setChapState(chapInfo));
    },[])

    useEffect(() => {
        if (chapInfo) {
            dispatch(setCurrentMediaUUID(chapInfo[0].uuid))
            dispatch(setCurrentMediaInfo(videoInfo));
        }
    },[chapInfo])
    
    return (
        chapInfo && chapInfo.map((el, index) => {
            return (
                <tr className="langRow" key={index}>
                    <td style={{  paddingLeft: 30  }}> { el.koLabel } </td>
                    <td></td>
                    <td>
                        <SubToggleSwitch key={index} langState={el} /> 
                    </td>
                    <td style={{  marginTop: 15 }}>
                        <EditColumn type='chap' setIsLoading={setIsLoading} index={index} el={el} uuid={chapInfo[0].uuid}  />
                    </td>
                </tr>
            )
        })
    );
}

export default LangRow;
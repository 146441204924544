//Actions
const PURGE = "user/PURGE";

const SET_USER_DATA = "user/SET_USER_DATA";
const SET_USER_TOKEN = "user/SET_USER_TOKEN";
const SET_USER_THUMBNAIL_CDN = "user/SET_USER_THUMBNAIL_CDN";
const SET_USER_VS_CDN = "use/SET_USER_VS_CDN";
const SET_POPUP_USER_DATA = "user/SET_POPUP_USER_DATA";
const SET_POPUP_USER_TOKEN = "user/SET_POPUP_USER_TOKEN";
const SET_POPUP_USER_THUMBNAIL_CDN = "user/SET_POPUP_USER_THUMBNAIL_CDN";
const SET_POPUP_USER_VS_CDN = "user/SET_POPUP_USER_VS_CDN";

// 액션 생성 함수
export const purgeUser = () => ({ type: PURGE });
export const setUserData = userData => ({ type: SET_USER_DATA, userData });
export const setUserToken = userToken => ({ type: SET_USER_TOKEN, userToken });
export const setUserThumbnailCDN = userThumbnailCDN => ({ type: SET_USER_THUMBNAIL_CDN, userThumbnailCDN });
export const setUserVSCdn = userVSCdn=> ({ type: SET_USER_VS_CDN, userVSCdn });
export const setPopupUserData = PopupUserData => ({ type: SET_POPUP_USER_DATA, PopupUserData });
export const setPopupUserToken = PopupUserToken => ({ type: SET_POPUP_USER_TOKEN, PopupUserToken });
export const setPopupUserThumbnailCDN = PopupUserThumbnailCDN => ({ type: SET_POPUP_USER_THUMBNAIL_CDN, PopupUserThumbnailCDN });
export const setPopupUserVSCdn = PopupUserVSCdn => ({ type: SET_POPUP_USER_VS_CDN, PopupUserVSCdn });

const initialState = {
    userData : '',
    userToken : '',
    userThumbnailCDN : '',
    userVSCdn: '',
    PopupUserData : {},
    PopupUserToken : '',
    PopupuserThumbnailCDN : '',
    PopupuserVSCdn: '',
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case PURGE: 
      return {
        ...initialState
      }
    case SET_USER_DATA:
      return {
          ...state,
          userData : action.userData
      };
    case SET_USER_DATA:
      return {
          ...state,
          userData : action.userData
      };
    case SET_USER_TOKEN:
      return {
          ...state,
          userToken : action.userToken
      };
    case SET_USER_THUMBNAIL_CDN:
      return {
          ...state,
          userThumbnailCDN : action.userThumbnailCDN
      };
    case SET_USER_VS_CDN:
      return {
          ...state,
          userVSCdn : action.userVSCdn
      };
    case SET_POPUP_USER_DATA:
      return {
          ...state,
          userData : action.PopupUserData
      };
    case SET_POPUP_USER_TOKEN:
      return {
          ...state,
          PopupUserToken : action.PopupUserToken
      };
    case SET_POPUP_USER_THUMBNAIL_CDN:
      return {
          ...state,
          PopupUserThumbnailCDN : action.PopupUserThumbnailCDN
      };
    case SET_POPUP_USER_VS_CDN:
      return {
          ...state,
          PopupUserVSCdn : action.PopupUserVSCdn
      };
    default:
      return state;
  }
}

import React, { useEffect } from 'react';

import { useHistory, Redirect } from "react-router-dom";
import Video from './Video';

function ForRedirect(props) {

    const history = useHistory();

    var path = location.hash.replace("#/",'');

    useEffect(() => {
        if ( path.includes('video') ) {
            var videoPath = path.replace("video",'');
            if (videoPath != '' && !videoPath.includes('detail') && !videoPath.includes('subtitle') && !videoPath.includes('chapter') && !videoPath.includes('editor')) {
                history.push('/');
            }
            console.log('videoPath',videoPath)
        } else{
            if (path != '' && path != 'main' && path != 'video' && path != 'subtitle' && path != 'chapter' && path != 'editor' && path != 'ffmpegTest' && path != 'AIVodQuiz') {
                history.push('/');
                console.log('path',path)
            }
        }
        var videoPath = path.replace("video",'');
        console.log('videoPath',videoPath)
    },[location.hash.replace("#/",'')])

    return (
        <></>
    );
}

export default ForRedirect;
import React from 'react';

import ReactApexChart from "react-apexcharts";

function WeeklyVideoUsage( { options1 } ) {

    const series3 = [
        {
          name: "My Tasks",
          data: [11, 12,22, 14, 32, 8, 22, 14, 32, 41]
        }
    ];

    return (
        <div className="col-4" style={{ marginBottom: 30 }}>
            <div className="dashboardBox" style={{ height: 300 }}>
                <div className="dashboardBoxTitle">
                    최근 7일 동영상 이용량
                </div>
                <div className="dashboardBoxContent">
                    <div style={{ fontSize: 50, fontStyle: 'italic', textAlign: 'center' }}> 
                        27361회
                    </div>
                </div>
            <ReactApexChart options={options1} series={series3} width="100%" height={150} type="area"/>
            </div>
        </div>
    );
}

export default WeeklyVideoUsage;
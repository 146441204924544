import React,{ useState, useEffect } from 'react';

import axios from 'axios';
import moment from 'moment';
import { Modal, Row, Table } from 'react-bootstrap';
import { ChasingDots } from 'better-react-spinkit';
import { FiRefreshCcw } from '@react-icons/all-files/fi/FiRefreshCcw';

import { useDispatch, useSelector } from 'react-redux';

import { setVideoList } from '../../../modules/media'
import { setProjectData } from '../../../modules/editor';
import VideoListPagination from '../../videoEdit/VideoListPagination';

function ConcatMenu({ compType, uuid, tk, ogc }) {

    const projectData = useSelector(state => state.editor.projectData);
    const projectInfo = useSelector(state => state.editor.projectInfo);
    const editProjIdx = useSelector(state => state.editor.editProjIdx);

    const [concatList, setConcatList] = useState([]);
    const [concatModalOpen, setConcatModalOpen] = useState(false);
    const [videoListCount, setVideoListCount] = useState(0);
    const [currentVodList,setCurrentVodList] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [type , setType] = useState('');
    const videoListPerPage = 30;

    const CDN = compType == "popup" ? useSelector(state => state.user.PopupuserThumbnailCDN) : useSelector(state => state.user.userThumbnailCDN);

    const dispatch = useDispatch();  

    useEffect(() => {

        var toastId = '';

        const indexOfLast = currentPage * videoListPerPage;
        const indexOfFirst = indexOfLast - videoListPerPage;
        var data = JSON.stringify({ 
            "userToken" : tk,
            "page": currentPage,
            "organizationCode" : ogc
        });
    
        var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/vod/getVodList',
            headers: { 
            'Content-Type': 'application/json'
            },
            data : data
        };
        
        axios(config)
        .then( (response) => {
            if(response.data.statusCode==100){
                setVideoListCount(response.data.vodList.count)
                setCurrentVodList(response.data.vodList.rows)
                dispatch(setVideoList(response.data.vodList.rows));
            }
        })
        .catch((error) => {
            console.log(error);
        });

    },[currentPage])

    useEffect(() => {
        if (projectData.length > 0) {
            projectData.map((el, idx) => {
                if (el.action == "concat") {
                    setConcatList(el.data);
                }
            })
        }
    },[])

    function handleSelectedRow(el) {
        var tmpConcatList = concatList;

        setConcatModalOpen(false);
        if (type == 'before') {
            tmpConcatList.before = {
                "title" : el.realFileName,
                "uuid" : el.uuid
            }
        }
        else if (type == 'after') {
            tmpConcatList.after = {
                "title" : el.realFileName,
                "uuid" : el.uuid
            }
        }
        fetchText();
    }

    function fetchText() {

        var tmpConcatList = concatList;

        var tmpProjectData = projectData;
        if (projectData.length > 0) {
            projectData.map((el, idx) => {
                if (el.action == "concat") {
                    tmpProjectData.splice(idx,1);
                }
            })
        }
        tmpProjectData.push({
            "action" : "concat",
            "data" : tmpConcatList
        });

        dispatch(setProjectData(tmpProjectData));

        var data = JSON.stringify({
            "tk" : tk,
            "uuid" : uuid,
            "ogc" : ogc,
            "project_id": editProjIdx,
            "projectInfo" : projectInfo,
            "projectData" : tmpProjectData
        });

        var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/editor/update',
            headers: { 
                'Content-Type': 'application/json'
                },
            data : data            
        };
            
        axios(config)
        .then( (response) => {
            console.log(response.data.data);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    return (
        <>
            <div style={{ height: 320, overflowY: 'scroll'}}>
                <div className='menuBox'>
                    <Row  key='crop_before' className='menuBoxRow'>
                        <div className='col-2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 20 }}>붙일 영상</div>
                        <div className='col-2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >위치 앞</div>
                        <div className='col-7'>
                            {
                                concatList.hasOwnProperty('before') ?
                                    <>
                                        <Row style={{ marginBottom: 10 }}>
                                            <div style={{ marginRight: 5 }}>영상 : </div>
                                            <div style={{ fontSize: 14 }}>
                                                {concatList.before.title}
                                            </div>
                                        </Row>
                                        <Row>
                                            <div style={{ marginRight: 5 }}>UUID : </div>
                                            <div style={{ fontSize: 14 }}>{concatList.before.uuid}</div>
                                        </Row>
                                    </>
                                :
                                    <div style={{ cursor : 'pointer' }} onClick={(e) => {setConcatModalOpen(true); setType('before')}}>영상 찾기</div>
                            }
                        </div>
                        <div className='col-1' style={{ cursor : 'pointer' }} onClick={(e) => {setConcatModalOpen(true); setType('before')}}>
                            <FiRefreshCcw />
                        </div>
                    </Row>

                    <Row  key='crop_after' className='menuBoxRow'>
                        <div className='col-2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 20 }}>붙일 영상</div>
                        <div className='col-2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >위치 뒤</div>
                        <div className='col-7'>
                            {
                                concatList.hasOwnProperty('after') ?
                                    <>  
                                        <Row style={{ marginBottom: 10 }}>
                                            <div style={{ marginRight: 5 }}>영상 : </div>
                                            <div style={{ fontSize: 14 }}>
                                                {concatList.after.title}
                                            </div>
                                        </Row>
                                        <Row>
                                            <div style={{ marginRight: 5 }}>UUID : </div>
                                            <div style={{ fontSize: 14 }}>{concatList.after.uuid}</div>
                                        </Row>
                                    </>  
                                :
                                    <div style={{ cursor : 'pointer' }} onClick={(e) => {setConcatModalOpen(true); setType('after')}}>영상 찾기</div>
                            }
                        </div>
                        <div className='col-1' style={{ cursor : 'pointer' }} onClick={(e) => {setConcatModalOpen(true); setType('after')}}>
                            <FiRefreshCcw />
                        </div>
                    </Row>
                </div>
            </div>



            <Modal className="editModal" show={concatModalOpen} onHide={() => setConcatModalOpen(false)}>
                <Modal.Header style={{ fontSize: 20 }} closeButton> 영상 선택하기 </Modal.Header>
                <Modal.Body className="editModalBody" style={{ position: "relative", height: '100%', overflow: 'scroll' }}>
                    <div className='VideoList'>
                        <div className="wrapper">
                        { 
                            currentVodList.length > 0 ?
                                <>
                                    <Table className="videoListTable concat">
                                        <thead>
                                        <tr>
                                            <th scope="col">동영상</th>
                                            <th scope="col">파일명</th>
                                            <th scope="col">생성날짜</th>
                                            <th scope="col">시간</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                    currentVodList.map((el,idx) => { 
                                                        return (
                                                            <tr key={el.id} className="videoListRow" onClick={(e) => handleSelectedRow(el)}>
                                                                <td className='videoThumbnailColumn' style={{ paddingLeft: 30, width: '30%' }}>
                                                                    <div className="videoThumbnail">
                                                                        <img src={ CDN ? `${CDN}/${el.uuid}/thumbnail/tn_1.png` : '' }></img>
                                                                    </div>
                                                                </td>
                                                                <td className='contentColumn'>
                                                                    <div className='videoTitle'>{el.realFileName}</div>
                                                                </td>
                                                                <td className='videoCreateAtColumn'>{moment(el.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                                <td className='videoPlayTimeColumn'>{moment(moment.utc(el.playTime * 1000)).format("HH:mm:ss")}</td>
                                                            </tr>
                                                        )
                                                    })

                                            }
                                        </tbody>
                                    </Table>
                                    <VideoListPagination 
                                            videoListPerPage={videoListPerPage}
                                            totalVodList={videoListCount}
                                            paginate={setCurrentPage}
                                            currentPage={currentPage}
                                    />
                                </>
                            :   
                            <div>
                                <div className="LoadingSpinner">
                                    <ChasingDots size={35} color='#23adad' />
                                </div> 
                            </div>
                        }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ConcatMenu;
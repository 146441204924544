import React, { useState, useEffect } from "react";

import { Link, useLocation } from "react-router-dom";
import { RiDashboardFill } from '@react-icons/all-files/ri/RiDashboardFill';
import { RiVideoFill } from '@react-icons/all-files/ri/RiVideoFill';
import { FaClosedCaptioning } from '@react-icons/all-files/fa/FaClosedCaptioning';
import { FaThList } from '@react-icons/all-files/fa/FaThList';
import { FaCut } from '@react-icons/all-files/fa/FaCut';

import '../../../resources/assets/styles/sass/SideBar.scss';

function SideBar(props) {
    //dashboard 완성되면 다시 0으로 고치기
    const [selectedTab, setSelectedTab] = useState(1);
    const { pathname, state } = useLocation(0);

    useEffect(() => {
        if (pathname.indexOf('video') > 0) {
            setSelectedTab(1)
        }  
        else if (pathname.indexOf('subtitle') > 0) {
            setSelectedTab(2)
        }
        else if (pathname.indexOf('chapter') > 0) {
            setSelectedTab(3)
        }
        else if (pathname.indexOf('editor') > 0) {
            setSelectedTab(4)
        }
        else if (pathname.indexOf('main') > 0) {
          setSelectedTab(0)
      }

    },[])

    return (
      <>
        <div className='sidebarContainer'>

          <div className='navbarContentRegister'>
            <nav>   
                <div>
                  <Link to={'/main'}>
                    <div className = {`${selectedTab === 0 ? 'nav-menu-items active' : 'nav-menu-items'}`}>
                      <div className = "nav-menu-itemsIcon">
                        <RiDashboardFill size="23"/>
                      </div>
                      <div className = "nav-menu-itemsText">
                          대시보드
                      </div>
                    </div>
                  </Link>

                  <Link to={"/video"}>
                    <div className = {`${selectedTab === 1 ? 'nav-menu-items active' : 'nav-menu-items'}`}>
                      <div className = "nav-menu-itemsIcon">
                        <RiVideoFill size="23" />
                      </div>
                      <div className = "nav-menu-itemsText">
                          동영상
                      </div>
                    </div>
                  </Link>

                  <Link to="/subtitle">
                    <div className = {`${selectedTab === 2 ? 'nav-menu-items active' : 'nav-menu-items'}`}>
                      <div className = "nav-menu-itemsIcon">
                          <FaClosedCaptioning size="23" />
                      </div>
                      <div className = "nav-menu-itemsText">
                          자막
                      </div>
                    </div>
                  </Link>

                  <Link to="/chapter">
                    <div className = {`${selectedTab === 3 ? 'nav-menu-items active' : 'nav-menu-items'}`}>
                      <div className = "nav-menu-itemsIcon" id="chapterIcons">
                        <FaThList size="23"/>
                      </div>
                      <div className = "nav-menu-itemsText">
                          챕터
                      </div>
                    </div>
                  </Link>

                  <Link to="/editor">
                    <div className = {`${selectedTab === 4 ? 'nav-menu-items active' : 'nav-menu-items'}`}>
                      <div className = "nav-menu-itemsIcon">
                        <FaCut size="23" />
                      </div>
                      <div className = "nav-menu-itemsText">
                          편집기
                      </div>
                    </div>
                  </Link>

                  {/* <Link to="/analytics">
                    <div className = "nav-menu-items">
                      <div className = "nav-menu-itemsIcon">
                        <i className="far fa-cloud-upload"></i>
                      </div>
                      <div className = "nav-menu-itemsText">
                          통계
                      </div> 
                    </div>
                  </Link> */}

                  {/* <Link to="/settings">                  
                    <div className = "nav-menu-items">
                      <div className = "nav-menu-itemsIcon">
                        <i className="far fa-address-card"></i>
                      </div>
                      <div className = "nav-menu-itemsText">
                          설정
                      </div>
                    </div>
                  </Link> */}
                </div>
            </nav>
          </div>

        </div>
      </>
    );
  };


export default SideBar;

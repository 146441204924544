import React, { useState, useEffect, useRef } from 'react';

import { Row, Table } from 'react-bootstrap';
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { toast, Slide, Flip } from 'react-toastify';
import { ChasingDots } from 'better-react-spinkit';

import { setCurrentMediaInfo } from '../modules/media';
import { setEditProjIdx, setEditToolIdx } from '../modules/editor';

import Player from '../components/editor/Player';
import EditList from '../components/editor/EditList';
import EditTools from '../components/editor/EditTools';
import Header from '../components/_commons/Header';
import SideBar from '../components/_commons/SideBar';
import VideoListPagination from '../components/videoEdit/VideoListPagination';
import EditVideoList from '../components/editor/EditVideoList';
import EditComp from '../components/editor/EditComp';

function Editor() {

    const [uuid, setUuid] = useState('');

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        var path = location.hash.replace("#/editor",'');

        if (path != '') {
            history.push('/editor');
        }
    },[location.hash.replace("#/editor",'')])

    return (
        <>
            <Header></Header>
            <Row style={{ width: '100%', margin: 0  }}>
                <SideBar/>
                <div className='dashboardArea' style={{ width: '100%' }}>
                    <div className="contentHeader" style={{ display: 'flex', marginBottom: 20, alignItems: 'center', padding: 20 }}>
                        <div className='containerSubtitleTitle'>
                            <div> 편집기 </div>
                        </div>
                        <div className='titleDesc' style={{ marginLeft: 10 }}>
                            <div>· 프로젝트는 동영상 당 최대 5개까지 생성가능합니다.</div>
                        </div>
                    </div>

                    {
                        uuid == '' ?
                            <EditVideoList setUuid={setUuid} />
                            :
                            <EditComp uuid={uuid} setUuid={setUuid} />
                    }

{/*             
                    <Row style={{ marginBottom: 15, display: 'flex', justifyContent: 'space-between', alignItems : 'center' }}>
                        { 
                            editProjIdx > -1 ?
                            <div style={{ display: 'flex', marginLeft: 30, color: 'gray', cursor: 'pointer' }} onClick={() => { dispatch(setEditProjIdx(-1)); dispatch(setEditToolIdx(0)); } }>
                                <div style={{ marginRight : 12 }}><FaAngleLeft size="23" /></div>
                                <div>프로젝트 목록보기</div>
                            </div>
                            :null
                        }
                            
                        {
                            editProjIdx > -1 ?
                                <div style={{ fontSize: 14, display: 'flex' }}>
                                    <Button style={{ width: 85, height: 30, marginRight: 15 }} >영상 생성</Button>
                                </div>
                            :null
                        }
                    </Row> */}

                </div>

            </Row>
        </>  
    );
}

export default Editor;
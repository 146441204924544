//Actions
const PURGE = "media/PURGE";
const SET_VIDEO_LIST = "media/SET_VIDEO_LIST";

const SET_CURRENT_MEDIA_UUID = "media/SET_MEDIA_UUID";
const SET_POPUP_MEDIA_UUID = "media/SET_POPUP_MEDIA_UUID";
const SET_CURRENT_MEDIA_INFO = "media/SET_MEDIA_INFO";
const SET_POPUP_MEDIA_INFO = "media/SET_POPUP_MEDIA_INFO";

const SET_CURRENT_MEDIA_SUBTITLELIST = "media/SET_CURRENT_MEDIA_SUBTITLELIST";
const SET_CURRENT_MEDIA_CHAPTERLIST = "media/SET_CURRENT_MEDIA_CHAPTERLIST";

const SET_VIDEO_DURATION_TIME = "media/SET_VIDEO_DURATION_TIME"
const SET_PLAYINGTIME_TIMELINE_TO_MEDIAPLAYER = "media/SET_PLAYINGTIME_TIMELINE_TO_MEDIAPLAYER";
const SET_PLAYINGTIME_MEDIAPLAYER_TO_TIMELINE = "media/SET_PLAYINGTIME_MEDIAPLAYER_TO_TIMELINE";

const SET_THUMBNAILS = "media/SET_THUMBNAILS";


// 액션 생성 함수
export const purgeMedia = () => ({ type: PURGE });

export const setVideoList = videoList => ({ type: SET_VIDEO_LIST, videoList});

export const setCurrentMediaUUID = uuid => ({ type: SET_CURRENT_MEDIA_UUID, uuid });
export const setPopupMediaUUID = popupUuid => ({ type: SET_POPUP_MEDIA_UUID, popupUuid });
export const setCurrentMediaInfo = vodInfo => ({ type: SET_CURRENT_MEDIA_INFO, vodInfo });
export const setPopUpMediaInfo = popupvodInfo => ({ type: SET_POPUP_MEDIA_INFO, popupvodInfo });

export const setCurrentMediaSubtitleList = subtitleList => ({ type: SET_CURRENT_MEDIA_SUBTITLELIST, subtitleList });
export const setCurrentMediaChapterList = chapterList => ({ type: SET_CURRENT_MEDIA_CHAPTERLIST, chapterList });

export const setVideoDurationTime = videoDurationTime => ({ type: SET_VIDEO_DURATION_TIME, videoDurationTime })
export const setPlayingTimeTimelineToMediaPlayer = TimelinePlayingTime => ({ type: SET_PLAYINGTIME_TIMELINE_TO_MEDIAPLAYER, TimelinePlayingTime });
export const setPlayingTimeMediaPlayerToTimeline = MediaPlayerPlayingTime => ({ type: SET_PLAYINGTIME_MEDIAPLAYER_TO_TIMELINE, MediaPlayerPlayingTime });

export const setThumbnails = thumbnails => ({ type: SET_THUMBNAILS, thumbnails });

// 초깃값 (상태가 객체가 아니라 그냥 숫자여도 상관 없습니다.)
const initialState = {
  videoList: '',
  fileName : '',
  uuid : '',
  popupUuid: '',
  vodInfo: {},
  popupvodInfo: {},
  streamList : [],
  subtitleList : [],
  chapterList : [],
  videoDurationTime: 0,
  TimelinePlayingTime : 0,
  MediaPlayerPlayingTime: 0,
  message: '',
  thumbnails: [],
  isCropAndDuration: { isCrop: false, duration: 0},
  startEnd : { start: 0, end: 0 },
  thumnailStatus : '',
  filterStatus: ''
};

export default function media(state = initialState, action) {
  switch (action.type) {
    case PURGE: 
      return {
        ...initialState
      }
    case SET_VIDEO_LIST:
      return {
        ...state,
        videoList : action.videoList
      }
    case SET_CURRENT_MEDIA_UUID:
      return {
        ...state,
        uuid : action.uuid
      }
    case SET_POPUP_MEDIA_UUID:
      return {
        ...state,
        popupUuid : action.popupUuid
      }
    case SET_CURRENT_MEDIA_INFO:
      return {
        ...state,
        vodInfo : action.vodInfo
      }
    case SET_POPUP_MEDIA_INFO:
      return {
        ...state,
        popupvodInfo : action.popupvodInfo
      }
    case SET_CURRENT_MEDIA_SUBTITLELIST:
      return {
        ...state,
        subtitleList : action.subtitleList
      }
    case SET_CURRENT_MEDIA_CHAPTERLIST:
      return {
        ...state,
        chapterList : action.chapterList
      }
    case SET_VIDEO_DURATION_TIME:
      return {
        ...state,
        videoDurationTime : action.videoDurationTime
      }
    case SET_PLAYINGTIME_TIMELINE_TO_MEDIAPLAYER:
      return {
        ...state,
        TimelinePlayingTime : action.TimelinePlayingTime
      }         
    case SET_PLAYINGTIME_MEDIAPLAYER_TO_TIMELINE:
      return {
        ...state,
        MediaPlayerPlayingTime : action.MediaPlayerPlayingTime
      }        
    case SET_THUMBNAILS:
      return {
        ...state,
        thumbnails : action.thumbnails
      }             
    default:
        return state;
  }
} 
import './resources/assets/styles/sass/Common.scss';
import './resources/assets/styles/bootswatch/yeti/bootstrap.min.css'
import React, {useState} from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { configureStore } from "@reduxjs/toolkit";
import { Provider} from 'react-redux'
import reducer from './reducers/index';
import logger from 'redux-logger';
import {PersistGate} from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import ReduxThunk from 'redux-thunk';

const store = configureStore({
  reducer : reducer, // 리듀서 들을 정의합니다.
  middleware: [ReduxThunk, logger], // 미들웨어를 정의해주도록 합니다.
})

//const store = createStore(reducer, composeWithDevTools(applyMiddleware(ReduxThunk, logger)))
export const persistor = persistStore(store)

const mainElement = document.createElement('div');
mainElement.className = "main";
document.body.appendChild(mainElement);

ReactDOM.render(
    <Provider store={store} >
      <PersistGate persistor={persistor}>
          <App />          
      </PersistGate>
    </Provider>,
  mainElement
); 


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals 
import React, { useState, useEffect, useRef } from 'react';
import { Wave } from 'better-react-spinkit';

import ReactApexChart from "react-apexcharts";
import Loading from '../../_commons/Loading';

function VideoNStorage({ groupedData }) {

    const [series, setSeries] = useState([]);
    const [options, setOptions] = useState({
      dataLabels: {
        enabled: false
      },
      xaxis: {
        type: 'datetime',
        labels: {
          format: 'MM/dd',
        }
      },
      yaxis: [{
        seriesName: '업로드 영상 수',
        title: {
          text: '개수'
        },
        axisTicks: {
          show: true,
        }
      },
      {
        show: false,
        seriesName: '변환 수',
        axisTicks: {
          show: true,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        }
      },
      {
        seriesName: '원본 영상 크기',
        title: {
          text: "영상 크기 (GB)"
        },
        opposite: true,
        axisTicks: {
          show: true,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " GB"
            }
          }
        }
      },
      {
        show: false,
        seriesName: '변환된 용량',
        opposite: true,
        axisTicks: {
          show: true,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " GB"
            }
          }
        }
      }
      ]
    })

    useEffect(() => {
      if (groupedData.length > 0){
          var dateArray = groupedData.map(item => item.date);
          var convertedSizeArray = groupedData.map(item => item.data.convertedSize);
          var oriSizeArray = groupedData.map(item => item.data.oriSize);
          var succeedCountArray = groupedData.map(item => item.data.succeedCount);
          var vodCountArray = groupedData.map(item => item.data.vodCount);

          setOptions({
            ...options,
            labels: dateArray
          })
          setSeries([
            {
              name: '업로드 영상 수',
              data: vodCountArray
            }, {
              name: '변환 수',
              data: succeedCountArray
            },
            {
            name: '원본 영상 크기',
            data: oriSizeArray
            }, {
              name: '변환된 용량',
              data: convertedSizeArray
            }
        ])

      }
    },[groupedData])

    return (
        <div className="col-12" style={{ marginBottom: 30 }}>
            <div className="dashboardBox" style={{ }}>
                <div className="dashboardBoxTitle" style={{ fontWeight: 500, marginBottom: 30 }}>
                    VOD 스토리지 이용량
                </div>
                <div className="dashboardBoxContent" style={{ width: '100%' }}>
                  { 
                    groupedData && groupedData.length > 0 ? 
                      <ReactApexChart options={options} series={series} id= "chartVideoNStorage_area" type="bar" width="90%" height={350} />
                      :
                      <div style={{ width: '100%', height: 350 }}>
                          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 150 }}>
                            <Wave size={35} color='#ccc' />
                          </div>
                      </div>
                  }
                </div>
            </div>
        </div>
    );
}

export default VideoNStorage;
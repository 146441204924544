import React, { MutableRefObject, useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

interface Props {
  thumbnailRef: MutableRefObject<HTMLDivElement>;
}

const moveSlider = (location: number) => `
  @keyframes moveSlider {
    from {
      left: ${location}px;
    }
    to {
      left: 100%;
    }
  }
`;

const Slider: React.FC<Props> = React.memo(({ thumbnailRef, modalPlayState }) => {
  const [location, setLocation] = useState(0);
  const [restDuration, setRestDuration] = useState(0);
//   const { start, end } = useSelector(getStartEnd, shallowEqual);

//   const { isCrop, duration } = useSelector(getIsCropAndDuration);
const videoDurationTime = useSelector(state => state.media.videoDurationTime);
const isPlaying = useSelector(state => state.status.videoPlayState);
const videoCurrentTime = useSelector(state => state.status.videoCurrentTime);



  useEffect(() => {
    console.log('isPlaying',isPlaying)
    if (isPlaying) {
        const width = thumbnailRef.current.clientWidth;

        console.log('width',width);
        // const totalDuration = isCrop ? duration : end - start;
    
        // const movedLocation = videoDurationTime
        //   ? ((videoCurrentTime - (!isCrop && start)) / videoDurationTime) * width
        //   : 0;
        const movedLocation = videoDurationTime
          ? (videoCurrentTime / videoDurationTime) * width
          : 0;
        setLocation(movedLocation);
        const restWidth = width - movedLocation;
        setRestDuration((restWidth / width) * videoDurationTime);
    }
  }, [videoDurationTime, isPlaying]);

  useEffect(() => {
    console.log('location', location)
  },[location])

  const dynamicStyles = moveSlider(location);

  return (
    <div
      style={{
        position: 'absolute',
        left: `${location}px`,
        width: '1px',
        border: `solid 1px gary`,
        backgroundColor: `gray`,
        height: '7rem',
        zIndex: 5,
        animation: isPlaying
        ? `moveSlider ${videoDurationTime}s linear forwards`
        : 'none',
      }}
    />
  );
});

export default Slider;

import React, { useState, useEffect }  from 'react';

import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { setSubtitleList, setSelectedSubtitle } from '../../../modules/subtitle';
import axios from 'axios';

function TimeInput({ uuid, idx, lang, start, end, el, setParsedSub, tk, ogc }) {

    const subtitleList = useSelector(state => state.subtitle.subtitleList);
    const timelineText = useSelector(state => state.status.timelineText);

    const dispatch = useDispatch();
    const [tmpSelectedSub, setTmpSelectedSub] = useState(el);
    const [startVal, setStartVal] = useState(start);
    const [endVal, setEndVal] = useState(end);

    useEffect(() => {
        if(timelineText && el.id == timelineText.id) {   
            setStartVal(timelineText.start);
            setEndVal(timelineText.end);
        }
    },[timelineText])

    useEffect(() => {
        if (subtitleList.length > 0) {
            setStartVal(subtitleList[idx].start);
            setEndVal(subtitleList[idx].end);
        }
    },[subtitleList])

    function changeTime(e, idx , type){
        e.preventDefault();

        if (type == "start") {
            var time = e.currentTarget.textContent;
            //변경하려는 시간이 왼쪽에 있는 기존 아이템과 겹칠 때
            if (subtitleList[idx-1] != undefined && moment('1970-01-01 ' + time).isBefore(moment(subtitleList[idx-1].end)) ) {

                setTmpSelectedSub( {
                    ...el,
                    start: subtitleList[idx-1].end
                });
            } else {
                            
                setTmpSelectedSub( {
                    ...el,
                    start: '1970-01-01 '+ time
                });
            }
        }
        else if (type == "end") {
            var time = e.currentTarget.textContent;

            //변경하려는 시간이 오른쪽에 있는 기존 아이템과 겹칠 때
            if (subtitleList[idx+1] != undefined && moment('1970-01-01 ' + time).isAfter(moment('1970-01-01 ' + subtitleList[idx+1].start))) {

                setTmpSelectedSub( {
                    ...el,
                    end : subtitleList[idx+1].start
                });
            } else {
                setTmpSelectedSub( {
                    ...el,
                    end : '1970-01-01 '+ time
                });
            }
            
        }
    }

    function fetchTime(e, tmpSelectedSub) {
        e.preventDefault();

        var tmpSubList = [...subtitleList];
        tmpSubList[idx] = tmpSelectedSub;
        dispatch(setSelectedSubtitle(tmpSelectedSub));

        dispatch(setSubtitleList(tmpSubList));
        setParsedSub(tmpSubList);
        
        console.log('tmpSubList',tmpSubList)
        var tmpParsed = tmpSubList.map(list => Object.assign({
            identifier: String(list.id),
            start: moment.duration(moment(list.start).diff(moment('1970-01-01 00:00:00.000'))).asSeconds(),
            end: moment.duration(moment(list.end).diff(moment('1970-01-01 00:00:00.000'))).asSeconds(),
            text: list.content,
            styles: ""
        })
        )

        var data = JSON.stringify({
            "tk" : tk,
            "uuid" : uuid,
            "ogc" : ogc ,
            "lang" : lang,
            "subtitle" : tmpParsed
        });

        var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/subtitle/aUpdate',
            headers: { 
                'Content-Type': 'application/json'
                },
            data : data            
        };
            
        axios(config)
        .then( (response) => {
            console.log(response)
            var data = response.data.data;
            console.log('edit data',data)
            //var langData = data.subtitle;
            
        })
        .catch((error) => {
            console.log(error);
        });
    }

    // edit API
    // function fetchTime(e, tmpSelectedSub) {
    //     e.preventDefault();

    //     var tmpSubList = [...subtitleList];
    //     tmpSubList[idx] = tmpSelectedSub;
    //     dispatch(setSubtitleList(tmpSubList))

    //     var data = JSON.stringify({
    //         "tk" : userToken,
    //         "uuid" : uuid,
    //         "ogc" : organizationData.value,
    //         "lang" : lang,
    //         "subtitle" : [{
    //             "identifier" : String(idx),
    //             "text": tmpSubList[idx].content
    //         }]
    //     });

    //     var config = {
    //         method: 'post',
    //         url: API_URL + '/' + API_VERSION + '/subtitle/edit',
    //         headers: { 
    //             'Content-Type': 'application/json'
    //             },
    //         data : data            
    //     };
            
    //     axios(config)
    //     .then( (response) => {
    //         console.log(response)
    //         var data = response.data.data;
    //         console.log('edit data',data)
    //         //var langData = data.subtitle;
            
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //     });
    // }

    return (
        <div className='block-editable-time'  onBlur={e =>fetchTime(e, tmpSelectedSub)}>
            <div className='editable-time' contentEditable="true" 
                onInput={e => changeTime(e, idx, 'start') }
                suppressContentEditableWarning={true}
            >
                { moment(startVal).format('HH:mm:ss.SSS') }
            </div>
        <div className='block-editable-time block-editable-end'  onBlur={e => fetchTime(e, tmpSelectedSub)}>
            <div className='editable-time' contentEditable="true" 
                    onInput={e => changeTime(e, idx, 'end') }
                    suppressContentEditableWarning={true}
                >
                    { moment(endVal).format('HH:mm:ss.SSS') }
            </div>
            </div>
        </div>
    );
}

export default TimeInput;
import {combineReducers} from 'redux';
import {persistReducer} from "redux-persist";

import rootReducer from '../app/modules';
import storage from "redux-persist/lib/storage";

const persistConfig = {
    key: "root",
    storage: storage,
    blacklist: ['aiQuiz']
};

export default persistReducer(persistConfig,rootReducer);
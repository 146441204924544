import React, { useEffect, useState, useRef } from 'react';

import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FaAngleUp } from '@react-icons/all-files/fa/FaAngleUp';
import { FaAngleDown } from '@react-icons/all-files/fa/FaAngleDown';
import { RiScissorsFill } from '@react-icons/all-files/ri/RiScissorsFill';
import { MdBrandingWatermark } from '@react-icons/all-files/md/MdBrandingWatermark';
import { AiOutlineMergeCells } from '@react-icons/all-files/ai/AiOutlineMergeCells';

import CropMenu from './crop/CropMenu';
import CutMenu from './cut/CutMenu';
import InfoCardMenu from './info/InfoCardMenu';
import ConcatMenu from './concat/ConcatMenu';
import ResultMenu from './result/ResultMenu';
import WatermarkMenu from './watermark/WatermarkMenu';

import { setEditToolIdx } from '../../modules/editor';
import ThumbnailGenerator from './ThumbnailGenerator';

function EditTools( { type, uuid, tk, ogc }) {

    const [editToolIdx, setEditToolIdx] = useState(0);

    const dispatch = useDispatch();  

    function RenderMenus() {
     
        switch(editToolIdx) {
            case 1 :
                return <CutMenu compType={type} uuid={uuid} tk={tk} ogc={ogc} />;
            case 2 :
                return <ConcatMenu compType={type} uuid={uuid} tk={tk} ogc={ogc} />;
            case 3 :
                return <WatermarkMenu uuid={uuid} tk={tk} ogc={ogc} />;
            case 4 :
                return <ResultMenu compType={type} uuid={uuid} tk={tk} ogc={ogc} />;
            case 5 :
                return <InfoCardMenu uuid={uuid} tk={tk} ogc={ogc} />;
            default :
                return null;
        }
    }
    
    return (
        <>

            {
                editToolIdx == 0 || editToolIdx == 1 ?
                    <Row key='cut' className='EditToolList' onClick={() => editToolIdx > 0 ? setEditToolIdx(0) : setEditToolIdx(1)}>
                        <div className="col-2 iconCol">
                            <div><RiScissorsFill size="25" /></div>
                        </div>
                        <div className="col-3" style={{ fontSize: 20 }}>
                            <div>자르기</div>
                        </div>
                        <div  className="col-6">
                            
                        </div>
                        <div className='col-1 btns' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            {
                                editToolIdx == 1 ?
                                    <FaAngleUp size="20" color="gray" />
                                :
                                    <FaAngleDown size="20" color="gray" />
                            }
                        </div>
                    </Row>
                : null
            }

            {
                editToolIdx == 0 || editToolIdx == 2 ?
                    <Row key='concat' className='EditToolList' onClick={() => editToolIdx > 0 ? setEditToolIdx(0) : setEditToolIdx(2) }>
                        <div className="col-2 iconCol">
                            <div><AiOutlineMergeCells size="25" /></div>
                        </div>
                        <div className="col-2" style={{ fontSize: 20 }}>
                            <div>붙이기</div>
                        </div>
                        <div  className="col-7">
                            {
                                editToolIdx == 2 ?
                                    <div style={{ fontSize: 12, color: 'gray' }}>붙이기는 영상의 앞뒤에만 가능합니다.</div> : null
                            }
                        </div>
                        <div className='col-1 btns' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            {
                                editToolIdx == 2 ?
                                    <FaAngleUp size="20" color="gray" />
                                :
                                    <FaAngleDown size="20" color="gray" />
                            }
                        </div>
                    </Row>
                : null
            }

            {
                editToolIdx == 0 || editToolIdx == 3 ?
                    <Row key='watermark' className='EditToolList' onClick={() => editToolIdx > 0 ? setEditToolIdx(0) : setEditToolIdx(3) }>
                        <div className="col-2 iconCol">
                            <div><MdBrandingWatermark size="25" /></div>
                        </div>
                        <div className="col-3" style={{ fontSize: 20 }}>
                            <div>워터마크</div>
                        </div>
                        <div  className="col-6">

                        </div>
                        <div className='col-1 btns' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            {
                                editToolIdx == 3 ?
                                    <FaAngleUp size="20" color="gray" />
                                :
                                    <FaAngleDown size="20" color="gray" />
                            }
                        </div>
                    </Row>
                    
                : null
            }

            {/* {
                editToolIdx == 0 || editToolIdx == 4 ?
                    <Row key='result' className='EditToolList' onClick={() => editToolIdx > 0 ? setEditToolIdx(0) : setEditToolIdx(4)}>
                        <div className="col-2 iconCol">
                            <div><MdFeaturedVideo size="25" /></div>
                        </div>
                        <div className="col-3" style={{ fontSize: 20 }}>
                            <div>최종 화면</div>
                        </div>
                        <div  className="col-6">
                            
                        </div>
                        <div className='col-1 btns' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            {
                                editToolIdx == 4 ?
                                    <FaAngleUp size="20" color="gray" />
                                :
                                    <FaAngleDown size="20" color="gray" />
                            }
                        </div>
                    </Row>
                : null
            }

            {
                editToolIdx == 0 || editToolIdx == 5 ?
                    <Row key='infoCard' className='EditToolList' onClick={() => editToolIdx > 0 ? setEditToolIdx(0) : setEditToolIdx(5)}>
                        <div className="col-2 iconCol">
                            <div><MdInfoOutline size="25" /></div>
                        </div>
                        <div className="col-3" style={{ fontSize: 20 }}>
                            <div>정보 카드</div>
                        </div>
                        <div  className="col-6">
                            
                        </div>
                        <div className='col-1 btns' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            {
                                editToolIdx == 5 ?
                                    <FaAngleUp size="20" color="gray" />
                                :
                                    <FaAngleDown size="20" color="gray" />
                            }
                        </div>
                    </Row>
                : null
            } */}

            <RenderMenus />
        </>
    );
}

export default EditTools;



import React, { useState, useEffect, useRef } from 'react';

import { Table, Button, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { ChasingDots, Wave } from 'better-react-spinkit';
import { toast, Slide, Flip } from 'react-toastify';
import { VscCheck } from '@react-icons/all-files/vsc/VscCheck';
import { VscError } from '@react-icons/all-files/vsc/VscError';
import { CgSpinner } from '@react-icons/all-files/cg/CgSpinner';
import { TiWarningOutline } from '@react-icons/all-files/ti/TiWarningOutline';

import {
    setSubState
} from '../../../modules/subtitle';

import ToggleSwitch from "../_commons/ToggleSwitch";
import DeleteColumn from './DeleteColumn';
import EditColumn from './EditColumn';
import Loading from '../../_commons/Loading';

function VideoDetailSubtitleList() {

    const location = useLocation();
    const dispatch = useDispatch();

    const [autoGenSubState, setAutoGenSubState] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [translateSubState, setTranslateSubState] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [translateSubModalOpen, setTranslateSubModalOpen] = useState(false);
    const [translateInfo, setTranslateInfo] = useState({  lang: '', index : -1 });
    const [generateSubModalOpen, setGenerateSubModalOpen] = useState(false);
    const [loadingSubModalOpen, setLoadingSubModalOpen] = useState(false);
    const [translateErrorModalOpen, setTranslateErrorModalOpen] = useState(false);
    const [generateInfo, setGenerateInfo] = useState({  lang: '', index : -1 });
    const [isFetched, setIsFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const userToken = useSelector(state => state.user.userToken);
    const organizationData = useSelector(state => state.organization.organizationData);
    const subState = useSelector(state => state.subtitle.subState);

    var autoGenInterval;

    const koLabelArr = [
                            { "lang" : "ko", "koLabel" : "한글" },
                            { "lang" : "en", "koLabel" : "영어" },
                            { "lang" : "ja", "koLabel" : "일본어" },
                            { "lang" : "zh-CN", "koLabel" : "중국어 - 간체" },
                            { "lang" : "zh-TW", "koLabel" : "중국어 - 번체" },  
                            { "lang" : "vi", "koLabel" : "베트남어" },
                            { "lang" : "id", "koLabel" : "인도네시아어" },
                            { "lang" : "th", "koLabel" : "태국어" }, 
                            { "lang" : "de", "koLabel" : "독일어" },
                            { "lang" : "ru", "koLabel" : "러시아어" },
                            { "lang" : "es", "koLabel" : "스페인어" },
                            { "lang" : "it", "koLabel" : "이탈리아어" },
                            { "lang" : "fr", "koLabel" : "프랑스어" },
                        ]

    const toastId = useRef();

    useEffect(() => {

        //redux 초기화
        return () => {
            dispatch(setSubState([]));
        };

    },[])

    useEffect(() => {
  
        var data = JSON.stringify({
            "tk" : userToken,
            "uuid" : location.pathname.replace("/video/subtitle/",''),
            "ogc" : organizationData.value,
            "type" : "ALL"
        });

        var config = {
          method: 'post',
          url: API_URL + '/' + API_VERSION + '/subtitle/getState',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        axios(config)
        .then( (response) => {
            if(response.data.code==100){
                var tmpSubState = new Array(13).fill(0);
                if (response.data.data.subtitle.length > 0) {
                    response.data.data.subtitle.map((el, index) => {
                        /*
                            언어 순서 조정 및 한국어 라벨 저장하기
                        */
                       var lang = el.lang;

                       //하이픈 비교 오류 방지
                       if (el.lang && el.lang.includes('-')) {
                            lang = el.lang.replace('-','')
                       }

                       switch(lang) 
                       {
                           case "ko":
                               tmpSubState[0] = el;
                           break;
                           case "en":
                               tmpSubState[1] = el;
                           break;                        
                           case "ja":
                               tmpSubState[2] = el;
                           break;
                           case "zhCN":
                               tmpSubState[3] = el;
                           break;    
                           case "zhTW":
                               tmpSubState[4] = el;
                           break;  
                           case "vi":
                               tmpSubState[5] = el;
                           break;      
                           case "id":
                               tmpSubState[6] = el;
                           break;    
                           case "th":
                               tmpSubState[7] = el;
                           break;     
                           case "de":
                               tmpSubState[8] = el;
                           break;    
                           case "ru":
                               tmpSubState[9] = el;
                           break;   
                           case "es":
                               tmpSubState[10] = el;
                           break;    
                           case "it":
                               tmpSubState[11] = el;
                           break;   
                           case "fr":
                               tmpSubState[12] = el;
                           break;      
                       }
                        
                    })
                }

                dispatch(setSubState([...tmpSubState]));
                setIsFetched(true);
            }
        })
        .catch((error) => {
            console.log(error);
        });
        
    }, [location.pathname.replace("/video/subtitle/",'')]);


    function handleGenerate(lang, index) {
        // 자동 생성
        if (subState[index] == 0) {
            autoGenSub(lang,index);
        }
        // 자동 생성 진행하지 못하는 경우들
        else {
            if (subState[index].state == 2) {
                setLoadingSubModalOpen(true);
            }
            else {
                setGenerateSubModalOpen(true);
                setGenerateInfo({lang: lang, index: index});
            }
        }
    }

    function autoGenSub(lang, index) {

        var count = 0;
        var tmpAutoGenSubState = autoGenSubState;

        tmpAutoGenSubState[index] = 3;
        setAutoGenSubState([...tmpAutoGenSubState]);

        toastId.current = toast.info((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><CgSpinner size="22" color="#fff" className="loaderIcon" /></div><div>자막 자동 생성 중입니다.</div></div>),{
            type: toast.TYPE.INFO,
            autoClose: 1000,
            position: "top-center",
            className: 'rotateY animated',
            transition: Flip
        });

        var data = JSON.stringify({
            "tk" : userToken,
            "uuid" : location.pathname.replace("/video/subtitle/",''),
            "ogc" : organizationData.value,
            "lang" : lang
        });

        var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/subtitle/generate', 
            headers: { 
            'Content-Type': 'application/json'
            },
            data : data
        };

        autoGenInterval = setInterval(() => autoGenSubAPI(index), 5000);

        /* 최초 생성 시  
                response.data 에 code 100이 찍혀서 나옴. 

           최초 생성이 아닐 시
                response.data 에 code 100 안나옴.

            이를 임의로 코드에서 구분해서 사용
        */ 
        function autoGenSubAPI(index) {
         
            if (count > 10) {
                tmpAutoGenSubState[index] = 0;
                setAutoGenSubState([...tmpAutoGenSubState]);
                clearInterval(autoGenInterval);
                return;
            }

            axios(config)
            .then( (response) => {
                count++;

                if (response.status == 200){
                    
                    // 최초 생성 시
                    if (Object.keys(response.data).includes('code')) {
                        tmpAutoGenSubState[index] = 3;
                        setAutoGenSubState([...tmpAutoGenSubState]);
                        console.log('has code 100')
                    }
                    else {
                        console.log('has no code 100')
                        if (response.data.state == 2){
                            tmpAutoGenSubState[index] = 3;
                            setAutoGenSubState([...tmpAutoGenSubState]);
                            toast.update(toastId.current ,{
                                render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><CgSpinner size="23" className="loaderIcon"/> 자막 생성 처리 중 </div></div>,
                                type: toast.TYPE.INFO,
                                autoClose: 1000,
                                position: "top-center",
                                className: 'rotateY animated',
                                transition: Flip
                            });
                        }
                        else if (response.data.state == 1) { // 생성 성공
                            tmpAutoGenSubState[index] = 1;
                            setAutoGenSubState([...tmpAutoGenSubState]);
                            clearInterval(autoGenInterval);
    
                            toast.update(toastId.current ,{
                                render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscCheck size="23" /> 자막 자동 생성 성공! </div></div>,
                                type: toast.TYPE.SUCCESS,
                                autoClose: 1000,
                                position: "top-center",
                                className: 'rotateY animated',
                                transition: Flip
                            });
    
                            var tmpArr = [...subState];
                            
                            tmpArr[index] = {
                                uuid: location.pathname.replace("/video/subtitle/",''),
                                lang: lang,
                                state: 0
                            }
                            console.log('tmpArr',tmpArr)
                            dispatch(setSubState(tmpArr));
                        }
                        else if (response.data.state == 0){
                            tmpAutoGenSubState[index] = 0;
                            setAutoGenSubState([...tmpAutoGenSubState]);
                            clearInterval(autoGenInterval);
                         
                            toast.update(toastId.current ,{
                                render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 자막 생성에 오류가 생겼습니다! </div></div>,
                                type: toast.TYPE.ERROR,
                                autoClose: 1000,
                                position: "top-center",
                                className: 'rotateY animated',
                                transition: Flip
                            });
                        }
                    }
                }
                else if (response.status == 201) {
                    clearInterval(autoGenInterval);

                    toast.update(toastId.current ,{
                        render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><TiWarningOutline size="22" color="#fff" /></div><div> 자막 자동 생성을 지원하는 언어가 아닙니다. </div></div>,
                        type: toast.TYPE.WARNING,
                        style: { width: 370 },
                        autoClose: 1000,
                        position: "top-center",
                        className: 'rotateY animated',
                        transition: Flip
                    });
                }
                else {
                    clearInterval(autoGenInterval);

                    toast.update(toastId.current ,{
                        render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 자막 생성에 오류가 생겼습니다! </div></div>,
                        type: toast.TYPE.ERROR,
                        autoClose: 1000,
                        position: "top-center",
                        className: 'rotateY animated',
                        transition: Flip
                    });
                }
            })
            .catch((error) => {
                clearInterval(autoGenInterval);

                toast.error((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 자막 생성에 오류가 생겼습니다! </div></div>),{
                    type: toast.TYPE.ERROR,
                    autoClose: 1000,
                    position: "top-center",
                    className: 'rotateY animated',
                    transition: Flip
                });
            });
        }
        
    }

    function handleTranslate(lang, index) {

        // 자동 번역 불가능 - 한글 자막 없음
        if (subState[0] == 0) {
            setTranslateErrorModalOpen(true);
        }
        else {
            // 자동 변역 진행
            if (subState[index] == 0) {
                translateSub(lang,index);
            }
            // 자동 번역 불가능 - 이미 존재할 때
            else {
                setTranslateSubModalOpen(true);
                setTranslateInfo({lang: lang, index: index});
            }
        }
    }

    function translateSub(lang, index) {

            var tmpTranslateSubState = translateSubState;

            tmpTranslateSubState[index] = 3;
            setTranslateSubState([...tmpTranslateSubState]);
    
                var data = JSON.stringify({
                    "tk" : userToken,
                    "uuid" : location.pathname.replace("/video/subtitle/",''),
                    "ogc" : organizationData.value,
                    "fromLang" : "ko",
                    "toLang" : lang
                });
            
                var config = {
                  method: 'post',
                  url: API_URL + '/' + API_VERSION + '/subtitle/translate', 
                  headers: { 
                    'Content-Type': 'application/json'
                  },
                  data : data
                };
                
                axios(config)
                .then( (response) => {
                    console.log(response.data)
                    if(response.data.statusCode==100){
                        tmpTranslateSubState[index] = 1;
                        setTranslateSubState([...tmpTranslateSubState]);
    
                        var tmpArr = [...subState];
    
                        if (tmpArr[index] == 0) {
                            tmpArr[index] = {
                                uuid: location.pathname.replace("/video/subtitle/",''),
                                lang: lang,
                                state: 0
                            }
                        }
                        else {
                            tmpArr[index] = {
                                uuid: location.pathname.replace("/video/subtitle/",''),
                                lang: lang,
                                state: tmpArr[index].state
                            }
                        }
                        console.log(tmpArr)
                        dispatch(setSubState([...tmpArr]));
                    }   
                    else if(response.data.statusCode==101){
                        tmpTranslateSubState[index] = 0;
                        setTranslateSubState([...tmpTranslateSubState]);
    
                        toast.update(toastId.current ,{
                            render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><TiWarningOutline size="22" color="#fff" /></div><div>번역이 이미 완료된 자막입니다.</div></div>,
                            type: toast.TYPE.WARNING,
                            style: { width: 370 },
                            autoClose: 1000,
                            position: "top-center",
                            className: 'rotateY animated',
                            transition: Flip
                        });
                    }
                })
                .catch((error) => {
                    tmpTranslateSubState[index] = 2;
                    setTranslateSubState([...tmpTranslateSubState]);
                    toast.error((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 자막 생성에 오류가 생겼습니다! </div></div>),{
                        type: toast.TYPE.ERROR,
                        autoClose: 1000,
                        position: "top-center",
                        className: 'rotateY animated',
                        transition: Flip
                    });
    
                });
        }

    return (
        <>
            { 
                isFetched && !isLoading ? 
                    <Table className="subtitleListTable">
                        <thead>
                            <tr>
                                <th scope="col">언어</th>
                                <th scope="col">사용</th>
                                <th scope="col">편집 / 생성</th>
                                <th scope="col">자동 생성</th>
                                <th scope="col">자동 번역</th>
                                <th scope="col">삭제</th>
                            </tr>
                        </thead>
                            <tbody>      
                                {
                                    subState && subState.length > 0 ?
                                        koLabelArr.map((el, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{el.koLabel}</td>
                                                    <td> 
                                                        <ToggleSwitch type='studio' loc='subtitle' uuid={location.pathname.replace("/video/subtitle/",'')} lang={el.lang} koLabel={el.koLabel} index={index}/>
                                                    </td>
                                                    <td className='editModifyBtns'> 
                                                        <EditColumn type='studio' setIsLoading={setIsLoading} isGenerating={autoGenSubState[index]} isTranslating={translateSubState[index]} setAutoGenSubState={setAutoGenSubState} setTranslateSubState={setTranslateSubState} index={index} el={el} uuid={location.pathname.replace("/video/subtitle/",'')} />
                                                    </td>
                                                    <td> 
                                                        {el.lang == 'ko' || el.lang == 'ja' || el.lang == 'en' ?
                                                            subState[index].state == 2 ? 
                                                                <Button className="autoGenBtn loading" variant="primary" disabled={true} >
                                                                    생성 중
                                                                </Button>
                                                                :
                                                                autoGenSubState[index] == 2?
                                                                    <Button variant="primary" style={{ backgroundColor:'gray', fontWeight: 500 }}>
                                                                        생성 오류
                                                                    </Button>
                                                                    :
                                                                    autoGenSubState[index] == 1?
                                                                        <Button className="autoGenBtn done" variant="primary" disabled={true}>
                                                                            완료
                                                                        </Button>
                                                                        :
                                                                        autoGenSubState[index] == 3 ?
                                                                            <Button className="autoGenBtn" variant="primary" disabled={true}>
                                                                                <Wave style={{ display: 'flex', justifyContent: 'center' }} size={15} color='#fefeff' />
                                                                            </Button>
                                                                            :
                                                                            translateSubState[index] == 3?
                                                                                <Button className="autoGenBtn" variant="primary" disabled={true}>
                                                                                    자동 생성
                                                                                </Button>
                                                                                :
                                                                                <Button className="autoGenBtn" variant="primary" onClick={() => handleGenerate(el.lang, index)}>
                                                                                    자동 생성
                                                                                </Button>
                                                            : null
                                                        }
                                                    </td>
                                                    <td> 
                                                        {el.lang != 'ko' ?
                                                            subState[index].state == 2 ? 
                                                                <Button className="autoTranslateBtn"  variant="warning" style={{ width: 85 }} disabled={true}>
                                                                    번역 중
                                                                </Button>
                                                                :
                                                                translateSubState[index] == 2?
                                                                    <Button className="autoTranslateBtn"  variant="warning" style={{ width: 85, backgroundColor:'gray', border: 0, fontWeight: 500 }}>
                                                                            오류
                                                                    </Button>
                                                                    :
                                                                    translateSubState[index] == 1?
                                                                        <Button className="autoTranslateBtn"  variant="warning" style={{ width: 85, fontWeight: 500,  }}>
                                                                                완료
                                                                        </Button>
                                                                        :
                                                                        translateSubState[index] == 3 ?
                                                                            <Button className="autoTranslateBtn"  variant="warning" style={{ width: 85 }} disabled={true}>
                                                                                <Wave style={{ display: 'flex', justifyContent: 'center' }} size={15} color='#fefeff' />
                                                                            </Button>
                                                                            :
                                                                            autoGenSubState[index] == 3 ?
                                                                                <Button className="autoTranslateBtn"  variant="warning" style={{ width: 85 }} disabled={true}>
                                                                                    자동 번역
                                                                                </Button>
                                                                                :
                                                                                <Button className="autoTranslateBtn"  variant="warning" style={{ width: 85 }} onClick={() => handleTranslate(el.lang,index)}>
                                                                                    자동 번역
                                                                                </Button>
                                                                :null
                                                            }
                                                    </td>
                                                    <td className='editDeleteBtns'> 
                                                        {
                                                            subState && subState[index] != 0 ?
                                                                <DeleteColumn type='studio' index={index} uuid={location.pathname.replace("/video/subtitle/",'')} el={el}  />
                                                            :   null
                                                        }

                                                    </td>
                                                </tr>
                                                
                                            )
                                        })  
                                    :
                                    null
                                }  
                            </tbody>        
                    </Table>
                    :                             
                    <Loading />
            }

            <Modal className="generateSubModal" show={generateSubModalOpen} onHide={() => setGenerateSubModalOpen(false)}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body style={{ textAlign: 'center' }}>
                    <div style={{ padding: '3rem' }}>
                        이미 생성된 자막이 있습니다. <br />
                        자동 생성 시 영상 소리를 자동 인식하여 생성된 자막이 저장됩니다.<br /><br />
                        자동 생성을 하시겠습니까?
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setGenerateSubModalOpen(false)}>
                        취소
                    </Button>
                    <Button variant="primary" style={{ padding: '0.375rem 0.5rem' }} onClick={() => { setGenerateSubModalOpen(false); autoGenSub(generateInfo.lang, generateInfo.index)  }}>
                        확인
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal className="loadingGenerateSubModal" show={loadingSubModalOpen} onHide={() => setLoadingSubModalOpen(false)}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body style={{ textAlign: 'center' }}>
                    <div style={{ padding: '3rem' }}>
                        자막이 생성 중 입니다. <br />
                        잠시 기다려주세요.<br /><br />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" style={{ padding: '0.375rem 0.5rem' }} onClick={() => { setLoadingSubModalOpen(false); autoGenSub(generateInfo.lang, generateInfo.index)  }}>
                        확인
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal className="translateSubModal" show={translateSubModalOpen} onHide={() => setTranslateSubModalOpen(false)}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body style={{ textAlign: 'center' }}>
                    <div style={{ padding: '3rem' }}>
                        이미 생성된 자막이 있습니다. <br />
                        자동 번역 시 한글 자막 기준으로 번역된 자막이 저장됩니다.<br /><br />
                        자동 번역을 하시겠습니까?
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setTranslateSubModalOpen(false)}>
                        취소
                    </Button>
                    <Button variant="primary" style={{ padding: '0.375rem 0.5rem' }} onClick={() => { setTranslateSubModalOpen(false); translateSub(translateInfo.lang, translateInfo.index)  }}>
                        확인
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal className="translateErrorModal" show={translateErrorModalOpen} onHide={() => setTranslateErrorModalOpen(false)}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body style={{ textAlign: 'center' }}>
                    <div style={{ padding: '3rem' }}>
                        번역할 한글 자막이 없습니다. <br />
                        한글 자막을 생성 후 번역을 진행해주세요.
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" style={{ padding: '0.375rem 0.5rem' }} onClick={() => { setTranslateErrorModalOpen(false); }}>
                        확인
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default VideoDetailSubtitleList;

import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';
import Select from 'react-select'
import axios from 'axios';
import jwt from 'expo-jwt';
import { toast, Slide, Flip } from 'react-toastify';
import { VscCheck } from '@react-icons/all-files/vsc/VscCheck';
import { VscError } from '@react-icons/all-files/vsc/VscError';
import { CgSpinner } from '@react-icons/all-files/cg/CgSpinner';
import { TiWarningOutline } from '@react-icons/all-files/ti/TiWarningOutline';

import { setOrganizationData } from '../modules/organization';
import { setUserData, setUserToken, setUserThumbnailCDN, setUserVSCdn } from '../modules/user';
//import { tryLogin } from '../modules/auth';

import '../../resources/assets/styles/sass/LoginPage.scss'

function Login(props){
    const location = useLocation();
    const history = useHistory();

    const userToken = useSelector(state => state.user.userToken);
    const userData = useSelector(state => state.user.userData);
    const organizationList = useSelector(state => state.organization.organizationList);

    const [user_id, setUserId] = useState('');
    const [user_password, setUserPassword] = useState('');
    const [organizationLogo, setOrganizationLogo] = useState('https://mpvldhluuktv5373941.cdn.ntruss.com/logo/coursemos_logo.png');
    const [userOrganization, setStatUserOrganization] = useState({});
    const [sortOrganizationList, setSortOrganizationList] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState([]);
    const [tryLogin, setTryLogin] = useState(false);
    const [uToken, setUtoken] = useState('');

    const dispatch = useDispatch();  
    const toastId = useRef();

    useEffect(() => {
      if(userToken != ''){

        return () => {
          history.push('/');
        }

      }
      else if ( userToken == '' && location.pathname != '/') {
          history.push('/');
      }
      
    }, [userToken]);
    
    const setOrganization = (e) => {
        var logoUrl = "https://mpvldhluuktv5373941.cdn.ntruss.com/logo/" + e.value + "_logo.png"

        setOrganizationLogo(logoUrl);
        setSelectedOrganization(e);
        //setOrganizationLogoUrl(logoUrl);
        //setStatUserOrganization(e);
        //setLoginForm(true);
        dispatch(setOrganizationData(e));
    };


    var organizationLogoStyle = {
        backgroundImage: `url(${organizationLogo})`
        }  

      const setInputChange = (searchText) => {
        var selectList = new Array();

        if (searchText == '' || searchText == ' ') {
          setSortOrganizationList([])
        }
        else {
          organizationList.map((item, key) => {
              if(item.label.includes(searchText)) {
                selectList.push(item);
              }
          });
          setSortOrganizationList(selectList);
        }

      }
      
      const tryAuthLogin = () =>{
    
        console.log("tryAuthLogin");
        var openToken = selectedOrganization.open_token;
    
        var authAllowed = true;
        var checkedUserID = user_id.trim();
        var checkedUserPassword = user_password.trim();
        
        toastId.current = toast.info( (<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><CgSpinner size="23" className="loaderIcon"/> 로그인 중입니다</div></div>),  {
          position: toast.POSITION.TOP_CENTER,
          transition : Flip,
          autoClose: 3000,
          className: 'rotateY animated',
        });

        if(checkedUserID == "" || checkedUserPassword == ""){
            authAllowed = false;

            toast.update(toastId.current , {
              render: (<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><TiWarningOutline size="22" color="#fff" /></div><div> 모든 정보를 입력해 주시기 바랍니다. </div></div>),
              type: toast.TYPE.WARNING,
              position: "top-center",
              autoClose: 300,
              className: 'rotateY animated',
              transition: Flip
            });    
          }
        
        if(authAllowed){
          setTryLogin(true);
          var payload = {
            userid : checkedUserID,
            password : checkedUserPassword,
            timestamp : new Date()
          }

          var token = jwt.encode(payload, openToken);
          var data = JSON.stringify({ 
                                      "token" : token,
                                      "organization_code" : selectedOrganization.value
                                    });

          var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/auth/login',    
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };

          
          if (!tryLogin) {
            

            axios(config)
            .then( (response) => {
                if(response.data.code == 100){
                  
                  var uToken = response.data.token;
                  var extraToken = response.data.token.slice(-7);
                  var userToken = response.data.token.replace(extraToken, '');
          
                  var decodedUserData = jwt.decode(userToken, openToken + extraToken);
                  var userData = {}; // 디코딩 된 사용자 정보
                  for (const [key, value] of Object.entries(decodedUserData)) {
                    userData[key] = decodeURI(value);
                  }

                  setUtoken(uToken);
                  dispatch(setUserData({...userData}));
                 
                  dispatch(setUserToken(uToken));
                  dispatch(setUserThumbnailCDN(userData.media_cdn))
                  dispatch(setUserVSCdn(userData.media_vs_cdn))

                  setTryLogin(false);
                }
                else{
                  toast.update(toastId.current , {
                      render: (<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><TiWarningOutline size="22" color="#fff" /></div><div> 잘못된 사용자 정보입니다. </div></div>),
                      type: toast.TYPE.WARNING,
                      autoClose: 3000,
                      position: "top-center",
                      className: 'rotateY animated',
                      transition: Flip
                  });
                  setTryLogin(false);
                }
            })
            .catch(function (error) {
              console.log(error);
              toast.update(toastId.current , {
                  render: (<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 알 수 없는 에러가 발생했습니다. </div></div>),
                  type: toast.TYPE.ERROR,
                  position: "top-center",
                  autoClose: 3000,
                  className: 'rotateY animated',
                  transition: Flip
              });          
              setTryLogin(false);
            });
          }
        }
      } //end Of tryAuthLogin

      const handleUserIDChange = (e) =>{
        setUserId(e.target.value);
      };
      
      const handlePasswordChange = (e) =>{
          setUserPassword(e.target.value);
      };
    
      const handleEnterPress = (e) =>{
        if (e.key === "Enter") {
          tryAuthLogin();
        }
      }
    
      // var organizationLogoStyle = {
      //   backgroundImage: `url(${organizationLogoUrl})`
      // }  
      // function powerOff(){
      //     //ipcRenderer.send('powerOff')
      //     //ipcRenderer.send('relaunchBrowser')
      // }
    

    return (
        <>
            <Container className="pageMain">
                <div className="row">
                    <div className="col-4"></div>
                    <div className="col-4">
                        <div id="organizationLogo" style={organizationLogoStyle}></div>
                    </div>
                    <div className="col-4"></div>
                </div>
                <div className="row">
                    <div className="col-4"></div>
                    <div className="col-4" style={{'textAlign': 'center', 'fontSize': '30px', 'marginTop' : '20px'}}>
                        CSMS Studio
                    </div>
                    <div className="col-4"></div>
                </div>
                <div id="mainOrganizationBox" className="row">
                    <div className="col-4"></div>
                    <div className="col-4">
                        <Select 
                            className="selectOrganization"
                            classNamePrefix="select"
                            onChange={setOrganization}
                            isSearchable={ true }
                            placeholder="기관명을 입력해주세요."
                            onInputChange={ setInputChange }
                            options={sortOrganizationList}
                            noOptionsMessage={() => '일치하는 기관명이 없습니다.'} 
                        />
                    </div>
                    <div className="col-4"></div>
                </div>
                {selectedOrganization.length == 0 ? null     
                    :
                    <div className="row">
                        <div className="col-4"></div>
                        <div className="col-4">
                            <form id="form-login" className="fade show">
                                <div className="form-group">
                                    <input placeholder="아이디" type="text" id="user_id" className="form-control form-control-lg" onChange={handleUserIDChange}></input>
                                </div>
                                <div className="form-group">
                                    <input placeholder="비밀번호" type="password" id="user_password" className="form-control form-control-lg" onChange={handlePasswordChange}></input>
                                </div>
                                <div className="btn-login" onClick={tryAuthLogin}>로그인</div>
                            </form>
                        </div>
                        <div className="col-4"></div>
                    </div>
                    }
            </Container>
        </>
    );
}

export default Login; 

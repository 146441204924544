import React, { useEffect, useState }  from 'react';

function TotalStorage({groupedData}) {

    const [storageSum, setStorageSum] = useState(0);

    useEffect(() => {
        if (groupedData.length > 0) {
            const totalValue = groupedData.reduce((accumulator, currentValue) => {
                if (currentValue.data?.convertedSize !== undefined) {
                    return accumulator + currentValue.data.convertedSize;
                  } else {
                    return accumulator;
                  }
              }, 0);
    
              setStorageSum(totalValue);
        }

    },[groupedData])

    return (
        <div className="col-6" style={{ marginBottom: 30 }}>
            <div className="dashboardBox" style={{ height: 300 }}>
                <div className="dashboardBoxTitle" style={{ fontWeight: 500 }}>
                    총 스토리지 이용량
                </div>
                <div className="dashboardBoxContent" style={{ paddingTop: 50 }}>
                    <div style={{ fontSize: 50, fontWeight: 500, textAlign: 'center' }}> 
                        <i>{ storageSum } GB</i>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TotalStorage;
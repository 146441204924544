import React, { useState, useEffect, useRef }  from 'react';

import videojs from "video.js";

import { useSelector, useDispatch } from 'react-redux';
import { setThumbnails, setVideoDurationTime } from '../../modules/media';
import { setStatusVideoCurrentTime, setStatusVideoPlayState } from '../../modules/status'
import WatermarkCanvasContainer from './watermark/WatermarkCanvasContainer';

import CropLayer from './timeline/CropLayer';

function Player({ type, uuid, VSCdn, setModalPlayState }) {

    const THUMBNAIL_COUNT = 30;
    const videoRef = useRef(null); 
    const playerRef = useRef(null);
    const canvasRef = useRef(null);
    
    const dispatch = useDispatch();
    
    const popupUuid = useSelector(state => state.media.popupUuid);
    const PopupUserVSCdn = useSelector(state => state.user.PopupUserVSCdn);

    const [player, setPlayer] = useState(null);
    const [currentTime, setCurrentTime] = useState(0);
    const [durationTime, setDurationTime] = useState(0);
    const [thumbCanvas, setThumbCanvas] = useState([]);
    const [thumbnails, setThumbnails] = useState([]);

    var videoJsOptions = {
        autoplay: false,
        playbackRates: [0.5, 1, 1.25, 1.5, 2],
        controls: true,
        height: '100%',
        width: '100%',
        sources: [
          {
            src: VSCdn + '/hls/' + uuid + '/mp4/' + uuid + '.mp4/index.m3u8',
            type: 'application/x-mpegURL',
          },
        ],
        plugins: {
          hlsQualitySelector: {
            displayCurrentQuality: true
          }
        }
      }
  
      useEffect(() => {
        // make sure Video.js player is only initialized once
        if (!playerRef.current) {
          
            const videoElement = videoRef.current;
            if (!videoElement) return;

            setPlayer( 
                playerRef.current = videojs(videoElement, videoJsOptions, () => {
                    console.log("player is ready");
  
                })
            )
        }
        
      }, []);
    
      // useEffect(() => {
      //   const canvas = canvasRef.current;
      //   if (canvas) {
      //       const ctx = canvas.getContext('2d');

      //       // 비디오의 크기에 맞게 Canvas 크기 설정
      //       canvas.width = videoRef.current.videoWidth;
      //       canvas.height = videoRef.current.videoHeight;

      //       // requestVideoFrameCallback을 사용하여 비디오의 현재 프레임을 얻어오기
      //       videoRef.current.requestVideoFrameCallback((currentFrame, metadata) => {
      //         // Canvas에 현재 프레임을 그리기
      //         ctx && ctx.drawImage(currentFrame, 0, 0);

      //         // Canvas에서 그린 이미지의 데이터 URL 생성
      //         const imageUrl = canvas.toDataURL('image/png');

      //         // 생성된 이미지 URL 사용
      //         console.log('Image URL:', imageUrl);
      //       }, { frame: 'current' });

      //   }
      // }, [canvasRef.current]); // 또는 다른 의존성 추가

      // Dispose the Video.js player when the functional component unmounts
      useEffect(() => {
  
          
          if(player != null){
            
              player.on("loadedmetadata", () => {
                setDurationTime(player.duration());
                dispatch(setVideoDurationTime(player.duration()))
                
              });
              player.on("timeupdate", () => {
                //makeInitialThumbnails();
                setCurrentTime(player.currentTime());
                dispatch(setStatusVideoCurrentTime(player.currentTime()));
              });
              player.on("play", () => {
                console.log("IS Playinggg");
                setModalPlayState(true);
                dispatch(setStatusVideoPlayState(true));
              });
              player.on("pause", () => {
                console.log("IS STOPPED");
                setModalPlayState(false);
                dispatch(setStatusVideoPlayState(false));
              });
  
          } 
        }, [player]);    
              

    // const makeInitialThumbnails = async () => {
    //   const delta = 1 / THUMBNAIL_COUNT;
    //   var thumbnails = await makeThumbnails(0, player.duration() - delta);
    //   console.log('thumbnails',thumbnails)
    //   dispatch(setThumbnails(thumbnails));
    // };
  
    // const makeThumbnails = (start: number, end: number) => {
    //   return new Promise<string[]>((resolve, reject) => {
    //     try {
    //       (async () => {
    //         const gap = (end - start) / (THUMBNAIL_COUNT - 1);
    //         let secs = end;
           
    //         const images: string[] = new Array(THUMBNAIL_COUNT);
  
    //         for (let count = THUMBNAIL_COUNT - 1; count > 0; count -= 1) {
    //          // videoRef.current.currentTime(secs);
    //           const image: string = await getImageAt();
    //           secs -= gap;
    //           images[count] = image;
    //         }
    //         playerRef.current.currentTime(start);
    //         images[0] = await getImageAt();
    //         resolve(images);
            
    //       })();
    //     } catch (err) {
    //       reject(err);
    //     }
    //   });
    // };

    // const getImageAt = () => {

    //   return new Promise<string>(resolve => {
    //         canvasRef.current = document.createElement("canvas");

    //         if (canvasRef.current.getContext) {
    //           const context = canvasRef.current.getContext('2d');
    //           context.drawImage(
    //             videoRef.current,
    //             0,
    //             0,
    //             videoRef.current.width,
    //             videoRef.current.height
    //           )  
    //         }
    //         resolve(canvasRef.current.toDataURL());
    //         }
    //     )
    // }

    return (
        <div className={ type == "popup" ? "videoContatiner popupEditor" : "videoContatiner" } >
            <div id="videoEl" data-vjs-player>
                <video ref={videoRef} id="videoEl" className="video-js vjs-big-play-centered" style={{ height: '100%' }} >
                </video>
            </div>
            {/* <div className="thumbnails">
                {thumbnails.map((thumbnail, index) => (
                    <img style={{ 'width': videoRef.current.clientWidth, 'height': videoRef.current.clientHeight }}key={index} src={thumbnail} alt={`Thumbnail ${index}`} />
                ))}
            </div>
            <WatermarkCanvasContainer /> */}
            {/* <CropLayer /> */}
        </div>
    );
}

export default Player;
import React,{ useEffect, useState, useRef } from 'react';

import axios from 'axios';
import Select from 'react-select'
import { Row } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import { BiImageAdd } from '@react-icons/all-files/bi/BiImageAdd';

import DeleteBtn from './DeleteBtn';
import { setProjectData, setWatermarkData } from '../../../modules/editor';

function WatermarkRow({ idx, el, watermarkList, setWatermarkList, uuid, tk, ogc }) {

    const projectData = useSelector(state => state.editor.projectData);
    const projectInfo = useSelector(state => state.editor.projectInfo);
    const editProjIdx = useSelector(state => state.editor.editProjIdx);

    const [changedWatermarkObj, setchangedWatermarkObj] = useState({});  

    const options = [
        { value: 'image', label: '이미지' },
        { value: 'text', label: '텍스트' }
      ];
      
    const selectInputRef = useRef(null);

    const selectStyle = {
        control: (base, state) => ({
            ...base,
            border: state.isFocused ? 0 : 0,
            // This line disable the blue border
            boxShadow: state.isFocused ? 0 : 0,
            "&:hover": {
            border: state.isFocused ? 0 : 0
            },
        })
    };

    const dispatch = useDispatch();  

    function handleChange( valueType, idx, text ) {

        var tmpObj = watermarkList[idx];

        switch (valueType) {
            case 'x':
                setchangedWatermarkObj({
                    ...tmpObj,
                    'x' : text
                });
                break;
            case 'y':
                setchangedWatermarkObj({
                    ...tmpObj,
                    'y' : text
                });
                break;
            case 'width':
                setchangedWatermarkObj({
                    ...tmpObj,
                    'width' : text
                });
                break;
            case 'height':
                setchangedWatermarkObj({
                    ...tmpObj,
                    'height' : text
                });
                break;
            case 'text':
                setchangedWatermarkObj({
                    ...tmpObj,
                    'text' : text
                });
                break;
            default:
                console.log('error');
        }
    }

    function fetchWatermark() {

        var tmpCardList = [...watermarkList];

        if ( Object.keys(changedWatermarkObj).length > 0 ) {
            tmpCardList[idx] = changedWatermarkObj;
            setWatermarkList(tmpCardList);
            dispatch(setWatermarkData(tmpCardList));
        }
        
        var tmpData = [...projectData];
        if (projectData.length > 0) {
            tmpData = projectData.filter((el) => el.action != "watermark");
        }
        
        var tmpDataCopy = [...tmpData];
        tmpCardList.map((el,idx) => {
            tmpDataCopy.push({
                "action" : "watermark",
                "data" : el
            })
        })

       dispatch(setProjectData(tmpDataCopy));

        var data = JSON.stringify({
            "tk" : tk,
            "uuid" : uuid,
            "ogc" : ogc,
            "project_id": editProjIdx,
            "projectInfo" : projectInfo,
            "projectData" : tmpDataCopy
        });

        var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/editor/update',
            headers: { 
                'Content-Type': 'application/json'
                },
            data : data            
        };
            
        axios(config)
        .then( (response) => {
            console.log(response.data.data);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    function setType(e, idx) {
        var tmpList = watermarkList[idx];
        tmpList.type = e.value
        setchangedWatermarkObj(tmpList);
    }

    return (
        <Row key={`watermarkList_${idx}`} className='menuBoxRow' onBlur={(e) => fetchWatermark()} >
            <div className='col-2'>워터마크 { idx + 1 }</div>
            <div className='col-2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 13, padding: 0 }}> 
                타입
                <Select 
                    className="selectWatermarktype"
                    classNamePrefix="select"
                    ref={selectInputRef}
                    onChange={(e) => setType(e,idx)}
                    isSearchable={ false }
                    placeholder=''
                    options={options}
                    styles={selectStyle}
                    defaultValue={ el.type == "text" ? options[1] : options[0] }
                />
            </div>
            <div className='col-2'> 
                {
                    el.type == "text" ?
                        <div  
                            className='watermark editable-text'
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                            onInput={(e) => handleChange('text', idx, e.currentTarget.textContent)}
                        >
                            {el.text} 
                        </div>
                    :
                        el.type == "image" ?
                            el.image != ''? 
                                <img src={el.image} style={{ height: 70 }}></img>
                                :
                                <label key={`fileLabel_${idx}`} htmlFor={`chapterFile_${idx}`} style={{ position: 'absolute', top: 'calc(50% - 22.5px)', left: 'calc(50% - 15px)' }}>
                                    <div className="btn" style={{ color: 'lightgray' }}>
                                    <BiImageAdd size="37" color="gray" />
                                    </div>
                                </label>
                        : null
                            
                }
            </div>
            <div className='col-5' style={{ padding: 0 }} >
                <Row>
                    <div className='col-6' style={{ fontSize: 13, padding: 0 }}>
                        <Row  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div className='col-4' style={{ padding: 0 }}>
                                위치
                            </div>
                            <div className='col-8'>
                                <Row >
                                    <div style={{ marginBottom: 10 }}>
                                        X
                                    </div>
                                    <div  
                                        className='watermark editable-text'
                                        contentEditable={true}
                                        suppressContentEditableWarning={true}
                                        onInput={(e) => handleChange('x', idx, e.currentTarget.textContent)}
                                    >
                                        {el.x} 
                                    </div>
                                </Row>
                                <Row >
                                    <div>
                                        Y
                                    </div>
                                    <div  
                                        className='watermark editable-text'
                                        contentEditable={true}
                                        suppressContentEditableWarning={true}
                                        onInput={(e) => handleChange('y', idx, e.currentTarget.textContent)}
                                    >
                                        {el.y}
                                    </div>
                                </Row>
                            </div>
                        </Row>
                    </div>
                    
                    <div className='col-6' style={{ fontSize: 13, padding: 0 }}>
                        <Row  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div className='col-4' style={{ padding: 0}}>
                                크기
                            </div>
                            <div className='col-8'>
                                <Row >
                                    <div style={{ marginBottom: 10 }}>
                                        가로
                                    </div>
                                    <div  
                                        className='watermark editable-text'
                                        contentEditable={true}
                                        suppressContentEditableWarning={true}
                                        onInput={(e) => handleChange('width', idx, e.currentTarget.textContent)}
                                        style={{ width: '60%' }}
                                    >
                                        {el.width}
                                    </div>
                                </Row>
                                <Row>
                                    <div>
                                        세로
                                    </div>
                                    <div  
                                        className='watermark editable-text'
                                        contentEditable={true}
                                        suppressContentEditableWarning={true}
                                        onInput={(e) => handleChange('height', idx, e.currentTarget.textContent)}
                                        style={{ width: '60%' }}
                                    >
                                        {el.height}
                                    </div>
                                </Row>
                            </div>
                        </Row>
                    </div>
                </Row>
            </div>
            <DeleteBtn idx={idx} watermarkList={watermarkList} setWatermarkList={setWatermarkList} />

        </Row>
    );
}

export default WatermarkRow;
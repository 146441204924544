import React, { useState, useEffect } from 'react';

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { setCurrentSub } from '../../modules/aiQuiz';

dayjs.extend(isBetween);

function SubList({ idx, item, playTime, setScrollHeight}) {
    
    const dispatch = useDispatch();  

    const [isSelected, setIsSelected] = useState(false);

    useEffect(() => {
        if (playTime) {
            const isBetween = dayjs(playTime).isBetween(dayjs(item.start), dayjs(item.end), undefined, "[]");
 
            if (isBetween) {
                setIsSelected(true);
                setScrollHeight(idx*80-150);
            }
            else {
                setIsSelected(false);
            }
        }
    },[playTime])

    const handleSubListClick = (item) => {
        // setIsSelected(true);
        dispatch(setCurrentSub(item));
    };
        
    return (
        <Row key={item.id} className={`subListRow ${isSelected ? 'active' : 'inactive'}`} onClick={(e) => handleSubListClick(item)}>
            <div className="col-1 listItemCol1">{item.id >= 10 ? item.id : '0' + item.id}</div>
            <div className="col-3 listItemCol3">
                <div id='start-time'>
                    { dayjs(item.start).format('HH:mm:ss.SSS') }
                </div>
                <div id='end-time'>
                    { dayjs(item.end).format('HH:mm:ss.SSS') }
                </div>
            </div>
            <div className='col-8 listItemCol8'>
                <div id='contentBox'>
                    {item.content} 
                </div>
            </div>
        </Row>
    );
}

export default SubList;
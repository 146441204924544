import React, { useEffect, useState } from 'react';

import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

function UsagePerTime(props) {

  function generateData(count, yrange) {
    var i = 0;
    var series = [];
    while (i < count) {
      var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
      console.log('chatt', y)
      series.push(y);
      i++;
    }

    return series;
  }
  
  var series = 
      [
          {
            name: '12오전',
            data: generateData(18, {
              min: 0,
              max: 500
            })
          },
          {
            name: '2오전',
            data: generateData(18, {
              min: 0,
              max: 500
            })
          },
          {
            name: '4오전',
            data: generateData(18, {
              min: 0,
              max: 500
            })
          },
          {
            name: '6오전',
            data: generateData(18, {
              min: 0,
              max: 500
            })
          },
          {
            name: '8오전',
            data: generateData(18, {
              min: 0,
              max: 500
            })
          },
          {
            name: '10오전',
            data: generateData(18, {
              min: 0,
              max: 500
            })
          },
          {
            name: '12오후',
            data: generateData(18, {
              min: 0,
              max: 500
            })
          },
          {
            name: '2오후',
            data: generateData(18, {
              min: 0,
              max: 500
            })
          },
          {
            name: '4오후',
            data: generateData(18, {
              min: 0,
              max: 500
            })
          },
          {
            name: '6오후',
            data: generateData(18, {
              min: 0,
              max: 500
            })
          },
          {
            name: '8오후',
            data: generateData(18, {
              min: 0,
              max: 500
            })
          },
          {
            name: '10오후',
            data: generateData(18, {
              min: 0,
              max: 500
            })
          }
      ];


    const options: ApexOptions = {
      dataLabels: {
        enabled: false
      },
      plotOptions: {
        heatmap: {
          shadeIntensity: 0.5,
          radius: 0,
          useFillColorAsStroke: true,
          colorScale: {
            ranges: [{
                from: -30,
                to: 5,
                name: 'low',
                color: '#00A100'
              },
              {
                from: 6,
                to: 20,
                name: 'medium',
                color: '#128FD9'
              },
              {
                from: 21,
                to: 45,
                name: 'high',
                color: '#FFB200'
              },
              {
                from: 46,
                to: 55,
                name: 'extreme',
                color: '#FF0000'
              }
            ]
          }
        }
      },
      colors: ["#008FFB"]
      };
    
    return (
        <div className="col-4" style={{ marginBottom: 30 }}>
            <div className="dashboardBox">
                <div className="dashboardBoxTitle">
                    시간에 따른 이용량 파악
                </div>
                <ReactApexChart options={options} series={series} width="95%" height={200} type="heatmap"/>
            </div>
        </div>
    );
}

export default UsagePerTime;
import React, { useState, useEffect, useRef } from 'react';

import { Table, Button, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { ChasingDots, Wave } from 'better-react-spinkit';
import { toast, Slide, Flip } from 'react-toastify';
import { VscCheck } from '@react-icons/all-files/vsc/VscCheck';
import { VscError } from '@react-icons/all-files/vsc/VscError';
import { TiWarningOutline } from '@react-icons/all-files/ti/TiWarningOutline';
import { CgSpinner } from '@react-icons/all-files/cg/CgSpinner';
import Switch from "react-switch";

import {
    setPopupSubState
} from '../../modules/subtitle';

import ToggleSwitch from "../../components/videoEdit/_commons/ToggleSwitch";
import DeleteColumn from '../videoEdit/subtitle/DeleteColumn';
import EditColumn from '../videoEdit/subtitle/EditColumn';
import Loading from '../_commons/Loading';

function SubtitlePopup({uuid}) {
  
    const dispatch = useDispatch();

    const [autoGenSubState, setAutoGenSubState] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [translateSubState, setTranslateSubState] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [translateSubModalOpen, setTranslateSubModalOpen] = useState(false);
    const [translateInfo, setTranslateInfo] = useState({  lang: '', index : -1 });
    const [generateSubModalOpen, setGenerateSubModalOpen] = useState(false);
    const [loadingSubModalOpen, setLoadingSubModalOpen] = useState(false);
    const [translateErrorModalOpen, setTranslateErrorModalOpen] = useState(false);
    const [generateInfo, setGenerateInfo] = useState({  lang: '', index : -1 });
    const [isUsed, setIsUsed] = useState(false);
    const [isFetched, setIsFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    const PopupUserToken = useSelector(state => state.user.PopupUserToken);
    const popupOrganizationData = useSelector(state => state.organization.popupOrganizationData);
    const popupvodInfo = useSelector(state => state.media.popupvodInfo);
    const popupSubState = useSelector(state => state.subtitle.popupSubState);


    var autoGenInterval;

    const koLabelArr = [
                            { "lang" : "ko", "koLabel" : "한글" },
                            { "lang" : "en", "koLabel" : "영어" },
                            { "lang" : "ja", "koLabel" : "일본어" },
                            { "lang" : "zh-CN", "koLabel" : "중국어 - 간체" },
                            { "lang" : "zh-TW", "koLabel" : "중국어 - 번체" },  
                            { "lang" : "vi", "koLabel" : "베트남어" },
                            { "lang" : "id", "koLabel" : "인도네시아어" },
                            { "lang" : "th", "koLabel" : "태국어" }, 
                            { "lang" : "de", "koLabel" : "독일어" },
                            { "lang" : "ru", "koLabel" : "러시아어" },
                            { "lang" : "es", "koLabel" : "스페인어" },
                            { "lang" : "it", "koLabel" : "이탈리아어" },
                            { "lang" : "fr", "koLabel" : "프랑스어" },
                        ]

    const toastId = useRef();

    useEffect(() => {
        console.log('ef')
        //redux 초기화
        return () => {
            dispatch(setPopupSubState([]));
        };
    },[]);

    useEffect(() => {
        if (PopupUserToken && popupOrganizationData) { 
            var data = JSON.stringify({
                "tk" : PopupUserToken,
                "uuid" : uuid,
                "ogc" : popupOrganizationData.organization_code,
                "type" : "ALL"
            });

            var config = {
                method: 'post',
                url: API_URL + '/' + API_VERSION + '/subtitle/getState',
                headers: { 
                    'Content-Type': 'application/json'
                },
                data : data
            };
            
            axios(config)
            .then( (response) => {
                if(response.data.code==100){
                    var tmpSubState = new Array(13).fill(0);
                    if (response.data.data.subtitle.length > 0) {
                        response.data.data.subtitle.map((el, index) => {
                            /*
                                언어 순서 조정 및 한국어 라벨 저장하기
                            */
                        var lang = el.lang;

                        //하이픈 비교 오류 방지
                        if (el.lang && el.lang.includes('-')) {
                                lang = el.lang.replace('-','')
                        }

                        switch(lang) 
                        {
                            case "ko":
                                tmpSubState[0] = el;
                            break;
                            case "en":
                                tmpSubState[1] = el;
                            break;                        
                            case "ja":
                                tmpSubState[2] = el;
                            break;
                            case "zhCN":
                                tmpSubState[3] = el;
                            break;    
                            case "zhTW":
                                tmpSubState[4] = el;
                            break;  
                            case "vi":
                                tmpSubState[5] = el;
                            break;      
                            case "id":
                                tmpSubState[6] = el;
                            break;    
                            case "th":
                                tmpSubState[7] = el;
                            break;     
                            case "de":
                                tmpSubState[8] = el;
                            break;    
                            case "ru":
                                tmpSubState[9] = el;
                            break;   
                            case "es":
                                tmpSubState[10] = el;
                            break;    
                            case "it":
                                tmpSubState[11] = el;
                            break;   
                            case "fr":
                                tmpSubState[12] = el;
                            break;      
                        }
                            
                        })
                    }
                 
                    dispatch(setPopupSubState([...tmpSubState]))
                    setIsFetched(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }, [PopupUserToken , popupOrganizationData]);



    useEffect(() => {
        if (popupvodInfo) {
            if (popupvodInfo.support_subtitle == 1) {
                setIsUsed(true);
            }
            else {
                setIsUsed(false);
            }
        }
    },[popupvodInfo])

    function handleGenerate(lang, index) {
        if (popupSubState[index] == 0) {
            autoGenSub(lang,index);
        }
        else {
            if (popupSubState[index].state == 2) {
                setLoadingSubModalOpen(true);
            }
            else {
                setGenerateSubModalOpen(true);
                setGenerateInfo({lang: lang, index: index});
            }
        }
    }

    function autoGenSub(lang, index) {

        var tmpAutoGenSubState = autoGenSubState;

        tmpAutoGenSubState[index] = 3;
        setAutoGenSubState([...tmpAutoGenSubState]);

        toastId.current = toast.info((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><CgSpinner size="22" color="#fff" className="loaderIcon" /></div><div>자막 자동 생성 중입니다.</div></div>),{
            type: toast.TYPE.INFO,
            autoClose: 1000,
            position: "top-center",
            className: 'rotateY animated',
            transition: Flip
        });

        var data = JSON.stringify({
            "tk" : PopupUserToken,
            "uuid" : uuid,
            "ogc" : popupOrganizationData.organization_code,
            "lang" : lang
        });

        var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/subtitle/generate', 
            headers: { 
            'Content-Type': 'application/json'
            },
            data : data
        };

        var count = 0;
        autoGenInterval = setInterval(() => autoGenSubAPI(index), 5000);

        function autoGenSubAPI(index) {

            if ( count > 10 ) {
                clearInterval(autoGenInterval);
                return;
            } 

            axios(config)
            .then( (response) => {
                console.log('count',count);

                if (response.status == 200){
                    if (Object.keys(response.data).includes('code')){
                        tmpAutoGenSubState[index] = 3;
                        setAutoGenSubState([...tmpAutoGenSubState]);
                    }
                    else {
                        if (response.data.state == 2){
                            tmpAutoGenSubState[index] = 3;
                            setAutoGenSubState([...tmpAutoGenSubState]);
                            toast.update(toastId.current ,{
                                render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><CgSpinner size="23" className="loaderIcon"/> 자막 생성 처리 중 </div></div>,
                                type: toast.TYPE.INFO,
                                autoClose: 1000,
                                position: "top-center",
                                className: 'rotateY animated',
                                transition: Flip
                            });
                        }
                        else if (response.data.state == 1) { // 생성 성공
                            tmpAutoGenSubState[index] = 1;
                            setAutoGenSubState([...tmpAutoGenSubState]);
                            clearInterval(autoGenInterval);
    
                            toast.update(toastId.current ,{
                                render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscCheck size="23" /> 자막 자동 생성 성공! </div></div>,
                                type: toast.TYPE.SUCCESS,
                                autoClose: 1000,
                                position: "top-center",
                                className: 'rotateY animated',
                                transition: Flip
                            });
    
                            var tmpArr = [...popupSubState];
                            
                            tmpArr[index] = {
                                uuid: location.pathname.replace("/video/subtitle/",''),
                                lang: lang,
                                state: 0
                            }

                            console.log('tmpArr',tmpArr)
                            dispatch(setPopupSubState([...tmpArr]));
                        }
                        else if (response.data.state == 0){
                            tmpAutoGenSubState[index] = 0;
                            setAutoGenSubState([...tmpAutoGenSubState]);
                            clearInterval(autoGenInterval);
                         
                            toast.update(toastId.current ,{
                                render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 자막 생성에 오류가 생겼습니다! </div></div>,
                                type: toast.TYPE.ERROR,
                                autoClose: 1000,
                                position: "top-center",
                                className: 'rotateY animated',
                                transition: Flip
                            });
                        }
                        else {
                            tmpAutoGenSubState[index] = 0;
                            setAutoGenSubState([...tmpAutoGenSubState]);
                            
                            toast.update(toastId.current ,{
                                render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 자막 생성에 오류가 생겼습니다! </div></div>,
                                type: toast.TYPE.ERROR,
                                autoClose: 1000,
                                position: "top-center",
                                className: 'rotateY animated',
                                transition: Flip
                            });
                            clearInterval(autoGenInterval);
                        }
                    }
                }
                else if (response.data.state == 201) {
                    clearInterval(autoGenInterval);

                    toast.update(toastId.current ,{
                        render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><TiWarningOutline size="22" color="#fff" /></div><div> 자막 자동 생성을 지원하는 언어가 아닙니다. </div></div>,
                        type: toast.TYPE.WARNING,
                        style: { width: 370 },
                        autoClose: 1000,
                        position: "top-center",
                        className: 'rotateY animated',
                        transition: Flip
                    });
                }
                else {
                    clearInterval(autoGenInterval);

                    toast.update(toastId.current ,{
                        render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 자막 생성에 오류가 생겼습니다! </div></div>,
                        type: toast.TYPE.ERROR,
                        autoClose: 1000,
                        position: "top-center",
                        className: 'rotateY animated',
                        transition: Flip
                    });
                }
            })
            .catch((error) => {
                clearInterval(autoGenInterval);

                toast.error((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 자막 생성에 오류가 생겼습니다! </div></div>),{
                    type: toast.TYPE.ERROR,
                    autoClose: 1000,
                    position: "top-center",
                    className: 'rotateY animated',
                    transition: Flip
                });
            });
        }
        
    }

    function handleTranslate(lang, index) {

        // 자동 번역 불가능 - 한글 자막 없음
        if (popupSubState[0] == 0) {
            setTranslateErrorModalOpen(true);
        }
        else {
            // 자동 변역 진행
            if (popupSubState[index] == 0) {
                translateSub(lang,index);
            }
            // 자동 번역 불가능 - 이미 존재할 때
            else {
                setTranslateSubModalOpen(true);
                setTranslateInfo({lang: lang, index: index});
            }
        }
    }

    function translateSub(lang, index) {

        var tmpTranslateSubState = translateSubState;

        tmpTranslateSubState[index] = 3;
        setTranslateSubState([...tmpTranslateSubState]);

            var data = JSON.stringify({
                "tk" : PopupUserToken,
                "uuid" : uuid,
                "ogc" : popupOrganizationData.organization_code,
                "fromLang" : "ko",
                "toLang" : lang
            });
        
            var config = {
              method: 'post',
              url: API_URL + '/' + API_VERSION + '/subtitle/translate', 
              headers: { 
                'Content-Type': 'application/json'
              },
              data : data
            };
            
            axios(config)
            .then( (response) => {
                if(response.data.statusCode==100){
                    tmpTranslateSubState[index] = 1;
                    setTranslateSubState([...tmpTranslateSubState]);

                    var tmpArr = [...popupSubState];
    
                    if (tmpArr[index] == 0) {
                        tmpArr[index] = {
                            uuid: location.pathname.replace("/video/subtitle/",''),
                            lang: lang,
                            state: 0
                        }
                    }
                    else {
                        tmpArr[index] = {
                            uuid: location.pathname.replace("/video/subtitle/",''),
                            lang: lang,
                            state: tmpArr[index].state
                        }
                    }

                    dispatch(setPopupSubState([...tmpArr]));
                }
                else if(response.data.statusCode==101){
                    tmpTranslateSubState[index] = 0;
                    setTranslateSubState([...tmpTranslateSubState]);

                    toast.update(toastId.current ,{
                        render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><TiWarningOutline size="22" color="#fff" /></div><div> 번역이 이미 완료된 자막입니다. </div></div>,
                        type: toast.TYPE.WARNING,
                        style: { width: 370 },
                        autoClose: 1000,
                        position: "top-center",
                        className: 'rotateY animated',
                        transition: Flip
                    });
                }
            })
            .catch((error) => {
                tmpTranslateSubState[index] = 2;
                setTranslateSubState([...tmpTranslateSubState]);

                toast.error((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 자막 번역에 오류가 생겼습니다. </div></div>),{
                    type: toast.TYPE.ERROR,
                    autoClose: 1000,
                    position: "top-center",
                    className: 'rotateY animated',
                    transition: Flip
                });
            });
    }

    function handleChange() {
        
        toastId.current = toast.info( (<div style={{ color: '#fff' }}>챕터 사용 여부를 변환 중 입니다.</div>),  {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            transition : Slide
        });

        setIsUsed(!isUsed);
        
        var data = JSON.stringify({
            "userToken" : PopupUserToken,
            "uuid" : uuid,
            "organizationCode" : popupOrganizationData.organization_code,
            "state" : isUsed ? 0 : 1
        });

        var config = {
        method: 'post',
        url: API_URL + '/' + API_VERSION + '/vod/setSubtitleState',
        headers: { 
            'Content-Type': 'application/json'
        },
        data : data
        };
        
        axios(config)
        .then( (response) => {
            toast.dismiss(toastId.current);

            console.log(response)
            if(response.data.code == 100){
                setIsUsed(response.data.currentState == 1 ? true : false);
            }
            else {
                toast.error((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 자막 상태 변경에 오류가 생겼습니다! </div></div>),{
                    type: toast.TYPE.ERROR,
                    autoClose: 1000,
                    position: "top-center",
                    className: 'rotateY animated',
                    transition: Flip
                });
            }
        })
        .catch((error) => {
            toast.error((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 자막 상태 변경에 오류가 생겼습니다! </div></div>),{
                type: toast.TYPE.ERROR,
                autoClose: 1000,
                position: "top-center",
                className: 'rotateY animated',
                transition: Flip
            });
        });
    }

    return (
        <>
            <div>
                <div className="contentHeader" style={{ padding: 20 }}>
                    <div className='containerSubtitleTitle'>
                        <div>
                            <div> 동영상 자막 </div>
                        </div>
                    </div>
                </div>

                <div style={{ width: '90%', margin: '0 auto 30px', fontSize: 13, display: 'flex', justifyContent: 'space-between' }}>
                    <div className='titleDesc'>
                        <div>· 이 영상에서 자막을 사용하시려면 토글 버튼을 on으로, 사용을 중단하실 때는 off로 바꾸어주십시오.</div>
                        <div>· 자동 번역은 한글을 기준으로 하는 번역만 지원합니다.</div>
                    </div>
                    <Switch uncheckedIcon={
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", 
                                            fontSize: 11, color: "#fff", paddingRight: 2 }} >
                                    Off
                                </div>
                            }
                            checkedIcon={
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", 
                                fontSize: 11, color: "#fff", paddingRight: 2 }} >
                                    On
                                </div>
                              }
                            onColor='#11a7ea'     
                            onChange={handleChange}
                            checked={ isUsed } />
                </div>             
            </div>
                
            {
                
            }
            <Table className="subtitleListTable">
                    <thead>
                        <tr>
                            <th scope="col">언어</th>
                            <th scope="col">사용</th>
                            <th scope="col">편집 / 생성</th>
                            <th scope="col">자동 생성</th>
                            <th scope="col">자동 번역</th>
                            <th scope="col">삭제</th>
                        </tr>
                    </thead>
                    <tbody>      
                        { 
                            isFetched && !isLoading ?
                                popupSubState && popupSubState.length > 0 ?
                                    koLabelArr.map((el, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{el.koLabel}</td>
                                                <td> 
                                                    <ToggleSwitch type='pop' loc='subtitle' uuid={uuid} lang={el.lang} koLabel={el.koLabel} index={index}/>
                                                </td>
                                                <td className='editModifyBtns'> 
                                                    <EditColumn type='pop'  setIsLoading={setIsLoading} isGenerating={autoGenSubState[index]} isTranslating={translateSubState[index]} setAutoGenSubState={setAutoGenSubState} setTranslateSubState={setTranslateSubState} index={index} el={el} uuid={uuid} />
                                                </td>
                                                <td> 
                                                    {
                                                        el.lang == 'ko' || el.lang == 'ja' || el.lang == 'en' ?
                                                            popupSubState[index].state == 2 ? 
                                                                <Button className="autoGenBtn loading" variant="primary" disabled={true} >
                                                                    생성 중
                                                                </Button>
                                                                :
                                                                autoGenSubState[index] == 2?
                                                                    <Button variant="primary" style={{ backgroundColor:'gray', fontWeight: 500 }}>
                                                                        생성 오류
                                                                    </Button>
                                                                    :
                                                                    autoGenSubState[index] == 1?
                                                                        <Button className="autoGenBtn done" variant="primary" disabled={true}>
                                                                            완료
                                                                        </Button>
                                                                        :
                                                                        autoGenSubState[index] == 3 ?
                                                                            <Button className="autoGenBtn" variant="primary" disabled={true}>
                                                                                <Wave style={{ display: 'flex', justifyContent: 'center' }} size={15} color='#fefeff' />
                                                                            </Button>
                                                                            :
                                                                            translateSubState[index] == 3?
                                                                                <Button className="autoGenBtn" variant="primary" disabled={true}>
                                                                                    자동 생성
                                                                                </Button>
                                                                                :
                                                                                <Button className="autoGenBtn" variant="primary" onClick={() => handleGenerate(el.lang, index)}>
                                                                                    자동 생성
                                                                                </Button>
                                                            : null
                                                        }
                                                </td>
                                                <td> 
                                                    {el.lang != 'ko' ?
                                                        popupSubState[index].state == 2 ? 
                                                            <Button className="autoTranslateBtn"  variant="warning" style={{ width: 85 }} disabled={true}>
                                                                생성 중
                                                            </Button>
                                                            :
                                                            translateSubState[index] == 2?
                                                                <Button className="autoTranslateBtn"  variant="warning" style={{ width: 85, backgroundColor:'gray', border: 0, fontWeight: 500 }}>
                                                                        오류
                                                                </Button>
                                                                :
                                                                translateSubState[index] == 1?
                                                                    <Button className="autoTranslateBtn"  variant="warning" style={{ width: 85, fontWeight: 500,  }}>
                                                                            완료
                                                                    </Button>
                                                                    :
                                                                    translateSubState[index] == 3 ?
                                                                        <Button className="autoTranslateBtn"  variant="warning" style={{ width: 85 }} disabled={true}>
                                                                            <Wave style={{ display: 'flex', justifyContent: 'center' }} size={15} color='#fefeff' />
                                                                        </Button>
                                                                        :
                                                                        autoGenSubState[index] == 3 ?
                                                                            <Button className="autoTranslateBtn"  variant="warning" style={{ width: 85 }} disabled={true}>
                                                                                자동 번역
                                                                            </Button>
                                                                            :
                                                                            <Button className="autoTranslateBtn"  variant="warning" style={{ width: 85 }} onClick={() => handleTranslate(el.lang,index)}>
                                                                                자동 번역
                                                                            </Button>
                                                            :null
                                                    }
                                                </td>
                                                <td className='editDeleteBtns'> 
                                                    {
                                                        popupSubState && popupSubState[index] != 0 ?
                                                            <DeleteColumn type="pop" index={index} uuid={uuid} el={el} />
                                                        :   null
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })  
                                :                             
                                    <tr>
                                        <td className="LoadingSpinner popup">
                                            <ChasingDots size={35} color='#23adad' />
                                        </td> 
                                    </tr>
                            :
                                 <tr>
                                    <td className="LoadingSpinner popup" >
                                        <ChasingDots size={35} color='#23adad' />
                                    </td> 
                                </tr>
                        }
                    </tbody>        
            </Table>
                
            <Modal className="generateSubModal" show={generateSubModalOpen} onHide={() => setGenerateSubModalOpen(false)}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body style={{ textAlign: 'center' }}>
                    <div style={{ padding: '3rem' }}>
                        이미 생성된 자막이 있습니다. <br />
                        자동 생성 시 영상 소리를 자동 인식하여 생성된 자막이 저장됩니다.<br /><br />
                        자동 생성을 하시겠습니까?
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setGenerateSubModalOpen(false)}>
                        취소
                    </Button>
                    <Button variant="primary" style={{ padding: '0.375rem 0.5rem' }} onClick={() => { setGenerateSubModalOpen(false); autoGenSub(generateInfo.lang, generateInfo.index)  }}>
                        확인
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal className="loadingGenerateSubModal" show={loadingSubModalOpen} onHide={() => setLoadingSubModalOpen(false)}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body style={{ textAlign: 'center' }}>
                    <div style={{ padding: '3rem' }}>
                        자막이 생성 중 입니다. <br />
                        잠시 기다려주세요.<br /><br />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" style={{ padding: '0.375rem 0.5rem' }} onClick={() => { setLoadingSubModalOpen(false); autoGenSub(generateInfo.lang, generateInfo.index)  }}>
                        확인
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal className="translateSubModal" show={translateSubModalOpen} onHide={() => setTranslateSubModalOpen(false)}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body style={{ textAlign: 'center' }}>
                    <div style={{ padding: '3rem' }}>
                        이미 생성된 자막이 있습니다. <br />
                        자동 번역 시 한글 자막 기준으로 번역된 자막이 저장됩니다.<br /><br />
                        자동 번역을 하시겠습니까?
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setTranslateSubModalOpen(false)}>
                        취소
                    </Button>
                    <Button variant="primary" style={{ padding: '0.375rem 0.5rem' }} onClick={() => { setTranslateSubModalOpen(false); translateSub(translateInfo.lang, translateInfo.index)  }}>
                        확인
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal className="translateErrorModal" show={translateErrorModalOpen} onHide={() => setTranslateErrorModalOpen(false)}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body style={{ textAlign: 'center' }}>
                    <div style={{ padding: '3rem' }}>
                        번역할 한글 자막이 없습니다. <br />
                        한글 자막을 생성 후 번역을 진행해주세요.
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" style={{ padding: '0.375rem 0.5rem' }} onClick={() => { setTranslateErrorModalOpen(false); }}>
                        확인
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default SubtitlePopup;
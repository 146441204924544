import React, { useEffect, useRef, useState } from 'react';

import axios from 'axios';
import { Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { toast, Slide, Flip } from 'react-toastify';
import { CgSpinner } from '@react-icons/all-files/cg/CgSpinner';
import { VscError } from '@react-icons/all-files/vsc/VscError';

import EditSubModal from '../videoEdit/subtitle/editModal/EditSubModal';
import SubToggleSwitch from './SubToggleSwitch';

import { setSubState } from '../../modules/subtitle';
import { setCurrentMediaInfo } from '../../modules/media';

import '../../../resources/assets/styles/sass/VideoDetail.scss'

function LangRow({ subInfo, index, videoInfo }) {

    const dispatch = useDispatch();  
    const toastId = useRef();

    const userToken = useSelector(state => state.user.userToken);
    const organizationData = useSelector(state => state.organization.organizationData);
    const subState = useSelector(state => state.subtitle.subState);

    const [editLang, setEditLang] = useState('');
    const [editKoLabel, setEditKoLabel] = useState('');
    const [editModalOpen, setEditModalOpen] = useState(false);

    useEffect(() => {
        dispatch(setSubState(subInfo));
        console.log('subInfo',subInfo)
    },[])
 
    function openModal(lang, koLabel) {
      setEditModalOpen(true);
      setEditLang(lang);
      setEditKoLabel(koLabel)
      dispatch(setCurrentMediaInfo(videoInfo));
    }
    //vodinfo 저장하기

    return (
      <>
        {
          subInfo &&
          subInfo.map((el, index) => {
            if (el.state === 2) {
              return null; // el.state가 2인 경우 아무것도 렌더링하지 않음
            }
            else {
              return (
                  <tr className="langRow" key={index}>
                      <td style={{ paddingLeft: 30 }}>{el.koLabel}</td>
                      <td></td>
                      <td>
                          <SubToggleSwitch key={index} langState={el} />
                      </td>
                      <td style={{ marginTop: 15 }}>
                        <Button className='subListEditBtn' disabled={false} onClick={() => openModal(el.lang, el.koLabel)}>
                                편집
                        </Button>
                      </td>
                  </tr>
                );
            }
          })
        }
        <EditSubModal key="sub" uuid={videoInfo.uuid} type="studio" editModalOpen={editModalOpen} setEditModalOpen={setEditModalOpen} editLang={editLang} editKoLabel={editKoLabel} />
      </>
      );
      
}

export default LangRow;
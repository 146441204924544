import React from 'react';

function ResultMenu(props) {
    return (
        <div>
            최종 화면
        </div>
    );
}

export default ResultMenu;
import React, {useEffect, useState} from 'react';

import axios from 'axios';

import { useSelector, useDispatch } from 'react-redux';
import jwt from 'expo-jwt';
import videojs from "video.js";
import hlsQualitySelector from "videojs-hls-quality-selector";
import "video.js/dist/video-js.css";

// videojs.registerPlugin('hlsQualitySelector', hlsQualitySelector);

import SubtitlePopup from '../components/popupManager/SubtitlePopup';
import ChapterPopup from '../components/popupManager/ChapterPopup';
import EditorPopup from '../components/popupManager/EditorPopup';


import '../../resources/assets/styles/sass/PopupManager.scss';

import { setPopupOrganizationData } from '../modules/organization';
import { setPopupUserData, setPopupUserToken, setPopupUserThumbnailCDN, setPopupUserVSCdn } from '../modules/user';
import { setPopupMediaUUID, setPopUpMediaInfo } from '../modules/media';
import {setPopupSubState} from '../modules/subtitle';

function PopupManager({match}){
    const dispatch = useDispatch();
    const { popupType, key } = match.params;

    const playerRef = React.useRef(null);
    
    const [wrongPopKey, setWrongPopKey] = useState(false);
    const [wrongPath, setWrongPath] = useState(false);
    const [orgData,setOrgData] = useState({});
    const [userToken,setUserToken] = useState('');
    const [userData,setUserData] = useState({});
    const [VodUUID,setVodUUID] = useState('');

    const organizationList = useSelector(state => state.organization.organizationList);

    useEffect(() => {

        if ( (popupType != '') && (popupType != 'subtitle') && (popupType != 'chapter') && (popupType != 'editor') ) {
            setWrongPath(true);
        }
    },[popupType]) 

    useEffect(() => {    
        var data = JSON.stringify({
            "popupKey": key
        });

        var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/auth/popup',
            headers: { 
                'Content-Type': 'application/json'
            },
            data : data
        };
    
        axios(config)
        .then( (response) => {
    
            var data = response.data;
            
            var orgData = response.data.organizationData; 

            var organizationData = {
                organization_name : orgData.organization_name,
                organization_code : orgData.organization_code,
                organization_url : orgData.organization_url,
                userThumbnailCDN : orgData.media_cdn,
                userVSCdn : orgData.media_vs_cdn,
                uToken : data.token
            }

            setVodUUID(data.uuid);
            setOrgData(organizationData);
            setUserToken(organizationData.uToken);

            dispatch(setPopupOrganizationData(organizationData));
            dispatch(setPopupMediaUUID(data.uuid));
            dispatch(setPopupUserToken(organizationData.uToken));
            dispatch(setPopupUserThumbnailCDN(organizationData.userThumbnailCDN));
            dispatch(setPopupUserVSCdn(organizationData.userVSCdn));

        })
        .catch((error) => {
            console.log(error);
            setWrongPopKey(true);
        });
    }, []);

    useEffect(() => {

        //redux 초기화
        return () => {
            dispatch(setPopupMediaUUID(''));
            dispatch(setPopUpMediaInfo({}));
            dispatch(setPopupOrganizationData({}));
            dispatch(setPopupUserData({}));
            dispatch(setPopupUserToken(''));
            dispatch(setPopupUserThumbnailCDN(''));
            dispatch(setPopupUserVSCdn(''));
            dispatch(setPopupSubState([]));
        };

    },[])

    useEffect(() => {

        if (Object.keys(orgData).length > 0) {
            var openToken = '';
            organizationList && organizationList.map((el) => {
                if(el.value == orgData.organization_code) {
                    openToken = el.open_token;
                    // dispatch(setOrganizationData(el));
                }
            })
            var extraToken = userToken.slice(-7);
            var replacedUserToken = userToken.replace(extraToken, '');

            var decodedUserData = jwt.decode(replacedUserToken, openToken + extraToken);
            var userData = {}; // 디코딩 된 사용자 정보
            for (const [key, value] of Object.entries(decodedUserData)) {
                userData[key] = decodeURI(value);
            }
            setUserData(decodedUserData)
            dispatch(setPopupUserData(JSON.stringify(userData)));    
        }

        if (userToken != '' && VodUUID != '') {
            var data = JSON.stringify({
                "userToken" : userToken,
                "uuid" : VodUUID,
                "organizationCode" : orgData.organization_code
            });

            var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/vod/getVodInfo',
            headers: { 
                'Content-Type': 'application/json'
            },
            data : data
            };

            axios(config)
            .then( (response) => {
                console.log(response)
                if(response.status==200){
                    dispatch(setPopUpMediaInfo(response.data));
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }

    },[userToken])

    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {
        return () => {
            if (playerRef.current) {
    
            playerRef.current.dispose();
            playerRef.current = null;
            }
        };
    }, []);

    return (
            
            wrongPopKey ? 
                <div style={{ 'marginTop' : 'calc(50vh - 25px)', 'transform': 'translate(calc(50% - 90px))' }}>
                    인증되지 않은 사용자 입니다.
                </div>
            :
                wrongPath ?
                    <div style={{ 'marginTop' : 'calc(50vh - 25px)', 'transform': 'translate(calc(50% - 90px))' }}>
                        잘못된 접근 경로입니다.
                    </div>
                    :
                        userToken || Object.keys(userData).length > 0 ?
                        (
                            <>
                                {popupType === 'subtitle' && <SubtitlePopup uuid={VodUUID} />}
                                {popupType === 'chapter' && <ChapterPopup uuid={VodUUID} />}
                                {popupType === 'editor' && <EditorPopup uuid={VodUUID} />}
                            </>
                        )
                        : null
        )

}

export default PopupManager;//라우팅처리
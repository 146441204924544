const PURGE = "chapter/PURGE";
const SET_CHAPTER_LIST = "chapter/SET_CHAPTER_LIST";
const SET_CHAP_STATE = "chapter/SET_CHAP_STATE";
const SET_POPUP_CHAP_STATE = "chapter/SET_POPUP_CHAP_STATE";

// 액션 생성 함수
export const purgeChapter = () => ({ type: PURGE });
export const setChapterList = chapterList => ({ type: SET_CHAPTER_LIST, chapterList });
export const setChapState = chapState => ({ type: SET_CHAP_STATE, chapState });
export const setPopupChapState = popupChapState => ({ type: SET_POPUP_CHAP_STATE, popupChapState });

// 초깃값 (상태가 객체가 아니라 그냥 숫자여도 상관 없습니다.)
const initialState = {
   chapterList : [],
   chapterState: [],
   popupChapState: []
}

export default function chapter(state = initialState, action) {
    switch (action.type) {
      case PURGE: 
        return {
          ...initialState
        }
      case SET_CHAPTER_LIST:
        return {
          ...state,
          chapterList : action.chapterList
        }
      case SET_CHAP_STATE:
        return {
          ...state,
          chapState : action.chapState
        }
      case SET_POPUP_CHAP_STATE:
        return {
          ...state,
          popupChapState : action.popupChapState
        }
    default:
        return state;
    }
} 
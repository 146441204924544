import React, {useEffect, useState, useRef} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Form, Button, InputGroup, Fade } from 'react-bootstrap';
import videojs from "video.js";

function Trimmer()  {
    const [videoSrc, setVideoSrc] = useState('');
    const [audioSrc, setAudioSrc] = useState('');
    const [ready,setReady] = useState(false);
    // const [video, setVideo] = useState('');
    const [watermarkImage, setWatermarkImage] = useState('');
    const [player, setPlayer] = useState(null);
    const dispatch = useDispatch();

    const videoRef = useRef(null); 
    const playerRef = useRef(null);

    //
    const canvasRef = useRef(null);
    const containerRef = useRef<HTMLElement>(null);
   
    const [ctx, setCtx] = useState();

    const video = videoRef.current;
    // const canvas = canvasRef.current;
    // const context = canvas && canvas.getContext('2d');



    useEffect(() => {
        const canvas = canvasRef.current
        //const context = canvas.getContext('2d')
        setCtx(canvas.getContext('2d'));
        // const canvas = document.createElement("canvas");
        // canvas.style.width = "100%";
        // canvas.style.height = "100%";

        // var context = canvas.getContext("2d");
        // containerRef.current.appendChild(canvas);

        // context.drawImage(
        //     videoRef.current,
        //     0,
        //     0,
        //    300,
        //     300
        // )  
        // video.addEventListener('loadeddata', function() {
        //     const duration = video.duration;
        //     const numThumbnails = 10;
        //     const interval = duration / numThumbnails;
      
        //     for (let i = 0; i < numThumbnails; i++) {
        //       const currentTime = interval * i;
        //       video.currentTime = currentTime;
      
        //       context.drawImage(
        //         video,
        //         0,
        //         i * (canvas.height / numThumbnails),
        //         canvas.width,
        //         canvas.height / numThumbnails
        //       );
        //     }
        //   });
        // }, [videoSrc]);

        //make sure Video.js player is only initialized once
        if (!playerRef.current) {
          
          const videoElement = videoRef.current;
          if (!videoElement) return;
  
            setPlayer( 
                playerRef.current = videojs(videoElement, {
                  autoplay: false,
                  playbackRates: [0.5, 1, 1.25, 1.5, 2],
                  width: 720,
                  height: 300,
                  controls: true,
                  sources: [
                    {
                      src: 'https://jaolyskypdfu7395038.cdn.ntruss.com/hls/88c38e24-077d-4886-8eb1-3af7e0a39e25/mp4/88c38e24-077d-4886-8eb1-3af7e0a39e25.mp4/index.m3u8',
                      type: 'application/x-mpegURL',
                    },
                  ]
                }, 
  
                () => {
                    console.log("player is ready");
                })
            )
          }  
  
      }, []);
  
      useEffect(() => {
  
          
        if(player != null){
          
            player.on("loadedmetadata", () => {
                const duration = player.duration();
                const numThumbnails = 10;
                const interval = duration / numThumbnails;
                console.log('interval',interval)
                // var wavesurferNode = document.getElementById('wavesurfer');
                // const wavesurfer = WaveSurfer.create({
                //     container: containerRef.current,
                //     waveColor: '#4F4A85',
                //     progressColor: '#383351',
                //     backend: 'MediaElement',
                //   })
                //   wavesurfer.backend.setPeaks(waveform.data, waveform.length)
                //   wavesurfer.load(videoRef.current, waveform.data);
                // for (let i = 0; i < numThumbnails; i++) {
                //     const currentTime = interval * i;
                //     video.currentTime = currentTime;
                //     console.log(currentTime,canvas.height,canvas.width,numThumbnails)
                //     // 비디오의 현재 시간에 해당하는 프레임을 캔버스에 그립니다.
                //     context && context.drawImage(video, 0, i * (canvas.height / numThumbnails), canvas.width, canvas.height / numThumbnails);
                // }

                // canvasRef.current = document.createElement("canvas");

                // if (canvasRef.current.getContext) {
                //const context = canvasRef.current.getContext('2d');
                //if (!context) return;
                // console.log('tyle',videoRef.current)
                // if (videoRef.current) {
                //     ctx.drawImage(
                //         videoRef.current,
                //         0,
                //         0,
                //         300,
                //         300
                //     )  
                // }

               // console.log('video',canvas.toDataURL('image/jpeg'))
                //}
            });
        } 
      }, [player]);    
      
    // function setVideoFile(e) {
    //     const file = e.target.files[0];
    //     setVideo(file);
    //     setVideoSrc("https://jaolyskypdfu7395038.cdn.ntruss.com/hls/88c38e24-077d-4886-8eb1-3af7e0a39e25/mp4/88c38e24-077d-4886-8eb1-3af7e0a39e25.mp4/index.m3u8");
    // }
    // function setWatermarkFile(e) {
    //     const file = e.target.files[0];
    //     setWatermarkImage(file);
    // }

    // const thumbnailVideo = async () => {
    //     if(!FFmpeg.isLoaded()) {
    //         await FFmpeg.load();
    //     }
    //     if (video) {
    //         FFmpeg.FS('writeFile', 'test_video.mp4', await fetchFile('https://jaolyskypdfu7395038.cdn.ntruss.com/hls/88c38e24-077d-4886-8eb1-3af7e0a39e25/mp4/88c38e24-077d-4886-8eb1-3af7e0a39e25.mp4/index.m3u8'));
    //     } else {
    //         alert('video file please!')
    //         return;
    //     }     

    //     await FFmpeg.run('-i', 'test_video.mp4', '-s', '400x222', '-ss', '00:00:14.435', '-vframes', '1', 'out.png');
    //     const data = FFmpeg.FS('readFile', 'out.png');
    //     // console.log(URL.createObjectURL(new Blob([data.buffer],{type: 'image/png'})))
    //     // setVideoSrc(URL.createObjectURL(new Blob([data.buffer],{type: 'video/mp4'})));
    // }

    // const trimVideo = async () => {
    //     if(!FFmpeg.isLoaded()) {
    //         await FFmpeg.load();
    //     }
    //     if (video) {
    //         FFmpeg.FS('writeFile', 'test_video.mp4', await fetchFile(video));
    //     } else {
    //         alert('video file please!')
    //         return;
    //     }      
    //     FFmpeg.FS('writeFile', 'test_video.mp4', await fetchFile(video));
    //     await FFmpeg.run('-i','test_video.mp4','-ss','00:00:05','-t','00:00:15', '-c:v', 'libx264', '-crf', '30', '-preset', 'ultrafast', 'cut.mp4');
    //     const data = FFmpeg.FS('readFile', 'cut.mp4');
    //     console.log(URL.createObjectURL(new Blob([data.buffer],{type: 'video/mp4'})))
    //     setVideoSrc(URL.createObjectURL(new Blob([data.buffer],{type: 'video/mp4'})));
    // }

    // const cropVideo = async () => {
    //     if(!FFmpeg.isLoaded()) {
    //         await FFmpeg.load();
    //     }
    //     if (video) {
    //         FFmpeg.FS('writeFile', 'test_video.mp4', await fetchFile(video));
    //     } else {
    //         alert('video file please!')
    //         return;
    //     }      
    //     FFmpeg.FS('writeFile', 'test_video.mp4', await fetchFile(video));
    //     await FFmpeg.run('-i','test_video.mp4','-filter:v','crop=200:200:50:50','-c:a', 'copy', '-preset', 'ultrafast', 'cut.mp4');
    //     const data = FFmpeg.FS('readFile', 'cut.mp4');
    //     console.log(URL.createObjectURL(new Blob([data.buffer],{type: 'video/mp4'})))
    //     setVideoSrc(URL.createObjectURL(new Blob([data.buffer],{type: 'video/mp4'})));
    // }

    // const extractAudio = async () => {
    //     if(!FFmpeg.isLoaded()) {
    //         await FFmpeg.load();
    //     }
    //     if (video) {
    //         FFmpeg.FS('writeFile', 'test_video.mp4', await fetchFile(video));
    //     } else {
    //         alert('video file please!')
    //         return;
    //     }      
    //     await FFmpeg.run('-i','test_video.mp4','-vn', '-ab', '128', 'cut.mp3');
    //     const data = FFmpeg.FS('readFile', 'cut.mp3');
    //     setAudioSrc(URL.createObjectURL(new Blob([data.buffer],{type: 'audio/mp3'})));
    // }

    // const muteAudio = async () => {
    //     if(!FFmpeg.isLoaded()) {
    //         await FFmpeg.load();
    //     }
    //     if (video) {
    //         FFmpeg.FS('writeFile', 'test_video.mp4', await fetchFile(video));
    //     } else {
    //         alert('video file please!')
    //         return;
    //     }      
    //     FFmpeg.FS('writeFile', 'test_video.mp4', await fetchFile(video));
    //     await FFmpeg.run('-i','test_video.mp4','-an', '-preset', 'ultrafast', 'cut.mp4');
    //     const data = FFmpeg.FS('readFile', 'cut.mp4');
    //     setVideoSrc(URL.createObjectURL(new Blob([data.buffer],{type: 'video/mp4'})));
    // }
    
    // const watermarkingText = async () => {
    //     if(!FFmpeg.isLoaded()) {
    //         await FFmpeg.load();
    //     }
    //     FFmpeg.FS('writeFile', 'font.ttf', await fetchFile(font));
    //     if (video) {
    //         FFmpeg.FS('writeFile', 'test_video.mp4', await fetchFile(video));
    //     } else {
    //         alert('video file please!')
    //         return;
    //     }        
    //     await FFmpeg.run('-i','test_video.mp4', '-vf','drawtext=fontfile=font.ttf:text=\'Artist\':fontcolor=white:fontsize=24:x=(w-text_w)/2:y=(h-text_h)/2', '-preset', 'ultrafast', '-c:a', 'copy', 'cut.mp4');
    //     const data = FFmpeg.FS('readFile', 'cut.mp4');
    //     setVideoSrc(URL.createObjectURL(new Blob([data.buffer],{type: 'video/mp4'})));
    // }

    // const watermarkingImage = async () => {
    //     if(!FFmpeg.isLoaded()) {
    //         await FFmpeg.load();
    //     }
    //     if (watermarkImage) {
    //         FFmpeg.FS('writeFile', 'logoImage.png', await fetchFile(watermarkImage));
    //     } else {
    //         alert('watermark Image file please!')
    //         return;
    //     }        
    //     if (video) {
    //         FFmpeg.FS('writeFile', 'test_video.mp4', await fetchFile(video));
    //     } else {
    //         alert('video file please!')
    //         return;
    //     }        
    //     await FFmpeg.run('-i','test_video.mp4', '-i', 'logoImage.png', '-filter_complex', 'overlay=x=(main_w-overlay_w)/2:y=(main_h-overlay_h)/2', '-preset', 'ultrafast', '-c:a', 'copy', 'cut.mp4');
    //     const data = FFmpeg.FS('readFile', 'cut.mp4');
    //     setVideoSrc(URL.createObjectURL(new Blob([data.buffer],{type: 'video/mp4'})));
    // }

    return (
        <div className="Trimmer" style={{ padding: 30 }}>
            {/* <div
                id="wavesurfer"
                style={{ width: "20vw", height: "20vh", overflow: "hidden" }}
                ref={containerRef}
                /> */}
            {/* <Row style={{ fontSize: 13, textAlign: 'center', display: 'flex', alignItems: 'center', marginBottom: 20}}>
                <label className="col-4" htmlFor="videoInput" >
                    업로드용 비디오 파일 등록: 
                </label>
                <input className="col-8" type="file" id="videoInput" onChange={setVideoFile}/>
            </Row>
            

            <Row style={{ fontSize: 13, textAlign: 'center', display: 'flex', alignItems: 'center', marginBottom: 20}}>
                <label className="col-4" htmlFor="watermarkInput">
                    워터마크용 이미지 파일 등록: 
                </label>
                <input className="col-8" type="file" id="watermarkInput" onChange={setWatermarkFile} />
            </Row>

            <div style={{ marginBottom: 20 }}>
                <Button style={{ marginRight: 20 }} onClick={trimVideo}>trim</Button>
                <Button style={{ marginRight: 20 }} onClick={cropVideo}>crop</Button>
                <Button style={{ marginRight: 20 }} onClick={extractAudio}>extract mp3</Button>
                <Button style={{ marginRight: 20 }} onClick={muteAudio}>Mute sound</Button>
                <Button style={{ marginRight: 20 }} onClick={watermarkingText}>text watermark</Button>
                <Button style={{ marginRight: 20 }} onClick={watermarkingImage}>Image watermark</Button>
            </div> */}
            {/* <Button style={{ marginRight: 20 }} onClick={thumbnailVideo}>trim</Button> */}

            <video ref={videoRef} id="videoEl" className="video-js vjs-big-play-centered" style={{ width: 720, height: 480 }} >
            
            </video>
{/* 
            <canvas id="thumbnailCanvas" ref={canvasRef} width="640" height="120"></canvas> */}
            {/* <audio controls
                    src={audioSrc}>
            </audio>

            <div style={{ border: '1px solid gray', padding: 20, marginTop: 20 }}> 
                <div style={{ borderBottom: '1px solid gray', fontSize: 15, marginBottom: 5, paddingBottom: 5 }}> 기능 설명 </div>
                <div>trim: 00:05 ~ 00:15 까지 자름</div>
                <div>crop: 영상 일부분만 보여지게 자름</div>
                <div>extract mp3: 영상에서 오디오 추출한 후 오디오 출력</div>
                <div>Mute Sound: 영상에서 소리 제거 한 비디오 출력</div>
                <div>Text watermark: 주어진 텍스트를 워터마크로 입력하여 비디오로 출력</div>
                <div style={{ marginBottom: 30 }}>Image watermark: 주어진 이미지를 워터마크로 입력하여 비디오로 출력</div>
                <div style={{ borderBottom: '1px solid gray', fontSize: 15, marginBottom: 5, paddingBottom: 5 }}>워터마크 적용 시간 테스트 </div>
                <div> 720p 544MB 영상(한 시간 길이)에 텍스트 워터마크 적용하는데 걸린 시간 : 9분 30초 </div>
                <div style={{ marginBottom: 5 }}> 720p 544MB 영상(한 시간 길이)에 텍스트 워터마크 적용 후 용량 변화 : 544200kB -&gt; 756904kB </div>
                <div> 720p 544MB 영상(한 시간 길이)에 이미지 워터마크 적용하는데 걸린 시간 : 9분 21초 </div>
                <div> 720p 544MB 영상(한 시간 길이)에 텍스트 워터마크 적용 후 용량 변화 : 544200kB -&gt; 793296kB </div>
            </div> */}

        </div>
    )
}

export default Trimmer;

import React, { useState, useEffect, useRef } from 'react';

import { Row, Button, Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { toast, Flip, Slide } from 'react-toastify';
import { VscCheck } from '@react-icons/all-files/vsc/VscCheck';
// import { VscError } from '@react-icons/all-files/vsc/VscError';
// import { FaAngleLeft } from '@react-icons/all-files/fa/FaAngleLeft';
// import { ChasingDots, Wave } from 'better-react-spinkit';

import { setCurrentMediaInfo } from '../modules/media';
import { setEditProjIdx, setEditToolIdx } from '../modules/editor';

import Player from './editor/Player';
import EditList from './editor/EditList';
import EditTools from './editor/EditTools';

import '../../resources/assets/styles/sass/Editor.scss';
import Thumbnail from './editor/timeline/Thumbnail';
import Loading from './_commons/Loading';
import VisTimeline from './editor/timeline/VisTimeline';

function VideoDetailEditor({match}) {

    const userToken = useSelector(state => state.user.userToken);
    const organizationData = useSelector(state => state.organization.organizationData);
    const userThumbnailCDN = useSelector(state => state.user.userThumbnailCDN);
    const userVSCdn = useSelector(state => state.user.userVSCdn);

    const [editProjIdx, setEditProjIdx] = useState(-1);
    const [editorModalOpen, setEditorModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [modalPlayState, setModalPlayState] = useState(false);

    const dispatch = useDispatch();  
    const location = useLocation();
    const toastId = useRef();

    useEffect(() => {

        toast.update(toastId.current ,{
            render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscCheck size="23" /> 자막 자동 생성 성공! </div></div>,
            type: toast.TYPE.INFO,
            autoClose: 1000,
            position: "top-center",
            className: 'rotateY animated',
            transition: Flip
        });

        // 동영상 정보 가져오기
        var data = JSON.stringify({
            "userToken" : userToken,
            "uuid" : location.pathname.replace("/video/editor/",''),
            "organizationCode" : organizationData.value
        });

        var config = {
        method: 'post',
        url: API_URL + '/' + API_VERSION + '/vod/getVodInfo',
        headers: { 
            'Content-Type': 'application/json'
        },
        data : data
        };
        
        axios(config)
        .then( (response) => {
            console.log(response)
            if(response.status==200){
                dispatch(setCurrentMediaInfo(response.data));
            }
        })
        .catch((error) => {
            console.log(error);
        });

    }, [])
    
    function handlePublish() {
        var data = JSON.stringify({
            "tk" : userToken,
            "uuid" : location.pathname.replace("/video/editor/",''),
            "ogc" : organizationData.value,
            "project_id": editProjIdx
        });

        var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/editor/publish',
            headers: { 
                'Content-Type': 'application/json'
                },
            data : data            
        };
            
        axios(config)
        .then( (response) => {
            console.log(response.data.data);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    return (
        <>
            <div style={{ position: 'relative' }}>
                <div className="contentHeader" style={{ display: 'flex', marginBottom: 20, alignItems: 'center', padding: 20 }}>
                    <div className='containerSubtitleTitle'>
                        <div> 편집기 </div>
                    </div>
                    <div className='titleDesc' style={{ marginLeft: 10 }}>
                        <div>· 프로젝트는 동영상 당 최대 5개까지 생성가능합니다.</div>
                    </div>
                </div>

                {
                    !isLoading ?
                            <Row>
                                <div className="col-xl-6 col-12 editorPopupGrid"  style={{ padding: 0 }}>
                                    <EditList setEditorModalOpen={setEditorModalOpen} setIsLoading={setIsLoading} setEditProjIdx={setEditProjIdx} uuid={location.pathname.replace("/video/editor/",'')} tk={userToken} ogc={organizationData.value} />
                                </div>
                                <div className="col-xl-6 col-12 editorPopupGrid" style={{ padding: 0 }}>
                                    {
                                        userVSCdn && userThumbnailCDN ?
                                            <Player type="popup" uuid={location.pathname.replace("/video/editor/",'')} VSCdn={userVSCdn}/>
                                            :   null
                                    }
                                </div>
                            </Row>
                            :
                            <Loading />
                }
            </div>


            <Modal className="editModal" show={editorModalOpen} onHide={() => {setEditorModalOpen(false); setEditToolIdx(-1); } }>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="editModalBody" style={{ position: "relative", overflowY: "auto", paddingBottom: 30 }}>
                    <Row style={{ margin: "20px 0px", display: 'flex', justifyContent: 'right', alignItems : 'center' }}>
                        <Button variant="primary" style={{ width: 85, height: 30, marginRight: 15 }} onClick={handlePublish} >영상 생성</Button>
                    </Row>
                    <Row style={{ marginBottom : "20px"}} >
                        <div className="col-xl-6 col-12 editorModalGrid"  style={{ padding: 0  }}>
                            <EditTools type="studio" uuid={location.pathname.replace("/video/editor/",'')} tk={userToken} ogc={organizationData.value} />
                        </div>
                        <div className="col-xl-6 col-12 editorModalGrid" style={{ padding: 0 }}>
                            {
                                editorModalOpen && userVSCdn && userThumbnailCDN ?
                                    <Player type="popup" uuid={location.pathname.replace("/video/editor/",'')} VSCdn={userVSCdn} setModalPlayState={setModalPlayState} />
                                    :   null
                            }
                        </div>
                    </Row>

                    <Row>
                        {/* <Thumbnail /> */}
                        <VisTimeline modalPlayState={modalPlayState} />
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default VideoDetailEditor;
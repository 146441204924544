import React, { useRef, useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import { 
//     setPlayingTimeTimelineToMediaPlayer
//   } from '../../modules/media';

import Slider from './Slider';
import Slider2 from './Slider2';
import CropLayer from './CropLayer';

import '../../../../resources/assets/styles/sass/Editor.scss';

const renderThumbnails = (thumbnails: string[]) =>

  thumbnails.map((image,idx) => (
      <img className='thumbImgs'
        key={idx}
        src={image}
        alt=""
      />
  ));

function Thumbnail() {
    const userToken = useSelector(state => state.media.userToken);
    const videoDurationTime= useSelector(state => state.media.videoDurationTime);
    const thumbnails= useSelector(state => state.media.thumbnails);
    const isCropAndDuration= useSelector(state => state.media.isCropAndDuration);
    const isCrop = useSelector(state => state.editor.isCrop)
    const startEnd = useSelector(state => state.media.startEnd);
    const thumnailStatus = useSelector(state => state.media.thumnailStatus);

    const [time, setTime] = useState(0);
    const dispatch = useDispatch();

    const thumbnailRef = useRef<HTMLDivElement>(null);
    const hoverSliderRef = useRef<HTMLDivElement>(null);

    const handleClick = () => {
        console.log('start + time',start, time);
        //dispatch(setPlayingTimeTimelineToMediaPlayer(start + time))
    };

    const handleMouseMove = (event: MouseEvent) => {
        const slider = hoverSliderRef.current;

        const mouseLocation = event.pageX;
        const offset = thumbnailRef.current.offsetLeft;

        const distance = mouseLocation - offset;

        const width = thumbnailRef.current.clientWidth;
        const interval = isCrop ? videoDurationTime : end - start;

        const hoverTime = (distance / width) * interval;

        setTime(hoverTime);
        slider.style.left = `${distance}px`;
    };

    const handleMouseLeave = () => {
        hoverSliderRef.current.style.display = 'none';
    };

    const handleMouseEnter = () => {
        hoverSliderRef.current.style.display = 'block';
    };

    const Thumbnails = useMemo(
        () => renderThumbnails(thumbnails),
        [thumbnails]
      );

    return (
        <div className='thumbsTimeline'
            ref={thumbnailRef}
            //onClick={handleClick}
            // onMouseMove={handleMouseMove}
            // onMouseLeave={handleMouseLeave}
            // onMouseEnter={handleMouseEnter}
        >
          {/* {isCrop && <CropLayer />} */}
          <Slider2 thumbnailRef={thumbnailRef} />
          {/* { 
            thumbnails&&
              thumbnails.map((image,idx) => {
                return(
                    <img className='thumbImgs'
                      key={idx}
                      src={image}
                      alt=""
                    />
                )
              })
          } */}
          { 
            thumbnails.length > 0 ?
              thumbnails.map((image,idx) => {
                return(
                  <div>{image}</div>
                )
              })
              : null
          }
          <div style={{ width: '100%', height: '7rem', backgroundColor: 'rgb(228,232,238)' }} />
        </div>
    );
}

export default Thumbnail;
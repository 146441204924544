

import '../../../resources/assets/styles/sass/Header.scss';

import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Link, useHistory } from "react-router-dom";
import { FaUserCircle } from '@react-icons/all-files/fa/FaUserCircle';
import { FaCaretDown } from '@react-icons/all-files/fa/FaCaretDown';
import { RiVideoAddFill } from "@react-icons/all-files/ri/RiVideoAddFill";

import { purgeMedia } from '../../modules/media';
import { purgeOrganiztion } from '../../modules/organization';
import { purgeStatus } from '../../modules/status';
import { purgeSubtitle } from '../../modules/subtitle';
import { purgeChapter } from '../../modules/chapter';
import { purgeUser } from '../../modules/user';

const Header = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userData = useSelector(state => state.user.userData) ;
    const organizationData = useSelector(state => state.organization.organizationData);
    // // const uuid = useSelector(state => state.media.uuid);
    // // const fileName = useSelector(state => state.media.fileName);
    // // const sidebarState = useSelector(state => state.status.sidebarState);


    function handleLogout() {
        dispatch(purgeMedia());
        dispatch(purgeOrganiztion());
        dispatch(purgeStatus());
        dispatch(purgeSubtitle());
        dispatch(purgeChapter());
        dispatch(purgeUser());

        history.push('/');
    }

    const popover = (
        <Popover id="popover" style={{ transform: 'translate3d(996.5px, 35px, 0px)' }}>
           <Popover.Content>
              <div><a href='#'> 정보 </a></div>
              <div> 수정 </div>
              <div onClick={handleLogout}> 로그아웃 </div>
           </Popover.Content>
        </Popover>
     );

    return (
        <div id="header">

            <Link to="/">
                <div style={{ position: 'absolute', left: 5 }}> 
                    <div style = {{ margin: 10, fontSize: 15, fontWeight: 'bold', color: '#585656'}}>
                        <img className="coursemosImage" src={`https://mpvldhluuktv5373941.cdn.ntruss.com/logo/${organizationData.value}_logo.png`} style = {{ width: 35, marginRight: 10}} />
                        {organizationData && organizationData.label}
                    </div>
                </div>
            </Link>

            <Link to="/video">
                <div className='uploadBtn'>
                    <div className = "uploadBtn_icon">
                        <RiVideoAddFill size="28" color="#aaa" />
                    </div>
                    <div> 만들기 </div>
                </div>
            </Link>

            <div className='profile'>
                <div className ="profile_header" > 
                    <FaUserCircle size="30" color="#ccc" />
                </div>
                 <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={popover}
                    >
                    <div className ="profile_name" >
                        <div> {userData && userData.firstname} </div>
                        <FaCaretDown size="20" color="#ccc" />
                    </div>
                </OverlayTrigger>
            </div>   
        </div>

    );
};

export default Header;
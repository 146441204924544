import React, { useState, useEffect, useRef } from 'react';

import { Button, Modal, Row } from 'react-bootstrap';
import axios from 'axios';
import { toast, Slide, Flip } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { ChasingDots, Wave } from 'better-react-spinkit';
import { FaAngleLeft } from '@react-icons/all-files/fa';
import { VscError, VscCheck } from '@react-icons/all-files/vsc';

import { setEditProjIdx, setEditToolIdx } from '../../modules/editor';

import Player from '../editor/Player';
import EditList from '../editor/EditList';
import EditTools from '../editor/EditTools';
import Loading from '../_commons/Loading';

import '../../../resources/assets/styles/sass/Editor.scss';
import Example from '../../containers/Example';

function EditorPopup({uuid}) {
    
    const popupvodInfo = useSelector(state => state.media.popupvodInfo);
    const PopupUserToken = useSelector(state => state.user.PopupUserToken);
    const popupOrganizationData = useSelector(state => state.organization.popupOrganizationData);
    const PopupUserThumbnailCDN = useSelector(state => state.user.PopupUserThumbnailCDN);
    const PopupUserVSCdn = useSelector(state => state.user.PopupUserVSCdn);
    const editProjIdx = useSelector(state => state.editor.editProjIdx);

    const dispatch = useDispatch();  

    const [isFetched, setIsFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [editorModalOpen, setEditorModalOpen] = useState(false);

    useEffect(() => {
        return () => {
            dispatch(setEditProjIdx(-1));
        } 
    },[])

    function handlePublish() {
        var data = JSON.stringify({
            "tk" : PopupUserToken,
            "uuid" : uuid,
            "ogc" : popupOrganizationData.organization_code,
            "project_id": editProjIdx
        });

        var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/editor/publish',
            headers: { 
                'Content-Type': 'application/json'
                },
            data : data            
        };
            
        axios(config)
        .then( (response) => {
            console.log(response.data.data);
        })
        .catch((error) => {
            console.log(error);
        });
    }
    useEffect(() => {

    })
    return (
        <>
            {
                !isFetched ?
                    <div>
                        <div className="contentHeader" style={{ padding: 20 }}>
                            <div className='containerEditorTitle' style={{ display: 'flex', marginBottom: 20, alignItems: 'center' }}>
                                <div style={{ fontSize: 27, fontWeight: 700, marginRight: 20, display: 'flex',  }}>
                                    <div> 동영상 편집 </div>
                                </div>
                                <div style={{ display: "flex"}}>
                                    <div className="editVideoTitle" style={{ marginRight: 15 }}>
                                        { popupvodInfo.realFileName }
                                    </div>
                                    <div style={{ color: "gray"}}>
                                        ( { uuid } ) 
                                    </div>
                                </div>
                            </div>
                        </div>                

                        {
                            !isLoading ?
                                <Row>
                                    <div className="col-xl-6 col-12 editorPopupGrid"  style={{ padding: 0 }}>
                                        <EditList setEditorModalOpen={setEditorModalOpen} setEditProjIdx={setEditProjIdx} setIsLoading={setIsLoading} uuid={uuid} tk={PopupUserToken} ogc={popupOrganizationData.organization_code} />
                                    </div>
                                    <div className="col-xl-6 col-12 editorPopupGrid" style={{ padding: 0 }}>
                                        {
                                            PopupUserVSCdn && PopupUserThumbnailCDN ?
                                                <Player type="popup" uuid={uuid} VSCdn={PopupUserVSCdn}/>
                                                :   null
                                        }
                                    </div>
                                </Row>
                            :
                                <div style={{ position: 'absolute', top: "calc(50% - 20px)", left: "calc(50% - 20px)" }}>
                                    <ChasingDots size={35} color='#23adad' />
                                </div>
                        }
                    </div>
                :
                <div style= {{ position: 'fixed', top: '50%', left: '50%' }}>
                    <ChasingDots size={35} color='#23adad'/>
                </div>
            }

            <Modal className="editModal" show={editorModalOpen} onHide={() => { setEditorModalOpen(false); setEditToolIdx(-1); } }>
                <Modal.Header closeButton>
                    <Modal.Title> 동영상 편집 </Modal.Title>
                </Modal.Header>
                   
                <Modal.Body className="editModalBody" style={{ position: "relative", overflowY: "auto", paddingBottom: 30 }}>
                    <Row style={{ margin: "20px 0px", display: 'flex', justifyContent: 'right', alignItems : 'center' }}>
                        <Button variant="primary" style={{ width: 85, height: 30, marginRight: 15 }} onClick={handlePublish} >영상 생성</Button>
                    </Row>
                    <Row>
                        <div className="col-xl-6 col-12 editorModalGrid"  style={{ padding: 0  }}>
                            <EditTools type="popup" uuid={uuid} tk={PopupUserToken} ogc={popupOrganizationData.value} />
                        </div>
                        <div className="col-xl-6 col-12 editorModalGrid" style={{ padding: 0 }}>
                            {
                                PopupUserVSCdn && PopupUserThumbnailCDN ?
                                    <Player type="popup" uuid={uuid} VSCdn={PopupUserVSCdn}/>
                                    :   null
                            }
                        </div>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default EditorPopup;
import React,{ useEffect, useState, useRef } from 'react';

import axios from 'axios';
import Select from 'react-select'
import { Row } from 'react-bootstrap';
import { IoTrashSharp } from '@react-icons/all-files/io5/IoTrashSharp';
import { AiOutlinePlus } from '@react-icons/all-files/ai/AiOutlinePlus';
import { useDispatch, useSelector } from 'react-redux';

import { setProjectData } from '../../../modules/editor';
import WatermarkRow from './WatermarkRow';
import WatermarkCanvas from './WatermarkCanvas';
import Thumbnail from '../timeline/Thumbnail';

function watermarkMenu({ uuid, tk, ogc  }) {

    const projectData = useSelector(state => state.editor.projectData);

    const [watermarkList, setWatermarkList] = useState([]);
    const [tmpProjectData, setTmpProjectData] = useState([]);

    const dispatch = useDispatch();  

    useEffect(() => {
        setWatermarkList([]);
        setTmpProjectData(projectData);

        var tmpList = [...watermarkList];
        var tmpType = [];

        if (projectData.length > 0) {
            projectData.map((el, idx) => {
                if (el.action == "watermark") {
                    tmpList.push(el.data)
                }
            })
            
            setWatermarkList(tmpList);
            console.log('console',tmpList,tmpType)
        }
    },[])

    function addCard() {

        var tmpList = watermarkList;
        tmpList.push(
            {
                "type" : "image",
                "text" : "",
                "x" : "0",
                "y" : "0",
                "width" : "0",
                "height" : "0"
            }
        );
        setWatermarkList([...tmpList])
    }

    return (
        <>
            <div className='menuBox'>
                {
                    watermarkList && watermarkList.map((el,idx) => {
                        return (
                            <WatermarkRow key={idx} idx={idx} el={el} watermarkList={watermarkList} setWatermarkList={setWatermarkList} uuid={uuid} tk={tk} ogc={ogc} />
                        )
                    })
                }             
                <div className="plusBtn" onClick={() => addCard()}><div className='plusBtnIcon'><AiOutlinePlus /> 새로운 워터마크 추가 </div></div>
            </div>
        </>

    );
}

export default watermarkMenu;
const PURGE = "editor/PURGE";
const SET_EDIT_PROJECT_IDX = "editor/SET_EDIT_PROJECT_IDX";
const SET_EDIT_PROJECT_LIST = "editor/SET_EDIT_PROJECT_LIST";
const SET_PROJECT_DATA = "editor/SET_PROJECT_DATA";
const SET_PROJECT_INFO = "editor/SET_PROJECT_INFO";
const SET_EDIT_TOOL_IDX = "editor/SET_EDIT_TOOL_IDX";
const SET_WATERMARK_DATA = "editor/SET_WATERMARK_DATA";
const SET_CROP_DATA = "editor/SET_CROP_DATA";
const SET_IS_CROP = "editor/SET_IS_CROP";
const SET_START_END = "editor/STARTEND";

// 액션 생성 함수
export const purgeEditor = () => ({ type: PURGE });
export const setEditProjIdx = editProjIdx => ({ type: SET_EDIT_PROJECT_IDX, editProjIdx });
export const setEditProjList = editProjList => ({ type: SET_EDIT_PROJECT_LIST, editProjList });
export const setProjectData = projectData => ({ type: SET_PROJECT_DATA, projectData });
export const setProjectInfo = projectInfo => ({ type: SET_PROJECT_INFO, projectInfo });
export const setEditToolIdx = editToolIdx => ({ type: SET_EDIT_TOOL_IDX, editToolIdx });
export const setWatermarkData = watermarkData => ({ type: SET_WATERMARK_DATA,  watermarkData });
export const setCropData = cropData => ({ type: SET_CROP_DATA, cropData });
export const setISCrop = isCrop => ({ type: SET_IS_CROP, isCrop });
export const setStartEnd = startEnd => ({ type: SET_START_END, startEnd });


// 초깃값 (상태가 객체가 아니라 그냥 숫자여도 상관 없습니다.)
const initialState = {
    editProjIdx : -1,
    editProjList : [],
    projectData : [],
    projectInfo : [],
    editToolIdx : 0,
    watermarkData: [],
    isCrop: false,
    cropData: {
      prev: {
        start: 0,
        end: 0
      },
      current: {
        start: 0,
        end: 0
      }
    },
    startEnd : {
      start: 0,
      end: 0
    }
}

export default function editor(state = initialState, action) {
    switch (action.type) {
      case PURGE: 
        return {
          ...initialState
        }
      case SET_EDIT_PROJECT_IDX:
        return {
          ...state,
          editProjIdx : action.editProjIdx
        }
      case SET_EDIT_PROJECT_LIST:
        return {
          ...state,
          editProjList : action.editProjList
        }
      case SET_PROJECT_DATA:
        return {
          ...state,
          projectData : action.projectData
        }
      case SET_PROJECT_INFO:
        return {
          ...state,
          projectInfo : action.projectInfo
        }
      case SET_EDIT_TOOL_IDX:
        return {
          ...state,
          editToolIdx : action.editToolIdx
        }
      case SET_WATERMARK_DATA:
        return {
          ...state,
          watermarkData : action.watermarkData
        }
      case SET_IS_CROP:
        return {
          ...state,
          isCrop : action.isCrop
        }
      case SET_CROP_DATA:
        return {
          ...state,
          cropData : action.cropData
        }
      case SET_START_END:
        return {
          ...state,
          startEnd : action.startEnd
        }
    default:
        return state;
    }
} 
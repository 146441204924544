import React, { useState, useRef, useEffect } from 'react';

import { Button, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { toast, Slide, Flip } from 'react-toastify';
import { VscCheck } from '@react-icons/all-files/vsc/VscCheck';
import { VscError } from '@react-icons/all-files/vsc/VscError';
import { CgSpinner } from '@react-icons/all-files/cg/CgSpinner';

import {
    setChapState,
    setPopupChapState
} from '../../../modules/chapter';


function DeleteColumn( { type, index, el, uuid } )  {

    const dispatch = useDispatch();

    const PopupUserToken = useSelector(state => state.user.PopupUserToken);
    const popupOrganizationData = useSelector(state => state.organization.popupOrganizationData);
    const popupChapState = useSelector(state => state.chapter.popupChapState);

    const userToken = useSelector(state => state.user.userToken);
    const organizationData = useSelector(state => state.organization.organizationData);
    const chapState = useSelector(state => state.chapter.chapState);

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [delState, setDelState] = useState(false);

    var tk = type=="pop" ? PopupUserToken : userToken;
    var ogc = type=="pop" ? popupOrganizationData.organization_code : organizationData.value;

    const toastId = useRef();

    useEffect(() => {

        if (type == "pop") {

            if (popupChapState && popupChapState.length > 0) {
                if (popupChapState[index] == 0){
                    setDelState(false);
                }
                else {
                    setDelState(true);
                }
            }
        }
        else {
            if (chapState && chapState.length > 0) {
                if (chapState[index] == 0){
                    setDelState(false);
                }
                else {
                    setDelState(true);
                }
            }
        }

    },[])

    function handleDeleteModal() {
        if(!deleteModalOpen) {
            setDeleteModalOpen(true)
        }
    }

    function deleteChap() {

        if (type == "pop") {
            var tmpChapState = popupChapState;
        }
        else {
            var tmpChapState = chapState;
        }

        toastId.current = toast.info((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><CgSpinner size="22" color="#fff" className="loaderIcon" /></div><div>챕터 삭제 중입니다.</div></div>),{
            type: toast.TYPE.INFO,
            autoClose: 1000,
            position: "top-center",
            className: 'rotateY animated',
            transition: Flip
        });
        var data = JSON.stringify({
            "tk" : tk,
            "uuid" : uuid,
            "ogc" : ogc,
            "lang" : el.lang
        });
    
        var config = {
            method: 'delete',
            url: API_URL + '/' + API_VERSION + '/chapter/delete', 
            headers: { 
            'Content-Type': 'application/json'
            },
            data : data
        };
        
        axios(config)
        .then( (response) => {
            setDeleteModalOpen(false)
            if(response.data.code==100){
                toast.update(toastId.current ,{
                    render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscCheck size="23" /> 삭제 되었습니다! </div></div>,
                    type: toast.TYPE.SUCCESS,
                    autoClose: 1000,
                    position: "top-center",
                    className: 'rotateY animated',
                    transition: Flip
                });
                tmpChapState[index] = 0;

                if (type=="pop") {
                    dispatch(setPopupChapState([...tmpChapState]));
                }
                else {
                    dispatch(setChapState([...tmpChapState]));
                }
               
                setDelState(false);
            }
            else {
                toast.update(toastId.current ,{
                    render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 챕터 삭제에 오류가 생겼습니다! </div></div>,
                    type: toast.TYPE.ERROR,
                    autoClose: 1000,
                    position: "top-center",
                    className: 'rotateY animated',
                    transition: Flip
                });
            }
        })
        .catch((error) => {
            setDeleteModalOpen(false);
            toast.error((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 자막 삭제에 오류가 생겼습니다! </div></div>),{
                type: toast.TYPE.ERROR,
                autoClose: 1000,
                position: "top-center",
                className: 'rotateY animated',
                transition: Flip
            });
        });
    }

    return (
        <>
            {
                delState ?
                    <Button variant="danger" style={{ width: 65 }} onClick={() => handleDeleteModal()}>
                        삭제
                    </Button>
                :
                    null
            }


            <Modal className="deleteModal" show={deleteModalOpen} onHide={() => setDeleteModalOpen(false)}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body style={{ textAlign: 'center' }}>{ el.koLabel } 자막을 삭제하시겠습니까?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setDeleteModalOpen(false)}>
                        취소
                    </Button>
                    <Button variant="primary"  style={{ padding: '0.375rem 0.5rem', opacity: 0.7 }} onClick={deleteChap}>
                        삭제
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    );
}

export default DeleteColumn;
import React,{ useEffect, useState, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { IoTrashSharp } from '@react-icons/all-files/io5/IoTrashSharp';

import { setProjectData, setWatermarkData } from '../../../modules/editor';

function DeleteBtn({idx, watermarkList, setWatermarkList }) {

    const dispatch = useDispatch();  

    function handleDelete() {
        console.log('idx',idx)
        const filteredList = watermarkList.filter((_, index) => index !== idx);
        setWatermarkList(filteredList);
        
        dispatch(setWatermarkData(tmpCardList))
    }

    return (
        <div className='col-1' style={{ cursor: 'pointer' }} onClick={(e) => handleDelete()}>
            <IoTrashSharp />
        </div>
    );
}

export default DeleteBtn;
import React, { useState, useEffect, useRef } from 'react';

import { Row, Button, Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { toast, Flip, Slide } from 'react-toastify';
import { VscCheck } from '@react-icons/all-files/vsc/VscCheck';
import { VscError } from '@react-icons/all-files/vsc/VscError';
import { IoIosArrowBack } from '@react-icons/all-files/io/IoIosArrowBack.esm';
import { ChasingDots, Wave } from 'better-react-spinkit';

import { setEditProjIdx, setEditToolIdx } from '../../modules/editor';

import Player from './Player';
import EditList from './EditList';
import EditTools from './EditTools';

import '../../../resources/assets/styles/sass/Editor.scss';
import Thumbnail from './timeline/Thumbnail';
import Loading from '../_commons/Loading';
import Test from './timeline/Test';
// import Test2 from './timeline/Test2';

function EditComp({ uuid, setUuid }) {

    const userToken = useSelector(state => state.user.userToken);
    const organizationData = useSelector(state => state.organization.organizationData);
    const userThumbnailCDN = useSelector(state => state.user.userThumbnailCDN);
    const userVSCdn = useSelector(state => state.user.userVSCdn);

    const [editProjIdx, setEditProjIdx] = useState(-1);
    const [editorModalOpen, setEditorModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    function handleBack() {
        setUuid('');
    }

    function handlePublish() {
        var data = JSON.stringify({
            "tk" : userToken,
            "uuid" : uuid,
            "ogc" : organizationData.value,
            "project_id": editProjIdx
        });

        var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/editor/publish',
            headers: { 
                'Content-Type': 'application/json'
                },
            data : data            
        };
            
        axios(config)
        .then( (response) => {
            console.log(response.data.data);
        })
        .catch((error) => {
            console.log(error);
        });
    }
    useEffect(() =>{
      console.log('hhh',isLoading)  
    })
    
    return (
        <>
            <div style={{ position: 'relative'}}>
            {
                !isLoading ?
                        <>
                            <Row style={{ padding: 25, width: '100%' }}>
                                <div style={{ marginLeft: 10, display: 'flex', alignItems: 'center', color: 'gray', cursor: 'pointer' }} onClick={handleBack}>
                                    <IoIosArrowBack /><div style={{ marginLeft : 10 }} >동영상 목록보기</div>
                                </div>
                            </Row>

                            <Row>
                                <div className="col-xl-6 col-12 editorPopupGrid"  style={{ padding: 0 }}>
                                    <EditList setEditorModalOpen={setEditorModalOpen} setIsLoading={setIsLoading} setEditProjIdx={setEditProjIdx} uuid={uuid} tk={userToken} ogc={organizationData.value} />
                                </div>
                                <div className="col-xl-6 col-12 editorPopupGrid" style={{ padding: 0 }}>
                                    {
                                        userVSCdn && userThumbnailCDN ?
                                            <Player type="popup" uuid={uuid} VSCdn={userVSCdn}/>
                                            :   null
                                    }
                                </div>
                            </Row>
                        </>
                    :
                        <Loading />
            }

            </div>

            <Modal className="editModal" show={editorModalOpen} onHide={() => { setEditorModalOpen(false); setEditToolIdx(-1); setIsLoading(false); } }>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="editModalBody" style={{ position: "relative", overflowY: "auto", paddingBottom: 30 }}>
                    <Row style={{ margin: "20px 0px", display: 'flex', justifyContent: 'right', alignItems : 'center' }}>
                        <Button variant="primary" style={{ width: 85, height: 30, marginRight: 15 }} onClick={handlePublish} >영상 생성</Button>
                    </Row>
                    <Row>
                        <div className="col-xl-6 col-12 editorModalGrid"  style={{ padding: 0  }}>
                            <EditTools type="studio" uuid={uuid} tk={userToken} ogc={organizationData.value} />
                        </div>
                        <div className="col-xl-6 col-12 editorModalGrid" style={{ padding: 0 }}>
                            {/* {
                                userVSCdn && userThumbnailCDN ?
                                    <Player type="popup" uuid={uuid} VSCdn={userVSCdn}/>
                                    :   null
                            } */}
                        </div>
                    </Row>

                    <Row>
                        <Thumbnail />
                        {/* <Test /> */}
                    </Row>
                </Modal.Body>
            </Modal>

        </>
    );
}

export default EditComp;
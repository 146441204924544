import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx/xlsx.mjs';
import moment from "moment";
import { Table, Button, Row } from 'react-bootstrap';
import { Wave } from 'better-react-spinkit';

import Loading from '../../_commons/Loading';

function VideoTable({groupedData}) {

    const [sheetData, setSheetData] = useState({});

    useEffect(() => {
        var tmpArr =[];
        if (groupedData.length > 0) {
            groupedData.map((el) => {
                var tmpObj = {
                    "일시" : el.date,
                    "업로드 영상 수" : el.data.vodCount,
                    "원본 영상 크기 (GB)" : el.data.oriSize,
                    "변환 수" : el.data.succeedCount,
                    "변환된 용량 (GB)" : el.data.convertedSize
                }

                tmpArr.push(tmpObj);
            })
        }
        setSheetData(tmpArr);
    },[groupedData]);

    function downloadXlsx() {
        const today = moment();
        const formattedDate = today.format('YYYYMMDD');

        const excelHandler = {
          getExcelFileName: () => {
            return formattedDate + ".xlsx";
          },
          getExcelData: () => {
            return sheetData;
          },
          getWorksheet: () => {
            return XLSX.utils.json_to_sheet(excelHandler.getExcelData());
          },
        };
      
        const datas = excelHandler.getWorksheet();
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, datas);
        XLSX.writeFile(workbook, excelHandler.getExcelFileName());
      };

    return (
        <div className="col-12" style={{ marginBottom: 30 }}>
            <div className="dashboardBox">
                <div className="dashboardBoxContent" style={{ padding: 30 }}>
                    <Row style={{ width: '100%', marginBottom: 20 }}>
                        <div className='col-10'></div>
                        <div className='col-2' style={{ display: 'flex' }}>
                            <Button
                                variant="success" 
                                style={{ margin: 'auto', width: 105, height: 40, fontWeight: 400, fontSize: 14  }}
                                onClick={() =>
                                downloadXlsx()
                                    }
                            > 
                                엑셀 다운로드
                            </Button>
                        </div>
                    </Row>
                    <Table className="subtitleListTable" style={{ width: '100%', height: 350, fontWeight: 400, marginBottom: 0 }}>
                        <thead>
                        <tr>
                            <th scope="col">일시</th>
                            <th scope="col">업로드 영상 수</th>
                            <th scope="col">원본 영상 크기</th>
                            <th scope="col">변환 수</th>
                            <th scope="col">변환된 용량</th>
                        </tr>
                        </thead>
                        { 
                            groupedData && groupedData.length > 0 ? 
                                <tbody style={{ width: '100%', height: 150 }} >                         
                                {
                                    groupedData && groupedData.map((el, idx) => {
                                        return (
                                            <tr key={idx}>
                                            <td>
                                                    { el.date }
                                                </td> 
                                                <td>
                                                    { el.data.vodCount }
                                                </td> 
                                                <td>
                                                    { el.data.oriSize } GB
                                                </td> 
                                                <td>
                                                    { el.data.succeedCount }
                                                </td> 
                                                <td>
                                                    { el.data.convertedSize } GB
                                                </td> 
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            :
                                <div style={{ position: 'absolute', top: "60%", left: "calc(50% - 17px)" }}>
                                    <div>
                                        <Wave size={35} color='#ccc' />
                                    </div>

                                </div>
                            }
                    </Table>


                </div>
            </div>
        </div>
    );
}

export default VideoTable;
import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import axios from 'axios';
import Switch from "react-switch";
import { setStatusSubtitleLang } from '../../modules/status';

const ChapToggleSwitch = ({ langState }) => {
console.log(langState)
    var tmpChapState = langState;
    const [checked, setChecked] = useState(langState.state === 1 ? true : false);
    
    const userToken = useSelector(state => state.user.userToken);
    const organizationData = useSelector(state => state.organization.organizationData);

    const handleChange = nextChecked => {
        setChecked(nextChecked);
        tmpChapState.state = nextChecked === true ? 1 : 0;
        changeState(tmpChapState)
    };

    function changeState (stateObj) {
        var data = JSON.stringify({
          'tk' : userToken,
          'uuid' :  stateObj.uuid,
          'ogc' : organizationData.value,
          'type' : stateObj.lang,
          'state' : stateObj.state
        });

        var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION +'/chapter/setState',
            headers: { 
                'Content-Type': 'application/json'
            },
            data : data
        };

          axios(config)
          .then( (response) => {
            if(response.data.code==100){
              console.log('success')
            }
          })
          .catch((error) => {
              console.log(error);
          });


      };


    return (
          <Switch
            onChange={handleChange}
            checked={checked}
          />
    );
  };
  

  export default ChapToggleSwitch;
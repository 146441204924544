import React, { useState, useEffect }  from 'react';

import axios from 'axios';
import webvtt from "node-webvtt";
import moment from 'moment';
import { Modal, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { toast, Flip } from 'react-toastify';
import {  ChasingDots } from 'better-react-spinkit';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import { FaMinus } from '@react-icons/all-files/fa/FaMinus';
import { VscError } from '@react-icons/all-files/vsc/VscError';
import { useLocation } from "react-router-dom";

import TimelineComp from './TimelineComp';
import MediaPlayer from '../../_commons/MediaPlayer';
import TimeInput from '../../_commons/TimeInput';
import TextInput from '../../_commons/TextInput';

import { 
    setSubtitleList
} from '../../../../modules/subtitle';

import { 
    setCardText,
    setTimelineText
} from '../../../../modules/status';
import '../../../../../resources/assets/styles/sass/VideoDetail.scss';

function EditSubModal( { uuid, type, editModalOpen, setEditModalOpen, editLang, editKoLabel }) {
    
    const dispatch = useDispatch();

    const PopupUserToken = useSelector(state => state.user.PopupUserToken);
    const popupOrganizationData = useSelector(state => state.organization.popupOrganizationData);
    const videoDurationTime = useSelector(state => state.media.videoDurationTime);
    const subtitleList = useSelector(state => state.subtitle.subtitleList);
    const currentIndex = useSelector(state => state.status.currentIndex);
    const userToken = useSelector(state => state.user.userToken);
    const PopupUserThumbnailCDN = useSelector(state => state.user.PopupUserThumbnailCDN);
    const PopupUserVSCdn = useSelector(state => state.user.PopupUserVSCdn);
    const userThumbnailCDN = useSelector(state => state.user.userThumbnailCDN);
    const userVSCdn = useSelector(state => state.user.userVSCdn);
    const organizationData = useSelector(state => state.organization.organizationData);
    const vodInfo = useSelector(state => state.media.vodInfo);
    const popupvodInfo = useSelector(state => state.media.popupvodInfo);

    var newElement = [
        {
            id : 0,
            group : 1,
            start : "1970-01-01 00:00:00.000",
            end: "1970-01-01 00:00:01.000", 
            content: ""
        }           
    ]

    const [isFetched, setIsFetched] = useState(false);
    const [parsedSub, setParsedSub] = useState(newElement);


    //모달 닫힐 때 play멈추기, 재생 되었던 시간도 0으로 초기화

    useEffect(() => {

        if (editModalOpen) { //모달 열린 다음에 데이터 패치

            dispatch(setCardText({}));

            if (type == "pop") {

                var data = JSON.stringify({
                    "tk" : PopupUserToken,
                    "uuid" : uuid,
                    "ogc" : popupOrganizationData.organization_code,
                    "lang" : editLang
                });
          
                var config = {
                    method: 'post',
                    url: API_URL + '/' + API_VERSION + '/subtitle/getSubtitle',
                    headers: { 
                        'Content-Type': 'application/json'
                        },
                    data : data            
                };
                    
                axios(config)
                .then( (response) => {
                    console.log('response',response)
                    if (response.data.data && response.data.data.length > 0) {
    
                        const webvttString = response.data.data;
                        const parsed = webvtt.parse(webvttString);
                        
                        if (parsed.cues.length > 0 ){
                            var tmpParsed = parsed.cues.map(cue => Object.assign({
                                id: Number(cue.identifier),
                                start: '1970-01-01 ' + moment('1970-01-01 00:00:00.000').add(cue.start,'seconds').format('HH:mm:ss.SSS'),
                                end: '1970-01-01 ' + moment('1970-01-01 00:00:00.000').add(cue.end,'seconds').format('HH:mm:ss.SSS'),
                                group: 1,
                                content: cue.text
                            })
                            )
    
                            setParsedSub(tmpParsed);
                            dispatch(setSubtitleList(tmpParsed));
                        }
                        else {
                            setParsedSub(newElement);
                            dispatch(setSubtitleList(newElement));
                        }
                    } else {
                        setParsedSub(newElement);
                        dispatch(setSubtitleList(newElement));
                    }
                    setIsFetched(true);
                })
                .catch((error) => {
                    console.log('error',error)
                });
            }
            else {
                var data = JSON.stringify({
                    "tk" : userToken,
                    "uuid" : uuid,
                    "ogc" : organizationData.value,
                    "lang" : editLang
                });
          
                var config = {
                    method: 'post',
                    url: API_URL + '/' + API_VERSION + '/subtitle/getSubtitle',
                    headers: { 
                        'Content-Type': 'application/json'
                        },
                    data : data            
                };
                    
                axios(config)
                .then( (response) => {
                    if (response.data.data && response.data.data.length > 0) {
    
                        const webvttString = response.data.data;
                        const parsed = webvtt.parse(webvttString);
                        
                        if (parsed.cues.length > 0 ){
                            var tmpParsed = parsed.cues.map(cue => Object.assign({
                                id: Number(cue.identifier),
                                start: '1970-01-01 ' + moment('1970-01-01 00:00:00.000').add(cue.start,'seconds').format('HH:mm:ss.SSS'),
                                end: '1970-01-01 ' + moment('1970-01-01 00:00:00.000').add(cue.end,'seconds').format('HH:mm:ss.SSS'),
                                group: 1,
                                content: cue.text
                            })
                            )
    
                            setParsedSub(tmpParsed);
                            dispatch(setSubtitleList(tmpParsed));
                        }
                        else {
                            setParsedSub(newElement);
                            dispatch(setSubtitleList(newElement));
                        }
                    } else {
                        setParsedSub(newElement);
                        dispatch(setSubtitleList(newElement));
                    }
                    setIsFetched(true);
                })
                .catch((error) => {
                    console.log('error',error)
                });
            }
        }
    },[editModalOpen])

    useEffect(() => {
        setParsedSub(subtitleList);
    },[subtitleList])

    function addSubtitleBlock(idx){

        var tmpParsedSubtitle = parsedSub;
        var endTime = moment('1970-01-01 00:00:00.000').add(videoDurationTime,'seconds').format('HH:mm:ss.SSS');
        var arr = [];
        tmpParsedSubtitle.map((el) => {
            arr.push(Number(el.id))
        })
        var maxId = Math.max(...arr);
        var newElement = {};
    
        if (idx == tmpParsedSubtitle.length -1 ) { //맨 끝에 추가
            if ( moment(tmpParsedSubtitle[idx].end).add(2,'seconds').isAfter(moment("1970-01-01 " + endTime))) {
                if ( moment(tmpParsedSubtitle[idx].end).isAfter(moment("1970-01-01 " + endTime).subtract(1,'seconds').format("YYYY-MM-DD HH:mm:ss.SSS"))) {
                    newElement = {
                        'id' : maxId+1,
                        'group' : 1,
                        'start' : tmpParsedSubtitle[idx].end,
                        'end' : "1970-01-01 " + endTime,
                        'content' : ""
                    }
                } 
                else {
                    newElement = {
                        'id' : maxId+1,
                        'group' : 1,
                        'start' : moment("1970-01-01 " + endTime).subtract(1,'seconds').format("YYYY-MM-DD HH:mm:ss.SSS"),
                        'end' : "1970-01-01 " + endTime,
                        'content' : ""
                    }
                }
            } // end값이 duration값은 넘지 않도록
            else {
                newElement = {
                    'id' : maxId+1,
                    'group' : 1,
                    'start' : moment(tmpParsedSubtitle[idx].end).add(1,'seconds').format("YYYY-MM-DD HH:mm:ss.SSS"),
                    'end' : moment(tmpParsedSubtitle[idx].end).add(2,'seconds').format("YYYY-MM-DD HH:mm:ss.SSS"),
                    'content' : ""
                }
            }
        }
        else {
            newElement = { //중간에 추가
                'id' : maxId+1,
                'group' : 1,
                'start' : moment(tmpParsedSubtitle[idx].end).add(20,'milliseconds').format("YYYY-MM-DD HH:mm:ss.SSS"),
                'end' : moment(tmpParsedSubtitle[idx].end).add(40,'milliseconds').format("YYYY-MM-DD HH:mm:ss.SSS"),
                'content' : ""
            }
        }
    
        tmpParsedSubtitle.splice(idx+1, 0, newElement);
        setParsedSub([...tmpParsedSubtitle]);
        dispatch(setSubtitleList([...tmpParsedSubtitle]));
    
        fetchText();
    }

    function delSubtitleBlock(idx){
    
        console.log("del idx : ", idx)
    
        var tmpParsedSubtitle = parsedSub;
        
        if (idx == 0) {
            toast.error((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div>자막 완전 삭제를 하기 위해서는 <br />모달창을 닫고 삭제하려는 언어의 '삭제' 버튼을 누르세요 </div></div>),{
                type: toast.TYPE.ERROR,
                autoClose: 1000,
                className: 'rotateY animated',
                position: "top-center",
                transition: Flip
            });
        }
        else {
            tmpParsedSubtitle.splice(idx, 1);
            setParsedSub([...tmpParsedSubtitle]);
            dispatch(setSubtitleList([...tmpParsedSubtitle]));  
    
            fetchText();
        }

        dispatch(setTimelineText({}));
    }

    function fetchText() {

        if (type=="pop") {
            
            var tmpParsed = parsedSub.map(list => Object.assign({
                identifier: String(list.id + 1),
                start: moment.duration(moment(list.start).diff(moment('1970-01-01 00:00:00.000'))).asSeconds(),
                end: moment.duration(moment(list.end).diff(moment('1970-01-01 00:00:00.000'))).asSeconds(),
                text: list.content,
                styles: ""
            })
            )

            var data = JSON.stringify({
                "tk" : PopupUserToken,
                "uuid" : uuid ,
                "ogc" : popupOrganizationData.organization_code,
                "lang" : editLang,
                "subtitle" : tmpParsed
            });

            var config = {
                method: 'post',
                url: API_URL + '/' + API_VERSION + '/subtitle/aUpdate',
                headers: { 
                    'Content-Type': 'application/json'
                    },
                data : data            
            };
                
            axios(config)
            .then( (response) => {
                var data = response.data.data;
            })
            .catch((error) => {
                console.log(error);
            });
        }
        else {
            var tmpParsed = parsedSub.map(list => Object.assign({
                identifier: String(list.id + 1),
                start: moment.duration(moment(list.start).diff(moment('1970-01-01 00:00:00.000'))).asSeconds(),
                end: moment.duration(moment(list.end).diff(moment('1970-01-01 00:00:00.000'))).asSeconds(),
                text: list.content,
                styles: ""
            })
            )
    
            var data = JSON.stringify({
                "tk" : userToken,
                "uuid" : uuid,
                "ogc" : organizationData.value,
                "lang" : editLang,
                "subtitle" : tmpParsed
            });
    
            var config = {
                method: 'post',
                url: API_URL + '/' + API_VERSION + '/subtitle/aUpdate',
                headers: { 
                    'Content-Type': 'application/json'
                    },
                data : data            
            };
                
            axios(config)
            .then( (response) => {
                var data = response.data.data;
                console.log('edit data',data)
                //var langData = data.subtitle;
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }

    return (
        <Modal className="editModal" show={editModalOpen} onHide={() => setEditModalOpen(false) }>
            <Modal.Header closeButton>
                <Modal.Title>자막 편집</Modal.Title>
            </Modal.Header>
            <Modal.Body className='subtitleModalBody'>
                <div className='subtitleModalTitle'>
                    <div>
                        { type == 'pop'? popupvodInfo.realFileName : vodInfo.realFileName } / { editKoLabel } 
                    </div>
                </div>
                <div className='subtitleModalContainer'>
                {
                    isFetched && parsedSub.length > 0 ?
                        <>
                            <Row className="subtitleModalContent">
                                <div className="col-6 subListDiv">
                                    {
                                        parsedSub.map((el,idx) => (
                                            <Row key={idx} className={ idx == currentIndex ? "popupModalSubList highlight" : "popupModalSubList"}>
                                                <div className="col-1" style={{ 'lineHeight': '80px', 'fontWeight': 'bold' }}>{idx >= 10 ? idx : '0'+idx }</div>
                                                <div className="col-3" style={{ 'display': 'flex', 'alignItems': 'center' }}>
                                                    {
                                                        type=="pop" ?
                                                            <TimeInput uuid={uuid} idx={idx} lang={editLang} start={el.start} end={el.end} el={el} setParsedSub={setParsedSub} tk={PopupUserToken} ogc={popupOrganizationData.organization_code} />
                                                        :
                                                            <TimeInput uuid={uuid} idx={idx} lang={editLang} start={el.start} end={el.end} el={el} setParsedSub={setParsedSub} tk={userToken} ogc={organizationData.value} />
                                                    }
                                                </div>
                                                <div className='col-7' style={{ display: 'flex', alignItems: 'center' }}>
                                                    {
                                                        type == "pop" ?
                                                            <TextInput uuid={uuid} idx={idx} lang={editLang} text={el.content} el={el} setParsedSub={setParsedSub} tk={PopupUserToken} ogc={popupOrganizationData.organization_code}/>
                                                        :
                                                            <TextInput uuid={uuid} idx={idx} lang={editLang} text={el.content} el={el} setParsedSub={setParsedSub} tk={userToken} ogc={organizationData.value} />
                                                    }
                                                </div>
                                                <div className='col-1 btns' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                    { idx != 0 ?
                                                        <div className='btn-del' style={{ marginBottom: 10 }} onClick={()=>delSubtitleBlock(idx)}>
                                                            <FaMinus size="17" color="gray" />
                                                        </div>    
                                                        : null      
                                                    } 
                                                    <div className='btn-add' onClick={()=>addSubtitleBlock(idx)}>
                                                        <FaPlus size="17" color="gray" />
                                                    </div>
                                                </div>
                                            </Row>
                                        ))
                                    }
                                    <div className='modalAddListBtn' onClick={()=>addSubtitleBlock(subtitleList.length-1)} style={{ border: 0 }}><FaPlus size="22" color="gray" /></div>
                                </div>  
                                <div className="col-6">
                                    <Row>
                                        <div style={{ width: '100%', border: '1px solid #ddd', borderRadius: 10 }}>
                                            {
                                                type=="pop" ?
                                                    <MediaPlayer uuid={uuid} lang={editLang} koLabel={editKoLabel} VSCdn={PopupUserVSCdn} cdn={PopupUserThumbnailCDN}></MediaPlayer>
                                                :
                                                    <MediaPlayer uuid={uuid} lang={editLang} koLabel={editKoLabel} VSCdn={userVSCdn} cdn={userThumbnailCDN}></MediaPlayer>
                                            }
                                        
                                        </div>
                                    </Row>
                                </div> 
                            </Row>
                            <Row className="subtitleModalFooter">
                                {
                                    type=="pop" ?
                                        <TimelineComp uuid={uuid} lang={editLang} tk={PopupUserToken} ogc={popupOrganizationData.organization_code} />
                                    :
                                        <TimelineComp uuid={uuid} lang={editLang} tk={userToken} ogc={organizationData.value} />
                                }
                            </Row>
                        </>
                        :
                        <div style= {{ position: 'fixed', top: '50%', left: '50%' }}>
                            <ChasingDots size={35} color='#23adad'/>
                        </div>
                }
                </div>
                {/* <Row className="subtitleModalFooter">
                    {
                        type=="pop" ?
                            <TimelineComp uuid={uuid} lang={editLang} tk={PopupUserToken} ogc={popupOrganizationData.organization_code} />
                        :
                            <TimelineComp uuid={uuid} lang={editLang} tk={userToken} ogc={organizationData.value} />
                    }
                </Row> */}
            </Modal.Body>
        </Modal>
    );
}

export default EditSubModal;
import React, { useState, useEffect, useRef } from "react";

import Timeline from "react-vis-timeline";
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';

const now = dayjs('1970-01-01 00:00:00.000');

function VisTimeline( { playState, playTime, parsedSub, setTimelineTime } ) {
  const timelineRef = useRef(null);

  const durationTime = useSelector(state => state.aiQuiz.durationTime);
  const currentSub = useSelector(state => state.aiQuiz.currentSub);

  useEffect(() => {
    const nextSibling = document.querySelector('.vis-text.vis-minor.vis-even');

    if (nextSibling) {
        nextSibling.style.display = 'flex';
    } else {
        console.error('다음 형제 요소가 없습니다.');
    }

  },[])

  // 타임라인 드래그해서 시간 이동시키면 비디오 컴포넌트의 시간도 변경 (타임라인 -> 비디오)
  useEffect(() => {

    if (durationTime) {
      var start = now.clone().subtract(1000*20,'milliseconds').format('YYYY-MM-DD HH:mm:ss.SSS');
      var end = now.clone().add(1000*20,'milliseconds').format('YYYY-MM-DD HH:mm:ss.SSS');
      timelineRef.current.timeline.setWindow(start, end, { animation: false });

      timelineRef.current.timeline
        .on("rangechange", function (properties) {
          var ct = new Date((new Date(properties['start']).getTime() + new Date(properties['end']).getTime()) / 2);
          if (dayjs(ct).isBefore(dayjs('1970-01-01 00:00:00.000'))) {
            timelineRef.current.timeline.setWindow(start, end, { animation: false });
            setTimelineTime(0);
          }
  
          var maxDate = dayjs('1970-01-01 00:00:00.000').add(durationTime, 'seconds');
          if (dayjs(ct).isAfter(maxDate)) {
            var maxDateStart = maxDate.clone().subtract(1000*20,'milliseconds').format('YYYY-MM-DD HH:mm:ss.SSS');
            var maxDateEnd = maxDate.clone().add(1000*20,'milliseconds').format('YYYY-MM-DD HH:mm:ss.SSS');
            timelineRef.current.timeline.setWindow(maxDateStart, maxDateEnd, { animation: false });
            setTimelineTime(durationTime);
          }
       })
        .on("rangechanged", function (properties) {
  
          var ct = new Date((new Date(properties['start']).getTime() + new Date(properties['end']).getTime()) / 2);
            if (properties['byUser']) {
              setTimelineTime(dayjs(ct).diff(dayjs('1970-01-01 00:00:00.000'), 'millisecond')/1000);
            }
        })
    }
  }, [durationTime]);


  useEffect(() => {
    if (playTime) {
      var maxDateStart = dayjs(playTime).clone().subtract(1000*20,'milliseconds').format('YYYY-MM-DD HH:mm:ss.SSS');
      var maxDateEnd = dayjs(playTime).clone().add(1000*20,'milliseconds').format('YYYY-MM-DD HH:mm:ss.SSS');
      timelineRef.current.timeline.setWindow(maxDateStart, maxDateEnd, { animation: false });
    }
  },[playTime])

  useEffect(() => {
    if (parsedSub.length > 0) {
      const initGroups = [{ id: 1, content: "" }];
      
      timelineRef.current.timeline.setGroups(initGroups);
      timelineRef.current.timeline.itemsData.clear();
      timelineRef.current.timeline.itemsData.add(parsedSub);

      timelineRef.current.timeline
      .on("select", function (items, event) {
        var item = items['items'];
        parsedSub.map((el, idx) => {
          if (el.id == item[0]) {
              setTimelineTime(dayjs(el.start).diff(dayjs('1970-01-01 00:00:00.000'), 'millisecond')/1000)
              var maxDateStart = dayjs(el.start).clone().subtract(1000*20,'milliseconds').format('YYYY-MM-DD HH:mm:ss.SSS');
              var maxDateEnd = dayjs(el.start).clone().add(1000*20,'milliseconds').format('YYYY-MM-DD HH:mm:ss.SSS');
              timelineRef.current.timeline.setWindow(maxDateStart, maxDateEnd, { animation: {duration: 500, easingFunction: 'linear' } });
          }
        })
      })

    }
  },[parsedSub])

  useEffect(() => {
    //비디오 시간에 맞춰 타임라인 이동 (비디오 -> 타임라인)

    if (playState && playTime) {
        timelineRef.current.timeline.moveTo(dayjs(playTime).add(500,'milliseconds').format('YYYY-MM-DD HH:mm:ss.SSS'));
    }
  }, [playState, playTime]);

  useEffect(() => {
    //  선택된 자막 시간에 맞춰 타임라인 이동 (자막 -> 타임라인)
    console.log('currentSub',currentSub)
    if (Object.keys(currentSub).length > 0) {
      console.log('currentSub',currentSub)
        timelineRef.current.timeline.moveTo(currentSub.start);
    }
  }, [currentSub]);
  

  const options = {
    height: '150px',
    showMajorLabels: false,
    orientation: "top",
    // zoomMin: 100,
    // zoomMax: 1000 * 100,
    horizontalScroll: true, 
    verticalScroll: false,
    zoomKey: 'ctrlKey',
    min: dayjs('1970-01-01 00:00:00.000').subtract(durationTime - 20, 'seconds').format('YYYY-MM-DD HH:mm:ss.SSS'),
    max: dayjs('1970-01-01 00:00:00.000').add(durationTime + 20, 'seconds').format('YYYY-MM-DD HH:mm:ss.SSS'),
    zoomable: false,
    stack: false,
    type: "range",
    margin: {
      axis: 65,
      item: {
        vertical: 20,
        horizontal: 0,
      }
    },
    timeAxis: {
      scale: 'millisecond',
      step: 1000
    },
    format: {
      minorLabels: function (date, scale, step) {
          if (dayjs(date).isBefore(now)) {
            return "";
          }
          else if (dayjs(date).isAfter(now.clone().add(durationTime, 'seconds'))) {
            return "";
          }
          else {
            if (scale == "millisecond" && step == 1000)

              return "<div><div>"+dayjs(date).format('mm:ss')+"</div><div><div></div><div></div><div></div><div></div><div></div></div></div>";
          }
        }
    },
    template: (item, element, data) => {
      return item.content;
    },
  };

  return (
        <div className="aiQuiz TimelineBox" id="box">
            <Timeline options={options} ref={timelineRef} />

            <div className="borderBox" id="box">
              <div className="track"></div>
              <div className="arrow"></div>
            </div>
        </div>
  );
}




export default VisTimeline;


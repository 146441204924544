import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { toast, Flip } from 'react-toastify';

import { FaAngleDown } from '@react-icons/all-files/fa/FaAngleDown';
import { VscError } from '@react-icons/all-files/vsc/VscError';

import LangRow from './LangRow';

function ChapList({ videoInfo, index, chapListLoadingStatus, setChapListLoadingStatus, count, setCount }) {
    const [langRowOpen, setLangRowOpen] = useState(false);
    const [chapInfo, setChapInfo] = useState([]);
    const [list, setList] = useState([]);

    const userToken = useSelector(state => state.user.userToken);
    const organizationData = useSelector(state => state.organization.organizationData);
    const userThumbnailCDN = useSelector(state => state.user.userThumbnailCDN);

    useEffect(() => {
        setList(chapListLoadingStatus);
    },[]);

    useEffect(() => {

        var data = JSON.stringify({
            "tk" : userToken,
            "uuid" : videoInfo.uuid,
            "ogc" : organizationData.value,
            "type" : "ALL"
        });

        var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/chapter/getState',
            headers: { 
            'Content-Type': 'application/json'
            },
            data : data
        };
        
        axios(config)
        .then( (response) => {
            if(response.data.code==100){
                var tmpChapState = [];
                var filteredtmpChapState = [];
                
                if (response.data.data.chapter.length > 0) {
                    response.data.data.chapter.map((el, index) => {
                        /*
                            언어 순서 조정 및 한국어 라벨 저장하기
                            0 ~ 5번으로 언어 순서별로 저장 후 filter로 빈 배열 없애기
                        */
                       var lang = el.lang;
                       //하이픈 비교 오류 방지
                       if (el.lang.indexOf('-') > 0) {
                            lang = el.lang.replace('-','')
                       }
                       
                        switch(lang) 
                        {
                            case "ko":
                                tmpChapState[0] = el;
                                tmpChapState[0].koLabel = "한글";
                            break;
                            case "en":
                                tmpChapState[1] = el;
                                tmpChapState[1].koLabel = "영어";
                            break;                        
                            case "ja":
                                tmpChapState[2] = el;
                                tmpChapState[2].koLabel = "일본어";
                            break;
                            case "zhCN":
                                tmpChapState[3] = el;
                                tmpChapState[3].koLabel = "중국어 - 간체";
                            break;    
                            case "zhTW":
                                tmpChapState[4] = el;
                                tmpChapState[4].koLabel = "중국어 - 번체";
                            break;  
                            case "vi":
                                tmpChapState[5] = el;
                                tmpChapState[5].koLabel = "베트남어";
                            break;      
                            case "id":
                                tmpChapState[8] = el;
                                tmpChapState[8].koLabel = "인도네시아어";
                            break;    
                            case "th":
                                tmpChapState[9] = el;
                                tmpChapState[9].koLabel = "태국어";
                            break;     
                            case "de":
                                tmpChapState[10] = el;
                                tmpChapState[10].koLabel = "독일어";
                            break;    
                            case "ru":
                                tmpChapState[11] = el;
                                tmpChapState[11].koLabel = "러시아어";
                            break;   
                            case "es":
                                tmpChapState[12] = el;
                                tmpChapState[12].koLabel = "스페인어";
                            break;    
                            case "it":
                                tmpChapState[13] = el;
                                tmpChapState[13].koLabel = "이탈리아어";
                            break;   
                            case "fr":
                                tmpChapState[14] = el;
                                tmpChapState[14].koLabel = "프랑스어";
                            break;      
                        }
                        filteredtmpChapState = tmpChapState.filter(function (el) {
                            return el != null;
                        });
                        
                    })
                }
                setChapInfo(filteredtmpChapState);
            }
        })
        .catch((error) => {
            console.log(error)
            toast.error((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 챕터 상태 가져오기에 오류가 생겼습니다! </div></div>),{
                type: toast.TYPE.ERROR,
                autoClose: 1000,
                position: "top-center",
                className: 'rotateY animated',
                transition: Flip
            });
        });
    },[videoInfo]) 

    useEffect(() => {
        var tmpStatus = count;
        setCount(tmpStatus + 1);


        console.log('tmpStatus',count + 1);
    },[])

    return (
        <>
            <tr>
                <td style={{ width: '30%', paddingLeft: 30  }}>
                    <div className="videoThumbnail">
                        <img src={ userThumbnailCDN ? `${userThumbnailCDN}/${videoInfo.uuid}/thumbnail/tn_1.png` : '' }></img>
                    </div>
                </td>
                <td style={{ width: '30%', textAlign: 'left' }}>
                    <div>{ videoInfo.realFileName }</div>
                    {/* <div style={{ marginTop: 15 }}>크집사 굿즈 똥고양이 해적단 가입!</div> */}
                </td>
                <td>
                { chapInfo.length > 0 ? 
                    <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }} onClick={()=> { langRowOpen ? setLangRowOpen(false) : setLangRowOpen(true) }} >                                          
                        <div className={ langRowOpen ? 'langRowIcon open' : 'langRowIcon' } >
                            <FaAngleDown size="23" color="gray" />
                        </div>
                        <div style={{ marginLeft: 5 }}>{chapInfo.length}</div> 
                    </div>
                    : null
                }
                </td>
                <td style={{ width: '30%' }}>
                    <div>{moment(videoInfo.createdAt).format("YYYY-MM-DD HH:mm:ss")}</div>
                </td>
            </tr>
            {langRowOpen ? 
                <LangRow chapInfo={chapInfo} videoInfo={videoInfo} /> : null
            }
        </>
    );
}

export default ChapList;

import React, {useEffect, useState, useRef} from "react";

import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { FaPlay} from '@react-icons/all-files/fa/FaPlay';
import { FaPause} from '@react-icons/all-files/fa/FaPause';
import { FaInfoCircle } from '@react-icons/all-files/fa/FaInfoCircle';
import { GrForwardTen } from '@react-icons/all-files/gr/GrForwardTen';
import { GrBackTen } from '@react-icons/all-files/gr/GrBackTen';

import videojs from "video.js";
import chaptersMore from "videojs-chapters-extension";
import hlsQualitySelector from "videojs-hls-quality-selector";
import '../../../../resources/assets/styles/sass/MediaPlayer.scss';
import "video.js/dist/video-js.css";
import "videojs-chapters-extension/dist/videojs-chapters-extension.css";
import "videojs-chapters-extension/src/css/fade.css";

videojs.registerPlugin('chaptersMore', chaptersMore);
videojs.registerPlugin('hlsQualitySelector', hlsQualitySelector);


import { 
  setVideoDurationTime,
  setPlayingTimeMediaPlayerToTimeline,
} from '../../../modules/media';

import { 
  setStatusVideoPlayState
} from '../../../modules/status';

export const MediaPlayer = ( { uuid, lang, koLabel, VSCdn, cdn } ) => { 

    const videoRef = useRef(null); 
    const playerRef = useRef(null);

    const [player, setPlayer] = useState(null);
    const dispatch = useDispatch();
    
    const videoDurationTime = useSelector(state => state.media.videoDurationTime);
    const MediaPlayerPlayingTime = useSelector(state => state.media.MediaPlayerPlayingTime);
    const TimelinePlayingTime = useSelector(state => state.media.TimelinePlayingTime);
    const videoPlayState = useSelector(state => state.status.videoPlayState);
    const subtitleList = useSelector(state => state.subtitle.subtitleList);


    // var videoJsOptions = {
    //   autoplay: false,
    //   playbackRates: [0.5, 1, 1.25, 1.5, 2],
    //   width: 720,
    //   height: 300,
    //   controls: true,
    //   sources: [
    //     {
    //       src: VSCdn + '/hls/' + uuid + '/mp4/' + uuid + '.mp4/index.m3u8',
    //       type: 'application/x-mpegURL',
    //     },
    //   ],
    //   plugins: {
    //     hlsQualitySelector: {
    //       displayCurrentQuality: true
    //     },
    //     chaptersMore: {
    //       src: cdn + '/' + uuid + '/chapter/' + lang + '/' + uuid + '-' + lang + '.json'
    //     }
    //   }
    // }

    useEffect(() => {

      // make sure Video.js player is only initialized once
      if (!playerRef.current) {
        
        const videoElement = videoRef.current;
        if (!videoElement) return;

        if (lang) {

          setPlayer( 
              playerRef.current = videojs(videoElement, {
                autoplay: false,
                playbackRates: [0.5, 1, 1.25, 1.5, 2],
                width: 720,
                height: 300,
                controls: true,
                sources: [
                  {
                    src: VSCdn + '/hls/' + uuid + '/mp4/' + uuid + '.mp4/index.m3u8',
                    type: 'application/x-mpegURL',
                  },
                ],
                plugins: {
                  hlsQualitySelector: {
                    displayCurrentQuality: true
                  },
                  chaptersMore: {
                    src: cdn + '/' + uuid + '/chapter/' + lang + '/' + uuid + '-' + lang + '.json'
                  }
                }
              }, 

              () => {
                  console.log("player is ready");
              })
          )
        }  
      } else {
        // you can update player here [update player through props]
        // const player = playerRef.current;
        // player.autoplay(options.autoplay);
        // player.src(options.sources);
      }

    }, []);
  
    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {

        if(player != null){
          
            player.on("loadedmetadata", () => {
                dispatch(setVideoDurationTime(player.duration()))
                player.addRemoteTextTrack({src: cdn + '/' + uuid + '/subtitle/' + lang + '/' + uuid + '-' + lang + '.vtt', label : koLabel, mode: "showing" }, true);
                console.log(cdn + '/' + uuid + '/subtitle/' + lang + '/' + uuid + '-' + lang + '.vtt')
            });
            player.on("timeupdate", () => {

              dispatch(setPlayingTimeMediaPlayerToTimeline(player.currentTime()));
              
              var time = '1970-01-01 ' + moment('1970-01-01 00:00:00.000').add(player.currentTime(),'seconds').format('HH:mm:ss.SSS');
              console.log('time',time)
              const currentIndex = subtitleList.findIndex( (item) => 
                  moment(item.start).isAfter(time) && moment(item.end).isBefore(time)
              );
              console.log('currentIndex',currentIndex)
              //dispatch(setCurrentIndex(currentIndex));
            });
            player.on("play", () => {
              console.log("IS Playing");
              dispatch(setStatusVideoPlayState(true));
            });
            player.on("pause", () => {
              console.log("IS STOPPED");
              dispatch(setStatusVideoPlayState(false));
            });

        } 
      }, [player]);    
                                                                                          
    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {
      return () => {
        if (playerRef.current) {
          playerRef.current.dispose();
        }
      };
      
    }, []);
    
    useEffect(() => {
      playerRef.current.currentTime(TimelinePlayingTime);
    },[TimelinePlayingTime]);

    const checkPlaying = () => {
      if (playerRef.current.paused()) {
        playerRef.current.play();
  
      } else {
        playerRef.current.pause();
      }
      dispatch(setStatusVideoPlayState(!videoPlayState));      
    };

    function reloadPlayer() {
      console.log('ee')
      playerRef.current.dispose();
      const playerContainer = document.querySelector(".videoContatiner");

      console.log(cdn + '/' + uuid + '/subtitle/' + lang + '/' + uuid + '-' + lang + '.vtt')
      console.log(cdn + '/' + uuid + '/chapter/' + lang + '/' + uuid + '-' + lang + '.json')

      const videoElement = document.createElement("video-js");
      videoElement.style.width = '100%';
      videoElement.style.height = '100%';
      videoElement.classList.add('vjs-big-play-centered');
      videoElement.classList.add('vjs-chapters-more');
      videoElement.setAttribute("id", "videoEl");
      playerContainer.append(videoElement);

      setPlayer( 
          playerRef.current = videojs(videoElement, {
            autoplay: false,
            playbackRates: [0.5, 1, 1.25, 1.5, 2],
            width: 720,
            height: 300,
            controls: true,
            sources: [
              {
                src: VSCdn + '/hls/' + uuid + '/mp4/' + uuid + '.mp4/index.m3u8',
                type: 'application/x-mpegURL',
              },
            ],
            plugins: {
              hlsQualitySelector: {
                displayCurrentQuality: true
              },
              chaptersMore: {
                src: cdn + '/' + uuid + '/chapter/' + lang + '/' + uuid + '-' + lang + '.json'
              }
            }
          }, 
          () => {
            console.log("player is ready");
          })
      )

      player.addRemoteTextTrack({src: cdn + '/' + uuid + '/subtitle/' + lang + '/' + uuid + '-' + lang + '.vtt', label : koLabel, mode: "showing" }, true);

      playerRef.current.currentTime(MediaPlayerPlayingTime)
      if (videoPlayState) {
        playerRef.current.play();
      } 
    }
    /*  썸네일 만들거면 */
    // const makeInitialThumbnails = async () => {
    //   const delta = 1 / 30;
    //   var thumbnails = await makeThumbnails(0, player.duration() - delta);
    //   dispatch(setThumbnails(thumbnails));
    // };
  
    // const makeThumbnails = (start: number, end: number) => {
    //   return new Promise<string[]>((resolve, reject) => {
    //     try {
    //       (async () => {
    //         const gap = (end - start) / (THUMBNAIL_COUNT - 1);
    //         let secs = end;
  
    //         const images: string[] = new Array(THUMBNAIL_COUNT);
  
    //         for (let count = THUMBNAIL_COUNT - 1; count > 0; count -= 1) {
    //           // dispatch(setCurrentTime(secs));
    //           const image: string = await getImageAt();
  
    //           secs -= gap;
    //           images[count] = image;
    //         }

    //         // dispatch(setCurrentTime(start));
    //         images[0] = await getImageAt();
    //         resolve(images);
    //       })();
    //     } catch (err) {
    //       reject(err);
    //     }
    //   });
    // };
    // const getImageAt = () => {
    //   return new Promise<string>(resolve => {
    //           const context = canvasRef.current.getContext('2d');
    //           videoRef.current && context ? context.drawImage(
    //             videoRef.current,
    //             0,
    //             0,
    //             canvasRef.current.width,
    //             canvasRef.current.height
    //           ) : null;
    //           resolve(canvasRef.current.toDataURL());
    //           }
    //     )
    // }

    return (
      <div>
        <Button className="subReloadBtn" variant="primary" onClick={reloadPlayer}>새로고침</Button>
        <div className="videoContatiner">
          <div id="videoEl" data-vjs-player>
            {
              videoRef ?
                <video ref={videoRef} id="videoEl" className="video-js vjs-big-play-centered" style={{ height: '100%' }} >
                </video>
              :null
            }
          </div>
        </div>
        <div className="videoControlBox">
            <div className="row">
            
                <div className="iconDiv">
                  <div>
                    <button type="button" onClick={() => playerRef.current.currentTime(playerRef.current.currentTime() - 10)}>
                        <GrBackTen size="20" color="lightgray" />
                    </button>
                      {
                        videoPlayState == true ?
                          <button key="pause" className="play-button" onClick={ () => checkPlaying()} >
                                <FaPause size="30" color="gray" />
                          </button>
                          :
                          <button key="play" className="play-button  paused" onClick={ () => checkPlaying() } >
                                <FaPlay size="30" color="gray" />
                          </button>
                        }

                    <button type="button" onClick={() => playerRef.current.currentTime(playerRef.current.currentTime() + 10)}>
                        <GrForwardTen size="20"  color="lightgray" />
                    </button> 

                    <button type="button">
                        <FaInfoCircle size="20" color="gray" />
                    </button>    

                    <div style={{ marginLeft: 20 }}>
                      <div className="playTime"> 
                          <div className="row value">
                              { moment('1970-01-01 00:00:00.000').add(MediaPlayerPlayingTime,'seconds').format('HH:mm:ss.SSS') } /
                          </div>
                      </div> 
                    
                      <div className="durationTime" style={{ color: 'gray' }}> 
                          <div className="row value">
                              {moment('1970-01-01 00:00:00.000').add(videoDurationTime,'seconds').format('HH:mm:ss.SSS')}
                          </div>
                      </div> 

                    </div>
                  </div>  

                  </div>
            </div>
        </div>
      </div>

    );


}

export default MediaPlayer;
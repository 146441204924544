import React,{ useEffect, useState } from 'react';

import axios from 'axios';
import { Row, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { setProjectData } from '../../../modules/editor';

function CutRow({ idx, el, cutList, setCutList, uuid, tk, ogc }) {

    const projectInfo = useSelector(state => state.editor.projectInfo);
    const editProjIdx = useSelector(state => state.editor.editProjIdx);
    const projectData = useSelector(state => state.editor.projectData);

    const [changedObj, setchangedObj] = useState({});

    const dispatch = useDispatch();  

    function handleChange(idx, type, text) {

        var tmpArr = cutList[idx];

        if (type =='from') {
            setchangedObj({
                ...tmpArr,
                    'from' : text
            });
        }
        else if (type =='to') {
            setchangedObj({
                ...tmpArr,
                    'from' : text
            });
        }
    }

    function fetchText() {

        var tmpCardList = [...cutList];
        
        if ( Object.keys(changedObj).length > 0 ) {
            tmpCardList[idx] = changedObj;
            setCutList(tmpCardList);
        }

        var tmpProjectData = [...projectData];
        if (projectData.length > 0) {
            tmpProjectData = projectData.filter((el) => el.action != "cut");
        }

        tmpProjectData.push({
            "action" : "cut",
            "data" : tmpCardList
        });

        dispatch(setProjectData(tmpProjectData));

        var data = JSON.stringify({
            "tk" : tk,
            "uuid" : uuid,
            "ogc" : ogc,
            "project_id": editProjIdx,
            "projectInfo" : projectInfo,
            "projectData" : tmpProjectData
        });

        var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/editor/update',
            headers: { 
                'Content-Type': 'application/json'
                },
            data : data            
        };
            
        axios(config)
        .then( (response) => {
            console.log(response.data.data);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    return (
        <Row style={{ display: 'flex' }}  onBlur={e => fetchText(e)} >
            <div  
                className='editor editable-text'
                contentEditable={true}
                suppressContentEditableWarning={true}
                onInput={(e) => handleChange(idx, 'from', e.currentTarget.textContent)}
            >
                {el.from}
            </div>
            <div> - </div>
            <div  
                className='editor editable-text'
                contentEditable={true}
                suppressContentEditableWarning={true}
                onInput={(e) => handleChange(idx, 'to', e.currentTarget.textContent)}
            >
                {el.to}
            </div>
        </Row>
    );
}

export default CutRow;
//import axios from 'axios';

//Actions
const PURGE = "organization/PURGE";

const SET_ORGANIZATION_LIST = "organization/SET_ORGANIZATION_LIST";
const SET_ORGANIZATION_DATA = "organization/SET_ORGANIZATION_DATA";
const SET_POPUP_ORGANIZATION_DATA = "organization/SET_POPUP_ORGANIZATION_DATA";

// 액션 생성 함수
export const purgeOrganiztion = () => ({ type: PURGE });

export const setOrganizationList = organizationList => ({ type: SET_ORGANIZATION_LIST, organizationList });
export const setOrganizationData = organizationData => ({ type: SET_ORGANIZATION_DATA, organizationData });
export const setPopupOrganizationData = popupOrganizationData => ({ type: SET_POPUP_ORGANIZATION_DATA, popupOrganizationData });

// 초깃값 (상태가 객체가 아니라 그냥 숫자여도 상관 없습니다.)
const initialState = {
  organizationList : [],
  organizationData : {},
  popupOrganizationData : {}
};

export default function organization(state = initialState, action) {
  switch (action.type) {
    case PURGE: // 받아왔던 기관 리스트는 남겨준다.
      return {
        ...state,
        organizationData : {},
        popupOrganizationData : {}
      }
    case SET_ORGANIZATION_LIST:
      return {
        ...state,
        organizationList : action.organizationList
      }
    case SET_ORGANIZATION_DATA:
      return {
        ...state,
        organizationData : action.organizationData
      }
    case SET_POPUP_ORGANIZATION_DATA:
      return {
        ...state,
        popupOrganizationData : action.popupOrganizationData
      }
    default:
        return state;
  }
} 
import React,{ useEffect, useState } from 'react';

import { Row, Button } from 'react-bootstrap';
import { IoTrashSharp } from '@react-icons/all-files/io5/IoTrashSharp';
import { AiOutlinePlus } from '@react-icons/all-files/ai/AiOutlinePlus';
import { useDispatch, useSelector } from 'react-redux';

import CutRow from './CutRow';
function cutMenu({ compType, uuid, tk, ogc }) {

    const projectData = useSelector(state => state.editor.projectData);

    const [cutList, setCutList] = useState([]);

    useEffect(() => {
        setCutList([]);

        if (projectData.length > 0) {
            projectData.map((el, idx) => {
                if (el.action == "cut") {
                    setCutList(el.data);
                }
            })
        }
    },[])

    function handleDelete(e, idx) {

        const filteredList = cutList.filter((_, index) => index !== idx);
        setCutList(filteredList);
    }

    function addCard() {

        var tmpList = cutList;
        tmpList.push(
            {
                'from': '00:00:000',
                'to' : '00:00:000'
            }
        );
        setCutList([...tmpList])
    }

    return (
        <div className='menuBox'>
            {
                cutList.map((el,idx) => {
                    return (
                        <Row key={`crop_${idx}`} className='menuBoxRow' >
                            <div className='col-3' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 20 }}>잘라낼 구간 {idx + 1}</div>
                            <div className='col-3'></div>
                            <div className='col-5 cropOptions'>
                                <CutRow idx={idx} el={el} cutList={cutList} setCutList={setCutList} uuid={uuid} tk={tk} ogc={ogc} />
                            </div>
                            <div className='col-1' style={{ cursor : 'pointer' }} onClick={(e) => handleDelete(e, idx)}>
                                <IoTrashSharp />
                            </div>
                        </Row>
                    )
                })
            }   
            <Row className="menuBoxRow plusBtn" style={{ borderBottom: 0 }} onClick={() => addCard()}><div className='plusBtnIcon'><AiOutlinePlus /> 새로운 구간 잘라내기 </div></Row>
        </div>
    );
}

export default cutMenu;
import React, { useEffect, useState } from 'react';

function TotalVideo({groupedData}) {

    const [vodCountSum, setVodCountSum] = useState(0);

    useEffect(() => {
        if (groupedData.length > 0) {
            const totalValue = groupedData.reduce((accumulator, currentValue) => {
                if (currentValue.data?.succeedCount !== undefined) {
                    return accumulator + currentValue.data.succeedCount;
                } else {
                // convertedSize 속성이 없을 경우, 현재 합계를 그대로 반환
                return accumulator;
                }
              }, 0);
    
           setVodCountSum(totalValue);
        }

    },[groupedData])

    return (
        <div className="col-6" style={{ marginBottom: 30 }}>
            <div className="dashboardBox" style={{ height: 300 }}>
                <div className="dashboardBoxTitle" style={{ fontWeight: 500 }}>
                    총 영상 수
                </div>
                <div className="dashboardBoxContent" style={{ paddingTop: 50 }}>
                    <div style={{ fontSize: 50, fontWeight: 500, textAlign: 'center' }}> 
                        <i>{ vodCountSum }</i>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TotalVideo;

import '../../resources/assets/styles/sass/Main.scss';


import React, { useEffect } from 'react';
import { Row } from 'react-bootstrap';

import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useHistory } from "react-router-dom";

import '../../resources/assets/styles/sass/Toast.scss';
import '../../resources/assets/styles/sass/Main.scss';

import Header from '../components/_commons/Header';
import SideBar from '../components/_commons/SideBar';
import VideoSideBar from '../components/_commons/VideoSideBar';
import DashBoard from '../components/main/DashBoard';

function Main(){
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
      console.log('forRedirect : ', window.location.pathname)
      window.location.replace(window.location.pathname + '#/main');
  },[])

  return (
    <>

        <Header></Header>

        <Row style={{ width: '100%'}}>
        {
            location.pathname.indexOf('/video/') > -1 ? <VideoSideBar />: <SideBar/>
        }
          <div className='dashboardArea'>
            <div className="wrapper">
              <DashBoard />
            </div>
          </div>
        </Row>
    </>
    
  );
}

export default Main; 
import React from 'react';

function LearningStatus(props) {
    return (
        <div className="col-4" style={{ marginBottom: 30 }}>
            <div className="dashboardBox">
                <div className="dashboardBoxTitle">
                    학습 현황 (활성 강좌 기준)
                </div>
                {/* <ReactApexChart options={optionsDonut} series={seriesDonut} width="90%" height={200}  type="donut"/> */}
            </div>
        </div>
    );
}

export default LearningStatus;
import React, { useState, useEffect, useRef } from 'react';

import { Table } from 'react-bootstrap';
import axios from 'axios';
import { toast, Slide, Flip } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { AiOutlinePlus } from '@react-icons/all-files/ai/AiOutlinePlus';
import { IoIosArrowForward } from '@react-icons/all-files/io/IoIosArrowForward';
import { IoIosArrowBack } from '@react-icons/all-files/io/IoIosArrowBack';
import { IoTrashSharp } from '@react-icons/all-files/io5/IoTrashSharp';
import { CgSpinner } from '@react-icons/all-files/cg/CgSpinner';
import { VscError } from '@react-icons/all-files/vsc/VscError';
import { VscCheck } from '@react-icons/all-files/vsc/VscCheck';
import { TiWarningOutline } from '@react-icons/all-files/ti/TiWarningOutline';
import { ChasingDots, Wave } from 'better-react-spinkit';
import { setProjectInfo, setProjectData, setEditProjList } from '../../modules/editor';

function EditList( { setEditorModalOpen, setIsLoading, setEditProjIdx, uuid, tk, ogc}) {

    const vodInfo = useSelector(state => state.media.vodInfo);
    const editProjList = useSelector(state => state.editor.editProjList);

    const [editProjectList, setEditProjectList] = useState([]);
    const [isFetched, setIsFetched] = useState(false);

    const dispatch = useDispatch();  
    const toastId = useRef();
    var flag = 0;
    
    useEffect(() => {
        if (editProjList) {
            var data = JSON.stringify({
                "tk" : tk,
                "uuid" : uuid,
                "ogc" : ogc,
            });
        
            var config = {
                method: 'post',
                url: API_URL + '/' + API_VERSION + '/editor/getList',
                headers: { 
                    'Content-Type': 'application/json'
                },
                data : data            
            };
                
            axios(config)
            .then( (response) => {
                if (response.data.code == 100) {
                    setEditProjectList(response.data.data);
                    setIsFetched(true);
                    dispatch(setEditProjList(response.data.data));
                }
                else {
                    toast.error((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 자막을 불러오지 못했습니다. </div></div>),{
                        type: toast.TYPE.ERROR,
                        style: { width: 330 },
                        autoClose: 1000,
                        position: "top-center",
                        className: 'rotateY animated',
                        transition: Flip
                    });
                }
            })
            .catch((error) => {
                toast.error((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 자막을 불러오지 못했습니다. </div></div>),{
                    type: toast.TYPE.ERROR,
                    style: { width: 330 },
                    autoClose: 1000,
                    position: "top-center",
                    className: 'rotateY animated',
                    transition: Flip
                });
            });
        }
 
    },[])

    function addProject() {
        if (editProjectList.length >= 5) {
            toast.warning((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 프로젝트는 최대 5개까지 지원합니다. </div></div>),{
                type: toast.TYPE.ERROR,
                autoClose: 1000,
                style: { width: 330 },
                position: "top-center",
                className: 'rotateY animated',
                transition: Flip
            });
        }
        else {
            toastId.current = toast.info((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><CgSpinner size="22" color="#fff" className="loaderIcon" /></div><div>프로젝트 생성 중입니다.</div></div>),{
                type: toast.TYPE.INFO,
                autoClose: 5000,
                style: { width: 330 },
                position: "top-center",
                className: 'rotateY animated',
                transition: Flip
            });

            var data = JSON.stringify({
                "tk" : tk,
                "uuid" : uuid,
                "ogc" : ogc
            });
        
            var config = {
                method: 'post',
                url: API_URL + '/' + API_VERSION + '/editor/create',
                headers: { 
                    'Content-Type': 'application/json'
                },
                data : data            
            };
                
            axios(config)
            .then( (response) => {
                console.log(response.data)
                if (response.data.code == 100) {
                    var tmpList = [...editProjectList];
                    tmpList.push({
                        id: response.data.data,
                        label: "new Project",
                        uuid: vodInfo.uuid,
                        state: 1
                    });
                    setEditProjectList(tmpList);
                    dispatch(setEditProjList(tmpList));
                    toast.update(toastId.current ,{
                        render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscCheck size="23" /> 프로젝트 생성 성공! </div></div>,
                        type: toast.TYPE.SUCCESS,
                        style: { width: 330 },
                        autoClose: 1000,
                        position: "top-center",
                        className: 'rotateY animated',
                        transition: Flip
                    });

                }
                else {
                    toast.update(toastId.current ,{
                        render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><TiWarningOutline size="22" color="#fff" /></div><div> 프로젝트 생성에 오류가 생겼습니다. </div></div>,
                        type: toast.TYPE.WARNING,
                        style: { width: 330 },
                        autoClose: 1000,
                        position: "top-center",
                        className: 'rotateY animated',
                        transition: Flip
                    });
                }
            })
            .catch((error) => {
                toast.update(toastId.current ,{
                    render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><TiWarningOutline size="22" color="#fff" /></div><div> 프로젝트 생성에 오류가 생겼습니다. </div></div>,
                    type: toast.TYPE.WARNING,
                    style: { width: 330 },
                    autoClose: 1000,
                    position: "top-center",
                    className: 'rotateY animated',
                    transition: Flip
                });
            });
        }
    }

    function deleteProject(idx) {

        toastId.current = toast.info((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><CgSpinner size="22" color="#fff" className="loaderIcon" /></div><div>프로젝트 삭제 중입니다.</div></div>),{
            type: toast.TYPE.INFO,
            style: { width: 330 },
            autoClose: 5000,
            position: "top-center",
            className: 'rotateY animated',
            transition: Flip
        });

        var data = JSON.stringify({
            "tk" : tk,
            "uuid" : uuid,
            "ogc" : ogc,
            "project_id" : editProjectList[idx].id
        });
    
        var config = {
            method: 'delete',
            url: API_URL + '/' + API_VERSION + '/editor/delete',
            headers: { 
                'Content-Type': 'application/json'
            },
            data : data            
        };
            
        axios(config)
        .then( (response) => {
            console.log(response.data)
            if (response.data === true) {
                var tmpList = [...editProjectList];
                tmpList.splice(idx, 1);
                setEditProjectList(tmpList);
                dispatch(setEditProjList(tmpList));

                toast.update(toastId.current ,{
                    render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscCheck size="23" /> 프로젝트 삭제 성공! </div></div>,
                    type: toast.TYPE.SUCCESS,
                    style: { width: 330 },
                    autoClose: 1000,
                    position: "top-center",
                    className: 'rotateY animated',
                    transition: Flip
                });

            }
            else {
                toast.update(toastId.current ,{
                    render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><TiWarningOutline size="22" color="#fff" /></div><div> 프로젝트 삭제에 오류가 생겼습니다. </div></div>,
                    type: toast.TYPE.WARNING,
                    style: { width: 330 },
                    autoClose: 1000,
                    position: "top-center",
                    className: 'rotateY animated',
                    transition: Flip
                });
            }
        })
        .catch((error) => {
            toast.update(toastId.current ,{
                render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><TiWarningOutline size="22" color="#fff" /></div><div> 프로젝트 삭제에 오류가 생겼습니다. </div></div>,
                type: toast.TYPE.WARNING,
                style: { width: 330 },
                autoClose: 1000,
                position: "top-center",
                className: 'rotateY animated',
                transition: Flip
            });
        });
    }

    function openProject(idx) {
        console.log('openproject')
        setIsLoading(true);

        var data = JSON.stringify({
            "tk" : tk,
            "uuid" : uuid,
            "ogc" : ogc,
            "project_id" : editProjectList[idx].id
        });
    
        var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/editor/getInfo',
            headers: { 
                'Content-Type': 'application/json'
            },
            data : data            
        };
            
        axios(config)
        .then( (response) => {
            setIsLoading(false);

            if (response.data.code == 100) {
                flag = 0;

                toast.update(toastId.current ,{
                    render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscCheck size="23" />프로젝트를 열었습니다!</div></div>,
                    type: toast.TYPE.SUCCESS,
                    style: { width: 330 },
                    autoClose: 1000,
                    position: "top-center",
                    className: 'rotateY animated',
                    transition: Flip
                });

                setEditorModalOpen(true);

                setEditProjIdx(editProjectList[idx].id);
                dispatch(setProjectInfo(response.data.projectInfo)); 

                // 빈 array 들어가는 것 방지
                if (Object.keys(response.data.projectData).length === 0) {
                    dispatch(setProjectData([]));
                }
                else {
                    dispatch(setProjectData(response.data.projectData));
                }
            }
            else {
                toast.error((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 자막 편집 목록을 불러오지 못했습니다. </div></div>),{
                    type: toast.TYPE.ERROR,
                    style: { width: 330 },
                    autoClose: 1000,
                    position: "top-center",
                    className: 'rotateY animated',
                    transition: Flip
                });
            }
        })
        .catch((error) => {
            toast.error((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div>자막 편집 목록을 불러오지 못했습니다. </div></div>),{
                type: toast.TYPE.ERROR,
                style: { width: 330 },
                autoClose: 1000,
                position: "top-center",
                className: 'rotateY animated',
                transition: Flip
            });
        });
    }

    return (
        <div className="wrapper">
                
            <div style={{ width: '100%' }}>
                {
                    isFetched ?
                        <>
                            <Table className="editProjectTable" style={{ marginBottom: 0 }}>
                                <thead>
                                    <tr>
                                        <th scope="col">프로젝트</th>
                                        <th scope="col">날짜</th>
                                        <th scope="col">삭제</th>
                                        <th scope="col">편집</th>
                                    </tr>
                                </thead>
                                <tbody>    
                                    {
                                        editProjectList.map((el,idx) => {
                                            return (
                                                <tr key={idx} className="editProjectList">
                                                    <td>{el.label}</td>
                                                    <td>2023.10.22</td>
                                                    <td onClick={() => deleteProject(idx)}  style={{ cursor: 'pointer' }}><IoTrashSharp /></td>
                                                    <td onClick={() => flag == 0 ? openProject(idx) : null } style={{ cursor: 'pointer' }}><IoIosArrowForward /></td>
                                                </tr>
                                            )
                                        })
                                    }  
                                </tbody>    
                            </Table>
                            {
                                isFetched && editProjectList.length < 5 ? 
                                    <div className="plusTr" style={{ width: '100%', height: 80 }}>
                                        <div className='plusBtnIcon' onClick={() => addProject()}><AiOutlinePlus /></div>
                                    </div>
                                    :
                                    null    
                            }
                        </>
                        : 
                        <div className='EditListSpinner' >
                            <Wave size={35} color='#23adad' />      
                        </div> 
                } 
            </div>

        </div>
    );
}

export default EditList;
import React, { useEffect } from 'react';

import { Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

function Calender({ startDate, endDate, setStartDate, setEndDate, formattedEndDate }) {

    useEffect(() => {
        console.log('startDate',startDate)
        console.log('endDate',endDate)
    },[])

    return (
        <>
            <div className='col-3'></div>
            <div className='col-6' >
                <Row>
                    <div className='col-5 calBox' >
                        <Row>
                            <div className='col-12'>
                                <Form.Group id="DateformGroup" controlId="doj">
                                    <Form.Control 
                                        type="date" 
                                        name="startDate" 
                                        defaultValue={startDate}
                                        placeholder="조회 시작 날짜" 
                                        max={endDate}
                                        onChange={(e) => setStartDate(e.target.value)} />
                                </Form.Group>
                            </div>
                        </Row>
                    </div>
                    <div className='col-2' style={{ textAlign: 'center', lineHeight: '38px' }}> - </div>
                    <div className='col-5 calBox'>
                        <Row>
                            <div className='col-12'>
                                <Form.Group id="DateformGroup" controlId="doj">
                                    <Form.Control 
                                        type="date" 
                                        name="endDate" 
                                        defaultValue={endDate}
                                        placeholder="조회 끝 날짜" 
                                        min={startDate}
                                        max={formattedEndDate}
                                        onChange={(e) => setEndDate(e.target.value)} />
                                </Form.Group>
                            </div>
                        </Row>
                    </div>
                </Row>
            </div>
            <div className='col-3'></div>
        </>
    );
}

export default Calender;
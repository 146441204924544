import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Wave } from 'better-react-spinkit';
import { FaThList } from '@react-icons/all-files/fa/FaThList';
import { FaChevronLeft } from '@react-icons/all-files/fa/FaChevronLeft';
import { FaClosedCaptioning } from '@react-icons/all-files/fa/FaClosedCaptioning';
import { FaPencilAlt } from '@react-icons/all-files/fa/FaPencilAlt';
import { FaCut } from '@react-icons/all-files/fa/FaCut';

import '../../../resources/assets/styles/sass/SideBar.scss';

function VideoSideBar(props) {
    const [selectedTab, setSelectedTab] = useState(0);
    const { pathname, state } = useLocation(0);

    const userThumbnailCDN = useSelector(state => state.user.userThumbnailCDN);
    const vodInfo = useSelector(state => state.media.vodInfo);
    const uuid = pathname.substring(pathname.lastIndexOf('/') + 1);
    
    useEffect(() => {
        if (pathname.indexOf('detail') > 0) {
            setSelectedTab(1)
        }  
        else if (pathname.indexOf('subtitle') > 0) {
            setSelectedTab(2)
        }  
        else if (pathname.indexOf('chapter') > 0) {
            setSelectedTab(3)
        }
        else if (pathname.indexOf('editor') > 0) {
            setSelectedTab(4)
        }
    },[pathname])

    return (
      <>
        <div className='sidebarContainer'>

          <div className='navbarContentRegister'>
            <nav>   
                <div>
                  <Link to={'/video'}>
                    <div className = "nav-menu-items">
                      <div className = "nav-menu-itemsIcon">
                        <FaChevronLeft size="20"/>
                      </div>
                      <div className = "nav-menu-itemsText">
                          동영상
                      </div>
                    </div>
                  </Link>
                  { 
                    typeof vodInfo !== "undefined" &&
                      Object.keys(vodInfo).length > 0 ?
                        <div className="thumbnailImage" >
                          <img style={{ width: '100%', height: 'auto' }} src={`${userThumbnailCDN}/${uuid}/thumbnail/tn_1.png`}></img>
                          <div style={{ fontSize: 13, marginTop: 10 }}>{ vodInfo.realFileName }</div>
                        </div>
                        :
                        <div className="thumbnailImage" >
                          <Wave style={{ display: 'flex', justifyContent: 'center' }} size={15} color='#fefeff' />
                        </div>
                  }


                  <Link to={`/video/detail/${uuid}`}>
                    <div className = {`${selectedTab === 1 ? 'nav-menu-items active' : 'nav-menu-items'}`}>
                      <div className = "nav-menu-itemsIcon">
                        <FaPencilAlt size="23" />
                      </div>
                      <div className = "nav-menu-itemsText">
                          세부정보
                      </div>
                    </div>
                  </Link>

                  <Link to={`/video/subtitle/${uuid}`}>
                    <div className = {`${selectedTab === 2 ? 'nav-menu-items active' : 'nav-menu-items'}`}>
                      <div className = "nav-menu-itemsIcon">
                        <FaClosedCaptioning size="23" />
                      </div>
                      <div className = "nav-menu-itemsText">
                          자막
                      </div>
                    </div>
                  </Link>

                  <Link to={`/video/chapter/${uuid}`}>
                    <div className = {`${selectedTab === 3 ? 'nav-menu-items active' : 'nav-menu-items'}`}>
                      <div className = "nav-menu-itemsIcon" id="chapterIcons">
                        <FaThList size="23" />
                      </div>
                      <div className = "nav-menu-itemsText">
                          챕터
                      </div>
                    </div>
                  </Link>

                  <Link to={`/video/editor/${uuid}`}>
                    <div className = {`${selectedTab === 4 ? 'nav-menu-items active' : 'nav-menu-items'}`}>
                      <div className = "nav-menu-itemsIcon">
                        <FaCut size="23" />
                      </div>
                      <div className = "nav-menu-itemsText">
                          편집기
                      </div>
                    </div>
                  </Link>

                  {/* <Link to={`/video/editor/${uuid}`}>
                    <div className = {`${selectedTab === 3 ? 'nav-menu-items active' : 'nav-menu-items'}`}>
                      <div className= "selectedList"></div>
                      <div className = "nav-menu-itemsIcon">
                        <i className="fas fa-video"></i>
                      </div>
                      <div className = "nav-menu-itemsText">
                          편집기
                      </div>
                    </div>
                  </Link>

                  <Link to={`/video/analytics/${uuid}`}>
                    <div className = {`${selectedTab === 4 ? 'nav-menu-items active' : 'nav-menu-items'}`}>
                      <div className= "selectedList"></div>
                      <div className = "nav-menu-itemsIcon">
                        <i className="fas fa-chart-bar"></i>
                      </div>
                      <div className = "nav-menu-itemsText">
                          통계
                      </div> 
                    </div>
                  </Link>

                  <Link to={`/video/use/${uuid}`}>                  
                    <div className = {`${selectedTab === 5 ? 'nav-menu-items active' : 'nav-menu-items'}`}>
                      <div className= "selectedList"></div>
                      <div className = "nav-menu-itemsIcon">
                        <i className="far fa-cloud-upload"></i>
                      </div>
                      <div className = "nav-menu-itemsText">
                          사용하기
                      </div>
                    </div>
                  </Link> */}
                </div>
            </nav>
          </div>

        </div>
      </>
    );
  };


export default VideoSideBar;

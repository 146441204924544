import React, {useEffect, useState} from 'react';
import axios from 'axios';

import { useSelector, useDispatch } from 'react-redux';
import jwt from 'expo-jwt';
import videojs from "video.js";
import hlsQualitySelector from "videojs-hls-quality-selector";
import "video.js/dist/video-js.css";

import ReportPopup from '../components/popupManager/ReportPopup';

import '../../resources/assets/styles/sass/PopupManager.scss';

import { setPopupOrganizationData } from '../modules/organization';
import { setPopupUserData, setPopupUserToken, setPopupUserThumbnailCDN, setPopupUserVSCdn } from '../modules/user';
import { setPopupMediaUUID, setPopUpMediaInfo } from '../modules/media';
import {setPopupSubState} from '../modules/subtitle';

function MasterPopup({match}) {
    const dispatch = useDispatch();
    const { popupType, key } = match.params;
    
    const [wrongPopKey, setWrongPopKey] = useState(false);
    const [orgData,setOrgData] = useState({});
    const [userToken,setUserToken] = useState('');
    const [userData,setUserData] = useState({});
    const [VodUUID,setVodUUID] = useState(''); 

    const organizationList = useSelector(state => state.organization.organizationList);

    useEffect(() => {    
        var data = JSON.stringify({
            "popupKey": key
        });

        var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/auth/popup',
            headers: { 
                'Content-Type': 'application/json'
            },
            data : data
        };
    
        axios(config)
        .then( (response) => {
            var data = response.data;
            
            var orgData = response.data.organizationData; 

            var organizationData = {
                organization_name : orgData.organization_name,
                organization_code : orgData.organization_code,
                organization_url : orgData.organization_url,
                userThumbnailCDN : orgData.media_cdn,
                userVSCdn : orgData.media_vs_cdn,
                uToken : data.token
            }

            setVodUUID(data.uuid);
            setOrgData(organizationData);
            setUserToken(organizationData.uToken);

            dispatch(setPopupOrganizationData(organizationData));
            dispatch(setPopupMediaUUID(data.uuid));
            dispatch(setPopupUserToken(organizationData.uToken));
            dispatch(setPopupUserThumbnailCDN(organizationData.userThumbnailCDN));
            dispatch(setPopupUserVSCdn(organizationData.userVSCdn));

        })
        .catch((error) => {
            console.log(error);
            setWrongPopKey(true);
        });
    }, []);

    useEffect(() => {

        //redux 초기화
        return () => {
            dispatch(setPopupMediaUUID(''));
            dispatch(setPopUpMediaInfo({}));
            dispatch(setPopupOrganizationData({}));
            dispatch(setPopupUserData({}));
            dispatch(setPopupUserToken(''));
            dispatch(setPopupUserThumbnailCDN(''));
            dispatch(setPopupUserVSCdn(''));
            dispatch(setPopupSubState([]));
        };

    },[])

    return (
        wrongPopKey ? 
            <div style={{ 'marginTop' : 'calc(50vh - 25px)', 'transform': 'translate(calc(50% - 90px))' }}>
                인증되지 않은 사용자 입니다.
            </div>

        :
            userToken || Object.keys(userData).length > 0 ?
                <ReportPopup tk={userToken} orgCode={orgData.organization_code} />
                :
                null
    );
}

export default MasterPopup;

//Actions
const PURGE = "status/PURGE";

const SET_STATUS_APP_VERSION = "status/SET_STATUS_APP_VERSION";

const SET_STATUS_CPU_USAGE = "status/SET_STATUS_CPU_USAGE";
const SET_STATUS_RAM_USAGE = "status/SET_STATUS_RAM_USAGE";
const SET_STATUS_NETWORK_PING = "status/SET_STATUS_NETWORK_PING";
const SET_STATUS_PROCESS = "status/SET_STATUS_PROCESS";
const SET_STATUS_MONITOR_COUNT = "status/SET_STATUS_MONITOR_COUNT";
const SET_STATUS_WINDOW_COUNT = "status/SET_STATUS_WINDOW_COUNT";

const SET_STATUS_CAMERA = "status/SET_STATUS_CAMERA";

const SET_STATUS_USER_CAMERA = "status/SET_STATUS_USER_CAMERA";
const SET_STATUS_USER_MICROPHONE = "status/SET_STATUS_USER_MICROPHONE";

const SET_STATUS_DECIBEL_METER = "status/SET_STATUS_DECIBEL_METER";

const SET_STATUS_VIDEO_PLAYSTATE = "status/SET_STATUS_VIDEO_PLAYSTATE"
const SET_STATUS_VIDEO_CURRENT_TIME = "status/SET_STATUS_VIDEO_CURRENT_TIME"

const SET_STATUS_MANAGE_MODE = "status/SET_STATUS_MANAGE_MODE";
const SET_STATUS_SUBTITLE_LIST = "status/SET_STATUS_SUBTITLE_LIST";
const SET_STATUS_SUBTITLE_LANG = "status/SET_STATUS_SUBTITLE_LANG";
const SET_CURRENT_LANG = "status/SET_CURRENT_LANG";

const SET_CURRENT_INDEX = "status/SET_CURRENT_INDEX";

const SET_CARD_TEXT = "status/SET_CARD_TEXT";
const SET_TIMELINE_TEXT = "status/SET_TIMELINE_TEXT";

// 액션 생성 함수
export const purgeStatus = () => ({ type: PURGE });

//export const setStatusFaceMatcher = faceMatcherScore => ({ type: SET_STATUS_FACE_MACHER, faceMatcherScore });
export const setStatusDecibelMeter = decibelMeter => ({ type: SET_STATUS_DECIBEL_METER, decibelMeter });
export const setStatusIntimeColor = intimeColor => ({ type: SET_STATUS_INTIME_COLOR, intimeColor });

export const setStatusVideoPlayState = videoPlayState => ({ type: SET_STATUS_VIDEO_PLAYSTATE, videoPlayState });
export const setStatusVideoCurrentTime = videoCurrentTime => ({ type: SET_STATUS_VIDEO_CURRENT_TIME, videoCurrentTime });

export const setStatusManageMode = manageMode => ({ type: SET_STATUS_MANAGE_MODE, manageMode })
export const setStatusSubtitleList = subtitleList => ({ type: SET_STATUS_SUBTITLE_LIST, subtitleList});
export const setStatusSubtitleLang = subtitleLang => ({ type: SET_STATUS_SUBTITLE_LANG, subtitleLang });
export const setCurrentLang = currentLang => ({ type: SET_CURRENT_LANG, currentLang });

export const setCurrentIndex = currentIndex => ({ type: SET_CURRENT_INDEX, currentIndex });

export const setCardText = cardText => ({ type: SET_CARD_TEXT, cardText });
export const setTimelineText = timelineText => ({ type: SET_TIMELINE_TEXT, timelineText });

const initialState = {
    appVersion: 0,
    cpuUsage : 0,
    ramUsage : 0,
    networkPing : 0,
    statusProcess : [],
    monitorCount : false,
    windowCount : false,
    camera : {
      access : false,
      exists : false
    },
    microphone : {
      access : false,
      exists : false
    },
    userCamera : false,
    userMicrophone : false,

    decibelMeter : 0,
    videoPlayState: false,
    videoCurrentTime: 0,
    manageMode: 0,
    subtitleList: [],
    subtitleLang: {},
    currentLang: '',
    currentIndex : -1,
    cardText: {},
    timelineText: {}
};


/*
  const SET_STATUS_APP_VERSION = "status/SET_STATUS_APP_VERSION";

  const SET_STATUS_CPU_USAGE = "status/SET_STATUS_CPU_USAGE";
  const SET_STATUS_RAM_USAGE = "status/SET_STATUS_RAM_USAGE";
  const SET_STATUS_NETWORK_PING = "status/SET_STATUS_NETWORK_PING";
  const SET_STATUS_PROCESS = "status/SET_STATUS_PROCESS";
  const SET_STATUS_MONITOR_COUNT = "status/SET_STATUS_MONITOR_COUNT";
  const SET_STATUS_WINDOW_COUNT = "status/SET_STATUS_WINDOW_COUNT";

  const SET_STATUS_CAMERA = "status/SET_STATUS_CAMERA";
  const SET_STATUS_MICROPHONE = "status/SET_STATUS_MICROPHONE";

  const SET_STATUS_FACIAL_RECOGNITION = "status/SET_STATUS_FACIAL_RECOGNITION";
  const SET_STATUS_OBJECT_RECOGNITION = "status/SET_STATUS_OBJECT_RECOGNITION";
  const SET_STATUS_DECIBEL_METER = "status/SET_STATUS_DECIBEL_METER";
  const SET_STATUS_INTIME_COLOR = "status/SET_STATUS_INTIME_COLOR";
*/

export default function status(state = initialState, action) {
  switch (action.type) {
    case PURGE: 
      return {
        ...initialState
      }
    case SET_STATUS_APP_VERSION:
      return {
          ...state,
          appVersion : action.appVersion
      };
    case SET_STATUS_CPU_USAGE:
      return {
          ...state,
          cpuUsage : action.cpuUsage
    };
    case SET_STATUS_RAM_USAGE:
      return {
          ...state,
          ramUsage : action.ramUsage
    };
    case SET_STATUS_NETWORK_PING:
      return {
          ...state,
          networkPing : action.networkPing
    };
    case SET_STATUS_PROCESS:
      return {
          ...state,
          statusProcess : action.statusProcess
    };
    case SET_STATUS_MONITOR_COUNT:
      return {
          ...state,
          monitorCount : action.monitorCount
    };
    case SET_STATUS_WINDOW_COUNT:
      return {
          ...state,
          windowCount : action.windowCount
    };
    case SET_STATUS_CAMERA:
      return {
          ...state,
          camera : action.camera
    };
    case SET_STATUS_USER_CAMERA:
      return {
          ...state,
          userCamera : action.userCamera
    };
    case SET_STATUS_USER_MICROPHONE:
      return {
          ...state,
          userMicrophone : action.userMicrophone
    };
  
    case SET_STATUS_DECIBEL_METER:
      return {
          ...state,
          decibelMeter : action.decibelMeter
    };
    case SET_STATUS_VIDEO_PLAYSTATE:
      return {
          ...state,
          videoPlayState : action.videoPlayState
    };
    case SET_STATUS_VIDEO_CURRENT_TIME:
      return {
          ...state,
          videoCurrentTime : action.videoCurrentTime
    };
    case SET_STATUS_MANAGE_MODE:
      return {
        ...state,
        manageMode : action.manageMode
    };
    case SET_STATUS_SUBTITLE_LIST:
      return {
          ...state,
          subtitleList: action.subtitleList
    };
    case SET_STATUS_SUBTITLE_LANG:
      return {
          ...state,
          subtitleLang: action.subtitleLang
    };
    case SET_CURRENT_LANG:
      return {
          ...state,
          currentLang: action.currentLang
    };
    case SET_CURRENT_INDEX:
      return {
          ...state,
          currentIndex: action.currentIndex
    };
    case SET_CARD_TEXT:
      return {
          ...state,
          cardText: action.cardText
    };
    case SET_TIMELINE_TEXT:
      return {
          ...state,
          timelineText: action.timelineText
    };
    default:
      return state;
  }
}

import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Button } from 'react-bootstrap';
import { ChasingDots, Wave } from 'better-react-spinkit';
import { toast, Slide, Flip } from 'react-toastify';

import {
    setChapState
} from '../../../modules/chapter';

import ToggleSwitch from "../_commons/ToggleSwitch";
import EditColumn from './EditColumn';
import DeleteColumn from './DeleteColumn';
import Loading from '../../../components/_commons/Loading';

function VideoDetailChapterList() {

    const dispatch = useDispatch();
    const location = useLocation();

    const [isFetched, setIsFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    const userToken = useSelector(state => state.user.userToken);
    const organizationData = useSelector(state => state.organization.organizationData);
    const chapState = useSelector(state => state.chapter.chapState);

    const koLabelArr = [
                            { "lang" : "ko", "koLabel" : "한글" },
                            { "lang" : "en", "koLabel" : "영어" },
                            { "lang" : "ja", "koLabel" : "일본어" },
                            { "lang" : "zh-CN", "koLabel" : "중국어 - 간체" },
                            { "lang" : "zh-TW", "koLabel" : "중국어 - 번체" },  
                            { "lang" : "vi", "koLabel" : "베트남어" },
                            { "lang" : "id", "koLabel" : "인도네시아어" },
                            { "lang" : "th", "koLabel" : "태국어" },
                            { "lang" : "de", "koLabel" : "독일어" },
                            { "lang" : "ru", "koLabel" : "러시아어" },
                            { "lang" : "es", "koLabel" : "스페인어" },
                            { "lang" : "it", "koLabel" : "이탈리아어" },
                            { "lang" : "fr", "koLabel" : "프랑스어" },
                        ]

    const toastId = useRef();
    var autoGenInterval;

    useEffect(() => {

        //redux 초기화
        return () => {
            dispatch(setChapState([]));
        };

    },[])


    useEffect(() => {
  
        var data = JSON.stringify({
            "tk" : userToken,
            "uuid" : location.pathname.replace("/video/chapter/",''),
            "ogc" : organizationData.value,
            "type" : "ALL"
        });

        var config = {
          method: 'post',
          url: API_URL + '/' + API_VERSION + '/chapter/getState',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
  
        axios(config)
        .then( (response) => {
            if(response.data.code==100){
                var tmpChapState = new Array(13).fill(0);
                if (response.data.data.chapter.length > 0) {
                    response.data.data.chapter.map((el, index) => {
                        /*
                            언어 순서 조정 및 한국어 라벨 저장하기
                        */
                       var lang = el.lang;

                       //하이픈 비교 오류 방지
                       if (el.lang && el.lang.includes('-')) {
                            lang = el.lang.replace('-','')
                        }
                       
                        switch(lang) 
                        {
                            case "ko":
                                tmpChapState[0] = el;
                            break;
                            case "en":
                                tmpChapState[1] = el;
                            break;                        
                            case "ja":
                                tmpChapState[2] = el;
                            break;
                            case "zhCN":
                                tmpChapState[3] = el;
                            break;    
                            case "zhTW":
                                tmpChapState[4] = el;
                            break;  
                            case "vi":
                                tmpChapState[5] = el;
                            break;      
                            case "id":
                                tmpChapState[6] = el;
                            break;    
                            case "th":
                                tmpChapState[7] = el;
                            break;     
                            case "de":
                                tmpChapState[8] = el;
                            break;    
                            case "ru":
                                tmpChapState[9] = el;
                            break;   
                            case "es":
                                tmpChapState[10] = el;
                            break;    
                            case "it":
                                tmpChapState[11] = el;
                            break;   
                            case "fr":
                                tmpChapState[12] = el;
                            break;      
                        }
                        
                    })
                }

                dispatch(setChapState([...tmpChapState]));
                setIsFetched(true);
            }
            
        })
        .catch((error) => {
            console.log(error);
        });
        
    }, []);

    return (
        <>
            <Table className="subtitleListTable">
                <thead>
                    <tr>
                        <th scope="col">언어</th>
                        <th scope="col">사용</th>
                        <th scope="col">편집 / 생성</th>
                        {/* <th scope="col">자동 번역</th> */}
                        <th scope="col">삭제</th>
                    </tr>
                </thead>
                    <tbody>      
                        { 
                            isFetched && !isLoading ?
                                chapState && chapState.length > 0 ?
                                        koLabelArr.map((el, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{el.koLabel}</td>
                                                    <td> 
                                                        <ToggleSwitch type='studio' loc='chapter' uuid={location.pathname.replace("/video/chapter/",'')} lang={el.lang}  koLabel={el.koLabel} index={index}/>
                                                    </td>
                                                    <td className='editModifyBtns'> 
                                                        <EditColumn type='studio' setIsLoading={setIsLoading} index={index} uuid={location.pathname.replace("/video/chapter/",'')} el={el} />
                                                    </td>
                                                    
                                                    {/* <td> 
                                                        {   el.lang != 'ko' && autoGenChapState[index]  == 0?
                                                                <Button className="autoTranslateBtn"  variant="warning" style={{ width: 85}} onClick={() => autoGenChap(el.lang, index)}>
                                                                    자동 번역
                                                                </Button>
                                                            :
                                                                autoGenChapState[index] == 1?
                                                                    <Button  className="autoTranslateBtn" variant="success" style={{ width: 85 }} >
                                                                        완료
                                                                    </Button>
                                                                :
                                                                autoGenChapState[index] == 2?
                                                                    <Button className="autoTranslateBtn"  variant="primary" style={{ width: 85 }} >
                                                                        <Wave style={{ display: 'flex', justifyContent: 'center' }} size={15} color='#fefeff' />
                                                                    </Button>
                                                                    :null
                                                        }
                                                    </td> */}
                                                    <td className='editDeleteBtns'> 
                                                        {
                                                            chapState && chapState[index] != 0 ?
                                                                <DeleteColumn type='studio' index={index} uuid={location.pathname.replace("/video/chapter/",'')} el={el} />
                                                            :   null
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })  
                                    : 
                                    null           
                                :
                                <tr>
                                    <td className="LoadingSpinner" >
                                        <ChasingDots size={35} color='#23adad' />
                                    </td> 
                                </tr>
                        }
                    </tbody>        
            </Table>
        </>
    );
}

export default VideoDetailChapterList;
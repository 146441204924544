import React, { useEffect } from 'react';

import { Link, Redirect } from "react-router-dom";
import { Container, Row, Table } from 'react-bootstrap';
import { useHistory, Switch, Route } from "react-router-dom";

import Header from '../components/_commons/Header';
import SideBar from '../components/_commons/SideBar';
import VideoSideBar from '../components/_commons/VideoSideBar';

import VideoDetailMain from '../components/videoEdit/main/VideoDetailMain';
import VideoDetailSubtitle from '../components/videoEdit/subtitle/VideoDetailSubtitle';
import VideoDetailChapter from '../components/videoEdit/chapter/VideoDetailChapter';
import VideoDetailEditor from '../components/VideoDetailEditor';
import VideoDetailAnalytics from '../components/VideoDetailAnalytics';

import VideoList from '../components/videoEdit/VideoList';

import '../../resources/assets/styles/sass/Video.scss'


function Video() {
    
    const history = useHistory();
    var path = location.hash.replace("#/",'');

    useEffect(() => {

        if (path.includes('video')) {
            var videoPath = path.replace("video",'');
            var subPath = videoPath.substring(1,videoPath.indexOf('/',1));
            console.log('subPath',subPath)
            if ( (subPath != '') && (subPath != 'detail') && (subPath != 'subtitle') && (subPath != 'chapter') && (subPath != 'editor') ) {
                history.push('/video');
                console.log('subPath',subPath)
            }
        }
        else {
            console.log('path',path)
        }
    },[location.hash.replace("#/",'')]) 

    return (
        <>
            <Header />

                <Row style={{ width: "100%", margin: 0 }}>
                    {
                        path.indexOf('video/') > -1 ? <VideoSideBar />: <SideBar/>
                    }
                    <div className='dashboardArea' style={{ width: "100%" }}>
                        <div className="wrapper">
                            <Switch>
                                <Route exact path="/video/detail/:uuid" component={VideoDetailMain} />
                                <Route exact path="/video/subtitle/:uuid" component={VideoDetailSubtitle} />
                                <Route exact path="/video/chapter/:uuid" component={VideoDetailChapter} />
                                <Route exact path="/video/editor/:uuid" component={VideoDetailEditor} />

                                <Route exact path="/video" component={VideoList} />
                                <Route path="/" component={VideoList} />
                            </Switch>
                        </div>
                    </div>
                </Row>

        </>
        
      );
}

export default Video;
import React, { useState, useEffect, useRef } from 'react';
import { Row } from 'react-bootstrap';
import TotalVideo from './reportComp/TotalVideo';
import TotalStorage from './reportComp/TotalStorage';
import VideoNStorage from './reportComp/VideoNStorage';
import Calender from './reportComp/Calender';
import axios from 'axios';
import moment from "moment";

import VideoTable from './reportComp/VideoTable';

function ReportPopup({ tk, orgCode }) {

    const today = moment();
    const oneMonthAgo = today.clone().subtract(1, 'months');

    const [startDate, setStartDate] = useState(oneMonthAgo.format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(today.format("YYYY-MM-DD"));

    const [groupedData, setGroupedData] = useState({});

    useEffect(() => {

        // endDate는 다음 날짜로 넘어가기 직전을 계산해줘야 함
        const nextDay = new Date(endDate);
        nextDay.setDate(new Date(endDate).getDate() + 1);
        const resultDate = new Date(nextDay.getTime() - 1);

        var data = JSON.stringify({
          "tk" : tk,
          "ogc": orgCode,
          "timeStart" : dateToTimestamp(new Date(startDate)).toString(),
          "timeEnd" : dateToTimestamp(new Date(resultDate)).toString()
        });

        var config = {
        method: 'post',
        url: API_URL + '/' + API_VERSION + '/report/getStorageUsage',
        headers: { 
            'Content-Type': 'application/json'
        },
        data : data
        };
        
        axios(config)
        .then( (response) => {
            console.log(response)
            if(response.status==200){
              const aggregatedData = aggregateDataByDay(response.data.data);
              setGroupedData(aggregatedData);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    },[startDate, endDate])

    function dateToTimestamp(date) {
        return Math.floor(date.getTime() / 1000); // Unix 타임스탬프는 초 단위이므로 1000으로 나눕니다.
    }
     
    function aggregateDataByDay(data) {
        const dayDataMap = new Map();
      
        data.forEach(item => {
          
          const timestamp = item.timestamp * 1000; 
          const date = new Date(timestamp);
          // 날짜를 'YYYY-MM-DD' 형식의 문자열로 포맷
          const dayKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
      
          if (dayDataMap.has(dayKey)) {
            // 이미 날짜에 해당하는 데이터가 있다면 누적
            const existingData = dayDataMap.get(dayKey);
            // 예외 처리를 추가하여 각 필드를 더합니다.
            existingData.succeedCount = (existingData.succeedCount || 0) + (item.data.succeedCount || 0);
            existingData.convertedSize = (existingData.convertedSize || 0) + (item.data.convertedSize || 0);
            existingData.vodCount = (existingData.vodCount || 0) + (item.data.vodCount || 0);
            existingData.oriSize = (existingData.oriSize || 0) + (item.data.oriSize || 0);
          } else {
            // 해당 날짜로 데이터 생성
            const newData = {
              succeedCount: item.data.succeedCount || 0,
              convertedSize: item.data.convertedSize || 0,
              vodCount: item.data.vodCount || 0,
              oriSize: item.data.oriSize || 0
            };
            dayDataMap.set(dayKey, newData);
          }
        });
        // Map을 배열로 변환
        const aggregatedData = Array.from(dayDataMap, ([date, data]) => ({
          date,
          data: {
            succeedCount: data.succeedCount,
            convertedSize: Math.floor(data.convertedSize / (1024 ** 3)),
            vodCount: data.vodCount,
            oriSize: Math.floor(data.oriSize / (1024 ** 3)),
          }
        }));
      
        return aggregatedData;
    }

    useEffect(()=> {
        console.log('groupedData',groupedData)
    },[groupedData])

    return (
        <>
            <Row id="reportPopup" style={{ padding: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 500 }}>   

                <Row style={{ width: "100%", display: 'flex', marginBottom: 10 }}>
                    <Calender startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} formattedEndDate={today.format("YYYY-MM-DD")}/>
                </Row>

                <Row style={{ width: "100%" }}>
                    <TotalVideo groupedData={groupedData}/>
                    <TotalStorage groupedData={groupedData} />
                </Row>

                <Row style={{ width: "100%" }}>
                    <VideoNStorage groupedData={groupedData} />
                </Row>

                <Row style={{ width: "100%" }}>
                    <VideoTable groupedData={groupedData} />
                </Row>

            </Row>
        </>
    );
}

export default ReportPopup;
import React, { useState, useEffect, useRef } from "react";

import axios from 'axios';
import Timeline from "react-vis-timeline";
import Select from 'react-select'
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Form from 'react-bootstrap/Form';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import {
  setSubtitleList,
  setSelectedSubtitle
} from '../../../../modules/subtitle';

import { 
  setPlayingTimeTimelineToMediaPlayer,
  setPlayingTimeMediaPlayerToTimeline
} from '../../../../modules/media';

import { 
  setTimelineText
} from '../../../../modules/status';

import '../../../../../resources/assets/styles/sass/Timeline.scss';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

const now = moment('1970-01-01 00:00:00.000');

const moveSlider = (location: number) => `
  @keyframes moveSlider {
    from {
      left: ${location}px;
    }
    to {
      left: 100%;
    }
  }
`;

function TimelineComp( { uuid, lang, tk, ogc } ) {
  const timelineRef = useRef(null);
  const dispatch = useDispatch();

  const videoDurationTime = useSelector(state => state.media.videoDurationTime);
  
  const MediaPlayerPlayingTime = useSelector(state => state.media.MediaPlayerPlayingTime)
  const cardText = useSelector(state => state.status.cardText);
  const selectedSubtitle = useSelector(state => state.subtitle.selectedSubtitle);
  const subtitleList = useSelector(state => state.subtitle.subtitleList);
  const isPlaying = useSelector(state => state.status.videoPlayState);

  const [ zoomValue, setZoomValue ] = useState(4); 
  const [location, setLocation] = useState(0);

  useEffect(() => {

  if(cardText && Object.keys(cardText).length) {
    timelineRef.current.timeline.itemsData.update(cardText);
  }
  },[cardText])

  useEffect(() => {

  if(cardText && Object.keys(selectedSubtitle).length) {
    timelineRef.current.timeline.itemsData.update(selectedSubtitle);
  }
  },[selectedSubtitle])


  /* 
  Timeline & MediaPlayer 
  */

  const initGroups = [{ id: 1, content: "" }];


  // 타임라인 드래그해서 시간 이동시키면 비디오 컴포넌트의 시간도 변경 (타임라인 -> 비디오)
  useEffect(() => {

    var start = now.clone().subtract(1000*5,'milliseconds');
    var end = now.clone().add(1000*5,'milliseconds');
    timelineRef.current.timeline.setGroups(initGroups);
    timelineRef.current.timeline.setWindow(start, end, { animation: false });
    timelineRef.current.timeline.itemsData.clear();
    timelineRef.current.timeline.itemsData.add(subtitleList);

    dispatch(setPlayingTimeTimelineToMediaPlayer(0));
    dispatch(setTimelineText({}));

    timelineRef.current.timeline
      .on("rangechange", function (properties) {
        var ct = new Date((new Date(properties['start']).getTime() + new Date(properties['end']).getTime()) / 2);
        if (moment(ct).isBefore(moment('1970-01-01 00:00:00.000'))) {
          timelineRef.current.timeline.moveTo('1970-01-01 00:00:00', { animation: false })
          dispatch(setPlayingTimeMediaPlayerToTimeline(0));
        }

        if (moment(ct).isAfter(moment('1970-01-01 00:00:00.000').add(videoDurationTime, 'seconds'))) {
          timelineRef.current.timeline.moveTo(moment('1970-01-01 00:00:00.000').add(videoDurationTime, 'seconds'), { animation: false })
          //dispatch(setPlayingTimeMediaPlayerToTimeline(videoDurationTime));
        }
      })
      .on("rangechanged", function (properties) {

        var ct = new Date((new Date(properties['start']).getTime() + new Date(properties['end']).getTime()) / 2);
          if (properties['byUser']) {
            dispatch(setPlayingTimeTimelineToMediaPlayer((moment.duration(moment(ct).diff(moment('1970-01-01 00:00:00.000'))).asSeconds())));
          }
      })
      .on("select", function (items, event) {
        console.log('items',items);
      })
  }, []);

  useEffect(() => {
    //비디오 시간에 맞춰 타임라인 이동 (비디오 -> 타임라인)
    var time = moment('1970-01-01 00:00:00.000').add(MediaPlayerPlayingTime,'seconds').format('HH:mm:ss.SSS');
    timelineRef.current.timeline.moveTo(moment('1970-01-01 '+ time));

  }, [MediaPlayerPlayingTime]);
  
  useEffect(() => {
    console.log('location', location)
  },[location])
  /*
    Timeline & Subtitle
  */

  //자막 초기화
  useEffect(() => {

    if (subtitleList && subtitleList.length > 0 ) {
      console.log('timeline subtitleList',subtitleList)
      // stt파일 불러오면 그 로직도 여기에
      var timelineItem = timelineRef.current.timeline.itemsData.get();
  
      if (timelineItem.length != subtitleList.length) { //타임라인 내부에서 이미 추가한건 다시 추가 안되도록
        timelineRef.current.timeline.itemsData.clear();
        timelineRef.current.timeline.itemsData.add(subtitleList);
      } 

      var tmpSubList = subtitleList;
      timelineRef.current.timeline.on('itemover', function (properties) {
  
          const editBtn = document.createElement("div")
          editBtn.className = "editBtn"
          editBtn.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path style="fill:rgb(169,169,169)" d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"/></svg>';
          properties.event.target.closest(".vis-item").append(editBtn)
          
          editBtn.addEventListener('click', function (event) {
              event.stopPropagation()
              editBtn.style.display = 'none';
      
              var input = document.createElement("input");
              input.className = "editInput";
              input.value = properties.event.target.innerText;

              properties.event.target.append(input);
              input.focus();
              event.stopPropagation()

              // 타임라인 자막에 글 입력할 때마다 업데이트하는 이벤트
              input.addEventListener('input', function (event) {
                  var index = -1;

                  tmpSubList.map((el, idx) => {
                    if (el.id == properties.item) {
                      index = idx;
                    }
                  })

                  dispatch(setTimelineText({...subtitleList[index], content: event.target.value}));
                  var tmpArr = properties.item;
                  // tmpArr.start = moment(properties.item.start).format('1970-01-01 HH:mm:ss.SSS');
                  // tmpArr.end = moment(properties.item.end).format('1970-01-01 HH:mm:ss.SSS');
            
                  console.log('tmpArr',subtitleList[properties.item])
                 
                  tmpSubList[properties.item] = {
                    ...tmpSubList[properties.item],
                    content: event.target.value
                  };
                  dispatch(setSubtitleList(tmpSubList));

              })
          
              // 타임라인 자막 저장하는 이벤트
              input.addEventListener('focusout', function (event) {
                  try {
                    var tmpParsed = tmpSubList.map(list => Object.assign({
                          identifier: String(list.id),
                          start: moment.duration(moment(list.start).diff(moment('1970-01-01 00:00:00.000'))).asSeconds(),
                          end: moment.duration(moment(list.end).diff(moment('1970-01-01 00:00:00.000'))).asSeconds(),
                          text: list.content,
                          styles: ""
                        })
                      )
                      
                    var data = JSON.stringify({
                      "tk": tk,
                      "uuid": uuid,
                      "ogc": ogc,
                      "lang": lang,
                      "subtitle": tmpParsed
                    });
        
                    var config = {
                      method: 'post',
                      url: API_URL + '/' + API_VERSION + '/subtitle/aUpdate',
                      headers: {
                        'Content-Type': 'application/json'
                      },
                      data: data
                    };
        
                    axios(config)
                      .then((response) => {
                        console.log('edit')
                      })
                      .catch((error) => {
                        console.log(error);
                      });

                    timelineRef.current.timeline.itemsData.clear();
                    timelineRef.current.timeline.itemsData.add(subtitleList);
                    timelineRef.current.timeline.setSelection([]);

                    var editBtn = document.querySelector(".editBtn");
                    properties.event.target.closest(".vis-item").removeChild(editBtn)
                  }
                catch {}
              })
          })
      })
      
      }
  }, [subtitleList])

    const ZoomPlus = () => {
      setZoomValue((prevValue) => prevValue - 1);
      timelineRef.current.timeline.zoomOut(1.0)
    };

    const ZoomMinus = () => {
      setZoomValue((prevValue) => prevValue + 1);
      timelineRef.current.timeline.zoom(1.0)
    };

  const options = {
    height: '150px',
    showMajorLabels: false,
    orientation: "top",
    zoomMin: 100,
    zoomMax: 1000 * 100,
    horizontalScroll: true, 
    verticalScroll: false,
    zoomKey: 'ctrlKey',
    max: moment('1970-01-01 00:00:00.000').add(videoDurationTime + 5, 'seconds'),
    zoomable: true,
    stack: false,
    type: "range",
    showCurrentTime: true,
    editable: {
      add: true,         // add new items by double tapping
      updateTime: true,  // drag items horizontally
      updateGroup: false, // drag items from one group to another
      remove: false,       // delete an item by tapping the delete button top right
    },
    itemsAlwaysDraggable: {
      item: true,
      range: false,
    },
    margin: {
      axis: 50,
      item: {
        vertical: 15,
        horizontal: 0,
      }
    },
    template: (item, element, data) => {
      return item.content;
    },

    onAdd: function (item, callback) {

      var index;
      const maxId = subtitleList.reduce((acc, cur) => cur.id > acc ? cur.id : acc, 0);

      var newItem = {
        id: maxId + 1,
        group: 1,
        start: moment(item.start).format('1970-01-01 HH:mm:ss.SSS'),
        end: moment(item.start).add(1, 'seconds').format('1970-01-01 HH:mm:ss.SSS'),
        content: ''
      };

      var endTime = moment('1970-01-01 00:00:00.000').add(videoDurationTime, 'seconds').format('HH:mm:ss.SSS');

      // 같은 시간의 아이템 생성되지 않도록
      var notSameFlag = 0;
      subtitleList.map((el, idx, list) => {
        if (moment(newItem.start).isSame(moment(list[idx].start)) && moment(newItem.end).isSame(moment(list[idx].end))) {
          notSameFlag = 1;
        }
      });

      if (notSameFlag == 0) {

          // end값이 duration값은 넘지 않도록
          if (moment(newItem.end).add(1, 'seconds').isAfter(moment("1970-01-01 " + endTime))) {
            newItem.start = moment("1970-01-01 " + endTime).subtract(1, 'seconds').format("YYYY-MM-DD HH:mm:ss.SSS");
            newItem.end = moment("1970-01-01 " + endTime).format("YYYY-MM-DD HH:mm:ss.SSS");
            var tmpArr = [...subtitleList, newItem];
            timelineRef.current.timeline.itemsData.clear();
            timelineRef.current.timeline.itemsData.add(tmpArr);
            var sortedArray = tmpArr.sort((a, b) => moment(a.start).diff(moment(b.start)));
              dispatch(setSubtitleList(sortedArray));
          }
          else {
            subtitleList.map((el, idx, list) => {

              //중간에 생성될 떄
              if (list.length > 1 && idx + 1 < list.length) {
                  if (moment(item.start).isBetween(moment(list[idx].end), moment(list[idx + 1].start))) {
      
                    if (moment(newItem.start).add(1, 'seconds').isAfter(moment(list[idx + 1].start)))
                      newItem.end = list[idx + 1].start;
                    else
                      newItem.end = moment(newItem.start).add(1, 'seconds').format('YYYY-MM-DD HH:mm:ss.SSS')
                  }
              }
   
              var tmpArr = [...subtitleList, newItem];
              var sortedArray = tmpArr.sort((a, b) => moment(a.start).diff(moment(b.start)));
              dispatch(setSubtitleList(sortedArray));
        
              timelineRef.current.timeline.itemsData.clear();
              timelineRef.current.timeline.itemsData.add(tmpArr);

          })
        }
      }

    },
    onUpdate: function (item, callback) {
      var tmpArr = item;
      tmpArr.start = moment(item.start).format('1970-01-01 HH:mm:ss.SSS');
      tmpArr.end = moment(item.end).format('1970-01-01 HH:mm:ss.SSS');

      dispatch(setSelectedSubtitle(tmpArr));
      dispatch(setTimelineText(tmpArr));
      callback(item);
    },
    onMove: function (item, callback) {
      var tmpArr = item;
      tmpArr.start = moment(item.start).format('1970-01-01 HH:mm:ss.SSS');
      tmpArr.end = moment(item.end).format('1970-01-01 HH:mm:ss.SSS');

      dispatch(setSelectedSubtitle(tmpArr));
      dispatch(setTimelineText(tmpArr));

      var index;
      var tmpSubList = subtitleList;

      tmpSubList.map((el, idx) => {
        if (el.id == item.id) {
          index = idx;
        }
      })

      tmpSubList[index] = tmpArr;
      dispatch(setSubtitleList(tmpSubList));

      callback(item);
    },
    onMoving: function (item, callback) {

      var index;
      var tmpSubList = subtitleList;

      tmpSubList.map((el, idx) => {
        if (el.id == item.id) {
          index = idx;
        }
      });

      //1970-01-01 00:00:00 전으로는 이동못하게
      if (moment(item['start']).isBefore(moment('1970-01-01 00:00:00'))) {
      } else {

        //자막 한 개일 때 
        if (subtitleList.length == 1) {
          callback(item)
        } else {
          //자막 두 개일 때 오른쪽으로 이동 시 다음 요소랑 겹치는 것 막기
          if (subtitleList[index + 1] && moment(item.end).isAfter(moment(subtitleList[index + 1].start))) { }
          //자막 두 개일 때 왼쪽으로 이동 시 전 요소랑 겹치는 것 막기
          else if (subtitleList[index - 1] && moment(item.start).isBefore(moment(subtitleList[index - 1].end))) { }
          //겹치지 않을 때만 callback
          else { callback(item); }
        }
      }
    },
    onRemove: function (item, callback) {
      callback(item);
    },
    format: {
      minorLabels: function (date, scale, step) {

        if (moment(date).isBefore(now)) {
          return "";
        }
        else if (moment(date).isAfter(now.clone().add(videoDurationTime, 'seconds'))) {
          return "";
        }
        else {
          if (scale == "millisecond" && step == 200)
            return moment(date).format('mm:ss.SS');
          else if (scale == "millisecond" && step == 100)
            return moment(date).format('ss.SS');
          else if (scale == "millisecond" && step == 50)
            return moment(date).format('ss.SSS');
          else if (scale == "millisecond" && step == 10)
            return moment(date).format('ss.SSS');
          else if (scale == "millisecond" && step == 5)
            return moment(date).format('ss.SSS');
          else if (scale == 'second')
            return moment(date).format('HH:mm:ss');
        }
      }
    },
  };

  return (
    <>
      {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'right' }}>
        <div style={{ display: 'flex', marginRight: 20 }} >
          <OverlayTrigger
            delay={{ hide: 400, show: 300 }}
            overlay={(props) => (
              <Tooltip id="tooltip" {...props}  >
                타임라인 위에서 ctrl + 스크롤하여도 <br/>줌 기능을 사용하실 수 있습니다.
              </Tooltip>
            )}
            placement="top"
            >
            <div className="quantity-picker">
              <button className={`quantity-picker__button quantity-picker__button--decrease ${ zoomValue === 0 ? 'quantity-picker__button--disabled' : ''} `} onClick={ZoomPlus} disabled={zoomValue === 0}>-</button>
              <button className={`quantity-picker__button quantity-picker__button--increase ${ zoomValue === 8 ? 'quantity-picker__button--disabled' : ''} `}  onClick={ZoomMinus} disabled={zoomValue === 8}>+</button>
            </div>
          </OverlayTrigger>
        </div>
      </div> */}

      <div className="TimelineBox" id="box">

        <div className="borderBox" id="box">
          <div className="track"></div>
          <div className="arrow"></div>
        </div>

        <Timeline options={options} ref={timelineRef} />

        {/* { 
              domReady ? <Ticks /> : null
            } */}

      </div>
    </>


  );
}




export default TimelineComp;


import React from 'react';

import { Table } from 'react-bootstrap';

function UsedService(props) {
    return (
        <div className="col-4" style={{ marginBottom: 30 }}>
        <div className="dashboardBox">
            <div className="dashboardBoxTitle" style={{ marginBottom: 10 }}>
                사용자가 이용하는 서비스
            </div>
            <div className="dashboardBoxContent">
              <Table className="subtitleListTable" style={{ fontWeight: 400 }}>
                  <thead>
                  <tr>
                      <th scope="col">도메인</th>
                      <th scope="col">페이지</th>
                      <th scope="col">영상 호출 수</th>
                  </tr>
                  </thead>
                  
                  <tbody>  
                      <tr>
                          <td>
                              CSMS Media
                          </td> 
                          <td>
                              sd
                          </td> 
                          <td>
                              sd
                          </td> 
                      </tr>
                      <tr>
                          <td>
                              CSMS RUAuth
                          </td> 
                          <td>
                              sd
                          </td> 
                          <td>
                              sd
                          </td> 
                      </tr>
                      <tr>
                          <td>
                              CSMS LearningChecker
                          </td> 
                          <td>
                              sd
                          </td> 
                          <td>
                              sd
                          </td> 
                      </tr>
                  </tbody>
              </Table>
            </div>
        </div>
        </div>
    );
}

export default UsedService;
import React from 'react';

import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

function NumberUsers(props) {

    var data = [ 
        {
            data : generateDayWiseTimeSeries(new Date("22 Apr 2017").getTime(), 115, {
                min: 30,
                max: 90
            })
        } 
    ];
                console.log('data',data)                                                                                                                                                                                                                                  
    function generateDayWiseTimeSeries(baseval, count, yrange) {
        var i = 0;
        var series = [];
        while (i < count) {
          var x = baseval;
          var y =
            Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
      
          series.push([x, y]);
          baseval += 86400000;
          i++;
        }
        return series;
    }

    const options: ApexOptions = {
        chart: {
            id: 'chartNumberUser_area',
            height: 230,
            toolbar: {
                    autoSelected: 'pan',
                    show: false
                }
        },
        colors: ['#546E7A'],
        stroke: {
            width: 3
        },
        dataLabels: {
            enabled: false
        },
        fill: {
            opacity: 1,
        },
        markers: {
            size: 0
        },
        xaxis: {
            type: 'datetime'
        }
    };

    var optionsLine = {
        chart: {
            id: 'chartNumberUser_line',
            height: 130,
            brush:{
                target: 'chartNumberUser_area',
                enabled: true
            },
            selection: {
                enabled: true,
                xaxis: {
                    min: new Date('19 Jun 2017').getTime(),
                    max: new Date('14 Aug 2017').getTime()
                }
            },
        },
        colors: ['#008FFB'],
        fill: {
            type: 'gradient',
            gradient: {
            opacityFrom: 0.91,
            opacityTo: 0.1,
            }
        },
        xaxis: {
            type: 'datetime',
            tooltip: {
            enabled: false
            }
        },
        yaxis: {
            tickAmount: 2
        }
    };

    return (
        <div className="col-8" style={{ marginBottom: 30 }}>
            <div className="dashboardBox">
                <div className="dashboardBoxTitle">
                    사용자 수
                </div>
                <ReactApexChart options={options} series={data} width="90%" height={200}  type="line"/>
                <ReactApexChart options={optionsLine} series={data} width="90%" height={200}  type="area"/>
            </div>
        </div>
    );
}

export default NumberUsers;
import React, { useState, useEffect, useRef } from 'react';

import Switch from "react-switch";
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { toast, Flip, Slide } from 'react-toastify';
import { VscError } from '@react-icons/all-files/vsc/VscError';

import VideoDetailChapterList from './VideoDetailChapterList';

import { setCurrentMediaInfo } from '../../../modules/media';

function VideoDetailChapter() {

    const userToken = useSelector(state => state.user.userToken);
    const organizationData = useSelector(state => state.organization.organizationData);

    const dispatch = useDispatch(); 

    const [isUsed, setIsUsed] = useState(0);
    const [chapterList, setChapterList] = useState([]);

    const location = useLocation();

    const toastId = useRef();

    useEffect(() => {

        // toast.error((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 챕터 생성에 오류가 생겼습니다! </div></div>),{
        //     type: toast.TYPE.ERROR,
        //     autoClose: 100000,
        //     position: "top-center",
        //     className: 'rotateY animated',
        //     transition: Flip
        // });

        // 동영상 정보 가져오기
        var data = JSON.stringify({
            "userToken" : userToken,
            "uuid" : location.pathname.replace("/video/chapter/",''),
            "organizationCode" : organizationData.value
        });

        var config = {
        method: 'post',
        url: API_URL + '/' + API_VERSION + '/vod/getVodInfo',
        headers: { 
            'Content-Type': 'application/json'
        },
        data : data
        };
        
        axios(config)
        .then( (response) => {
            console.log(response)
            if(response.status==200){
                console.log('response.data.support_chapter',response.data.support_chapter)
                setChapterList(response.data.chapterList);
                dispatch(setCurrentMediaInfo(response.data));
                if (response.data.support_chapter == 1) {
                    setIsUsed(1);
                }
                else if (response.data.support_chapter == 0) {
                    setIsUsed(0);
                }
            }
        })
        .catch((error) => {
            toast.error((<div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 동영상 정보 가져오기에 오류가 생겼습니다! </div></div>),{
                type: toast.TYPE.ERROR,
                autoClose: 1000,
                position: "top-center",
                className: 'rotateY animated',
                transition: Flip
            });
        });

    }, [])


    function handleChange() {
        
        toastId.current = toast.info( (<div style={{ color: '#fff' }}>챕터 사용 여부를 변환 중 입니다.</div>),  {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            transition : Slide
        });

        setIsUsed(!isUsed);

        var data = JSON.stringify({
            "userToken" : userToken,
            "uuid" : location.pathname.replace("/video/chapter/",''),
            "organizationCode" : organizationData.value,
            "state" : isUsed ? 0 : 1
        });

        var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/vod/setChapterState',
            headers: { 
                'Content-Type': 'application/json'
            },
            data : data
        };
        
        axios(config)
        .then( (response) => {
            toast.dismiss(toastId.current);

            console.log(response)
            if(response.data.code == 100){
                setIsUsed(response.data.currentState);
            }
        })
        .catch((error) => {
            toast.update(toastId.current ,{
                render: () => <div style={{ color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10 }}><VscError size="22" color="#fff" /></div><div> 챕터 상태 변경에 오류가 생겼습니다! </div></div>,
                type: toast.TYPE.ERROR,
                autoClose: 1000,
                position: "top-center",
                className: 'rotateY animated',
                transition: Flip
            });
        });
    }


    return (
        <div style={{ position: 'relative' }}>
            <div>
                <div className="contentHeader" style={{ padding: 20 }}>
                    <div className='containerSubtitleTitle'>
                        <div>
                            <div> 동영상 챕터 </div>

                        </div>
                    </div>
                </div>

                <div style={{ width: '90%', margin: '0 auto 30px', fontSize: 13, display: 'flex', justifyContent: 'space-between' }}>
                    <div className='titleDesc'>
                        <div>· 이 영상에서 챕터를 사용하시려면 토글 버튼을 on으로, 사용을 중단하실 때는 off로 바꾸어주십시오.</div>
                        <div>· 자동 번역은 한글을 기준으로 하는 번역만 지원합니다.</div>
                    </div>
                    <Switch uncheckedIcon={
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", 
                                            fontSize: 11, color: "#fff", paddingRight: 2 }} >
                                    off
                                </div>
                            }
                            checkedIcon={
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", 
                                            fontSize: 11, color: "#fff", paddingRight: 2 }} >
                                    on
                                </div>
                              }
                            onColor='#11a7ea'     
                            onChange={() => handleChange() }
                            checked={ isUsed ? true : false } />
                </div>             
            </div>

            <VideoDetailChapterList />

        </div>
    );
}

export default VideoDetailChapter;
import React from 'react';

function UsagePerCountry(props) {
    return (
        <div className="col-4" style={{ marginBottom: 30 }}>
            <div className="dashboardBox">
                <div className="dashboardBoxTitle">
                    국가별 이용량
                </div>
                {/* <ReactApexChart options={optionsDonut} series={seriesDonut} width="90%" height={200}  type="donut"/> */}
            </div>
        </div>
    );
}

export default UsagePerCountry;
import React, { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { Wave } from 'better-react-spinkit';

import { FaTimes } from '@react-icons/all-files/fa/FaTimes';
import { FaCloudUploadAlt } from '@react-icons/all-files/fa/FaCloudUploadAlt';

import axios from 'axios';

function ThumbnailModal({ modalOpen, setModalOpen, url, idx }) {
    const [files, setFiles] = useState([]);
    const [uploadUrl, setUploadUrl] = useState([]);
    const [isChanged, setIsChanged] = useState(0);

    const userToken = useSelector(state => state.user.userToken);
    const organizationData = useSelector(state => state.organization.organizationData);
    const uuid = useSelector(state => state.media.uuid);

    useEffect(() => {
        if (modalOpen) {
            var data = JSON.stringify({
                "tk" : userToken,
                "uuid" : uuid,
                "ogc" : organizationData.value,
                "idx": idx + 1
            });
        
            var config = {
                method: 'post',
                url: API_URL + '/' + API_VERSION + '/common/getThumbUploadUrl', 
                headers: { 
                'Content-Type': 'application/json'
                },
                data : data
            };
            axios(config)
            .then( (response) => {
                if (response.data.code == 100) {
                    setUploadUrl(response.data.upload_url);
                }
               console.log('response',response)
            })
            .catch((error) => {
                console.log(error);
            });
        }
        else { setUploadUrl([]); setFiles([]); setIsChanged(0); }

    },[modalOpen])


    function UploadThumb(data) {
        setIsChanged(1);

        var config = {
            method: 'put',
            url: uploadUrl, 
            headers: { 
            'Content-Type': 'image/png'
            },
            data : data
        };
        console.log(config)
        axios(config)
          .then(function (response) {
            console.log(response);
          })
          .then(function () {
            var data = JSON.stringify({
                "tk" : userToken,
                "uuid" : uuid,
                "ogc" : organizationData.value,
                "idx": idx + 1
            });
        
            var config = {
                method: 'post',
                url: API_URL + '/' + API_VERSION + '/common/setThumb', 
                headers: { 
                'Content-Type': 'application/json'
                },
                data : data
            };
            axios(config)
            .then( (response) => {
                if (response.data.code == 100) {
                    setIsChanged(2);
                }
               console.log('setThumb response',response)
            })
            .catch((error) => {
                console.log(error);
            });
          })
          .catch(function (error) {
            console.log(error);
          });
    }

    const onDrop = useCallback(acceptedFiles => {

        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file),
            blob: file
        })));

    }, [])

    const {getRootProps, getInputProps, isDragActive, isDragReject} = useDropzone({onDrop, multiple: false, accept: { "image/png" : ['.png'] } })

    const RootProps = {
        ...getRootProps(),
    };

    useEffect(() => () => {
    // createObjectURL 로 생성한 URL을 revokeObjectURL 로 제거 해준다
    // 메모리 누수를 방지하기 위해 생성된 URL을 DOM과 바인딩한 후에는 해제하는 것이 좋다고한다.
        console.log(files)
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    //현재 업로드한 이미지 파일을 취소하는 기능
    const handleCancle = () => {
        console.log('cancle')
        setFiles([])
    }

    return (
        <>
            <Modal className="ThumbnailModal" show={modalOpen} onHide={() => setModalOpen(false)}>
                <Modal.Header closeButton>썸네일 교체</Modal.Header>
                    <Modal.Body style={{ textAlign: 'center', padding: '2rem' }}> 
                    <div>
                            <div style={{ boxSizing: 'border-box', width: '94%', margin: '0 auto 30px', position: 'relative' }}>
                                { files.length > 0 ? 
                                    <img className="thumbImg" src={files[0].preview} />
                                    :
                                    <img className={isDragActive ? 'thumbImg on' : 'thumbImg'}   src={url} />
                                }
                                {
                                isDragReject ?
                                    <div style={{position: 'absolute', top: 'calc(50% - 15px)', left: 'calc(50% - 15px)', fontSize: '3em', color: '#ea1111a3' }}> 
                                        <FaTimes size="40" color="#555" />
                                    </div>
                                    : 
                                    isDragActive ? 
                                        <div style={{position: 'absolute', top: 'calc(50% - 20px)', left: 'calc(50% - 25px)', fontSize: '2em' }}> 
                                            <Wave size={50} color='#11a7ea6b' />
                                        </div>
                                        :
                                        null
                               }
                            </div>

                            { files.length > 0 ? 
                                <div>
                                    <div style={{ display: 'flex', justifyContent: 'right', width: '94%', margin: '0 auto 30px' }}>
                                        <div style={{ marginRight: 10}}>
                                            {
                                                isChanged == 0 ?
                                                    <Button variant="info" style={{ width: 85, padding: '0.375rem 0.5rem'}} onClick={() => UploadThumb(files[0].blob)}>
                                                        교체하기
                                                    </Button>
                                                :
                                                    isChanged == 1 ?
                                                        <Button variant="info" style={{ width: 85, padding: '0.7rem 0.5rem'}} onClick={() => {setModalOpen(false); location.reload();}}>
                                                            <Wave style={{ display: 'flex', justifyContent: 'center' }} size={15} color='#fefeff' />
                                                        </Button>
                                                        :
                                                        <Button variant="success" style={{ width: 85, padding: '0.375rem 0.5rem'}} onClick={() => {setModalOpen(false); location.reload();}}>
                                                            완료
                                                        </Button>
                                            }
                                            
                                        </div>
                                        
                                        <div>
                                            <Button variant="secondary" style={{ width: 85 }} onClick={handleCancle}>
                                                이전
                                            </Button>
                                        </div>
                                    </div>    
                                </div>
                                :
                                <div style={{ boxSizing: 'border-box', width: '94%', margin: '0 auto' }}>
                                        <div className='dropZoneArea'  {...RootProps} >
                                            <div style={{ fontSize: "2em", marginTop: 30 }}>
                                                { isDragReject ? 
                                                    <div style={{ marginTop: 90, fontSize: "15px"}}>파일 형식이 맞지 않습니다. PNG 형식의 이미지 파일을 등록해주세요.</div>
                                                :
                                                    isDragActive ? 
                                                        <div style={{ marginTop: 60 }} >
                                                            <span><FaCloudUploadAlt size="23" color="gray"/></span>
                                                            <div style={{ fontSize: "15px"}} >파일을 놓아 업로드</div> 
                                                        </div>
                                                        : 
                                                        <div>
                                                            <span><FaCloudUploadAlt size="23" color="gray"/></span>
                                                            <div style={{ fontSize: "15px", marginBottom: 10 }} >PNG 형식의 이미지를 마우스로 끌어 놓으세요.</div>
                                                            <Button variant="info" style={{ width: 120 }}>
                                                                파일 직접 추가
                                                            </Button>
                                                        </div>
                                                }
                                                
                                            </div>
                                            
                                        </div>
                                    </div>
                            }        
                            
                    </div>
                    </Modal.Body>
            </Modal>
        </>

    )
}

export default ThumbnailModal;
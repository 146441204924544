import React, { useState, useEffect } from 'react';
import { Row, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { ChasingDots } from 'better-react-spinkit';
import axios from 'axios';

import Header from '../components/_commons/Header';
import SideBar from '../components/_commons/SideBar';
//import '../../resources/assets/styles/sass/Chapter.scss';

import VideoListPagination from '../components/subtitle/VideoListPagination';
import ChapList from '../components/chapter/ChapList';
import Loading from '../components/_commons/Loading';

function Chapter() {
    const [videoListCount, setVideoListCount] = useState(0);
    const [currentVodList,setCurrentVodList] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true); 
    const [chapListLoadingStatus, setChapListLoadingStatus] = useState( new Array(currentVodList.length).fill(false) );
    const [count, setCount] = useState(0);

    const videoListPerPage = 30;

    const initArr = new Array(videoListPerPage);
    initArr.fill(false);

    const userToken = useSelector(state => state.user.userToken);
    const organizationData = useSelector(state => state.organization.organizationData);

    const history = useHistory();

    useEffect(() => {
        const indexOfLast = currentPage * videoListPerPage;
        const indexOfFirst = indexOfLast - videoListPerPage;
        var data = JSON.stringify({ 
            "userToken" : userToken,
            "page": currentPage,
            "organizationCode" : organizationData.value
        });
    
        var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/vod/getVodList',
            headers: { 
            'Content-Type': 'application/json'
            },
            data : data
        };
        
        axios(config)
        .then( (response) => {
            if(response.data.statusCode==100){
                setVideoListCount(response.data.vodList.count);
                setCurrentVodList(response.data.vodList.rows);
                document.querySelector('.dashboardArea').scrollTo(0, 0);
            }
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            checkLoadingStatus(); 
        });
    },[currentPage])

    useEffect(() => {
        var path = location.hash.replace("#/chapter",'');

        if (path != '') {
            history.push('/chapter');
        }
    },[location.hash.replace("#/chapter",'')])

    const checkLoadingStatus = () => {
        const allLoaded = chapListLoadingStatus.every(status => status === true);
        if (allLoaded) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        console.log('ss',chapListLoadingStatus)
    },[chapListLoadingStatus]);
    
    return (
        <>
          <Header></Header>
          <Row style={{ width: '100%', margin: 0  }}>
              <SideBar/>
              <div className='dashboardArea'>
                  <div className="wrapper">
                      <div>
                          <div className="contentHeader">
                              <div className='containerTitle'>
                                  <div> 챕터 </div>
                              </div>
                          </div>
                      </div>
                  </div>
                    {
                        isLoading ? 
                            <Loading />
                    :    currentVodList.length > 0 ?
                            <>
                                <Table className="subtitleListTable">
                                    <thead>
                                        <tr>
                                            <th scope="col">동영상</th>
                                            <th scope="col"></th>
                                            <th scope="col">언어</th>
                                            <th scope="col">수정일</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            currentVodList.map((ele, index) => { 
                                                return (
                                                    <ChapList key={index} index={index} videoInfo={ele} chapListLoadingStatus={chapListLoadingStatus} setChapListLoadingStatus={setChapListLoadingStatus} count={count} setCount={setCount} />
                                                )
                                            })
                                        }
                                    </tbody>          
                                </Table>
                                <VideoListPagination 
                                        videoListPerPage={videoListPerPage}
                                        totalVodList={videoListCount}
                                        paginate={setCurrentPage}
                                        currentPage={currentPage}
                                />
                            </>
                        :
                        <Loading />
                }
              </div>
          </Row>
        </>        
      );
}

export default Chapter;